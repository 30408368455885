import { widthFor } from '../../../map/Token';

export default function setup(connection) {
  [
    { x: 10 + 2.5, y: 8 + 2.5, color: 'red' },
    { x: 8 + 2.5, y: 18 - 2.5, color: 'red' },
    { x: 18 - 2.5, y: 18, color: 'blue' },
  ].forEach(({ color, x, y }) => {
    connection.add('shape', {
      type: 'Image',
      x,
      y,
      imageUrl: `/Breachstorm/scenarios/objective-${color}.png`,
      width: 5,
      height: 5,
    });
  });
  [
    { x: 10, y: 36 - 17 },
    { x: 18, y: 36 - 10 },
  ].forEach(({ x, y }) => {
    connection.add('token', {
      x,
      y,
      label: 'Data Terminal',
      width: widthFor('40mm'),
      imageUrl: '/Breachstorm/scenarios/objective-blue.png',
      deletable: true,
      immobile: true,
    });
  });
  let y = 0;
  [
    { width: 1320, height: 802, label: 'Obscuring Terrain', imageUrl: '/Breachstorm/terrain/obscuring-terrain.png' },
    { width: 1001, height: 712, label: 'Obscuring Terrain', imageUrl: '/Breachstorm/terrain/obscuring-terrain-2.jpg' },
    { width: 575, height: 861, label: 'Obscuring Terrain', imageUrl: '/Breachstorm/terrain/obstructing-terrain-1.jpg' },
    { width: 572, height: 857, label: 'Obscuring Terrain', imageUrl: '/Breachstorm/terrain/obstructing-terrain-2.jpg' },
    { width: 853, height: 529, label: 'Difficult Terrain', imageUrl: '/Breachstorm/terrain/difficult-terrain.png' },
    { width: 288, height: 289, label: 'Low Terrain', imageUrl: '/Breachstorm/terrain/low-terrain-1.jpg' },
    { width: 288, height: 288, label: 'Low Terrain', imageUrl: '/Breachstorm/terrain/low-terrain-2.jpg' },
    { width: 574, height: 108, label: 'Low Terrain', imageUrl: '/Breachstorm/terrain/low-terrain-3.jpg' },
    { width: 577, height: 110, label: 'Low Terrain', imageUrl: '/Breachstorm/terrain/low-terrain-4.jpg' },
    { width: 577, height: 110, label: 'Low Terrain', imageUrl: '/Breachstorm/terrain/low-terrain-4.jpg' },
  ].forEach(({ imageUrl, label, width, height }) => {
    connection.add('shape', {
      type: 'Image',
      x: 37 + (width / 300),
      y: y + (height / 300),
      label,
      imageUrl,
      width: width / 150,
      height: height / 150,
    });
    y = y + (height / 150) + 0.5;
  });

  [6 + widthFor('50mm') / 2, 30 - widthFor('50mm') / 2].forEach(y => {
    connection.add('widget', {
      widgetType: 'Counter',
      value: 0,
      x: -2,
      y,
      label: 'VPs',
      fill: '#9e9e9e',
      stroke: '#ffffff',
    });
    connection.add('widget', {
      widgetType: 'Counter',
      value: 0,
      x: -2,
      y: y + widthFor('50mm') + 1,
      label: 'Asset Points',
      fill: '#f44336',
      stroke: '#ffffff',
    });
  });
}

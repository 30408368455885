import React, { Component, Fragment } from 'react';

import StorageIcon from '@mui/icons-material/Storage';

import ActionButton from '../../../game/ActionButton';
import RevealDialog from './RevealDialog';

export default class extends Component {
  render() {
    return (
      <Fragment>
        <ActionButton
          icon={StorageIcon}
          primary="Reveal"
          onClick={() => this.props.game.update({ showReveal: true })}
        />
        <RevealDialog
          game={this.props.game}
          log={this.props.log}
          open={this.props.game.attrs.showReveal}
          onClose={() => { this.props.game.update({ showReveal: false }) }}
        />
      </Fragment>
    );
  }
}



import { Component } from 'react';
import { fabric } from 'fabric-with-gestures';
import { LAYER_BACKGROUND } from './Layers';

export default class Background extends Component {
  componentDidMount() {
    let canvas = this.props.canvas;
    let imageUrl = this.props.url;
    if (!imageUrl || !imageUrl.length) {
      imageUrl = '/backgrounds/concrete.jpg';
    }
    fabric.Image.fromURL(imageUrl, (img) => {
      this.backgroundImage = img;
      img.scaleToWidth(this.props.size);
      img.layer = LAYER_BACKGROUND;

      img.set({
        evented: false,
        selectable: false,
      });
      this.clipBackground();

      canvas.add(img);
      canvas.on('object:added', img.sendToBack.bind(img));
      img.sendToBack();
    });
  }
  componentDidUpdate(prevProps) {
    if (!this.backgroundImage) {
      return;
    }
    if (this.props.size !== prevProps.size) {
      this.backgroundImage.scaleToWidth(this.props.size * this.props.canvas.getZoom());
    }
    if (this.props.url !== prevProps.url) {
      this.backgroundImage.setSrc(this.props.url, (image) => {
        this.props.canvas.requestRenderAll();
      });
    }
    if (this.props.shape !== prevProps.shape) {
      this.clipBackground();
    }
  }
  clipBackground() {
    if (this.props.shape === 'circle') {
      this.backgroundImage.set('clipPath', new fabric.Circle({
        radius: this.backgroundImage.width / 2,
        top: 0,
        left: 0,
        originX: 'center',
        originY: 'center',
      }));
    } else {
      this.backgroundImage.set('clipPath', null);
    }
  }
  render() {
    return '';
  }
}

import { Component } from 'react';

export default class FabricComponent extends Component {
  componentDidMount() {
    let fabricObject = this.createFabricObject();
    this.props.canvas.add(fabricObject);
    if (this.props.bringToFront) {
      this.props.canvas.bringToFront(fabricObject);
    }
    this.fabricObject = fabricObject;
    this.updateFabricObject();
  }
  componentDidUpdate() {
    if (this.fabricObject) {
      this.updateFabricObject();
    }
  }
  componentWillUnmount() {
    this.props.canvas.remove(this.fabricObject);
  }
  updateFabricObject() {
    let { attrs } = this.props.record;
    let { pixelsPerInch } = this.props;
    let fabricObjectAttrs = this.fabricObjectAttrs(attrs, pixelsPerInch);
    if (this.fabricObject.group) {
      delete fabricObjectAttrs.top;
      delete fabricObjectAttrs.left;
    }
    this.fabricObject.set(fabricObjectAttrs);
    if (fabricObjectAttrs.top || fabricObjectAttrs.left || fabricObjectAttrs.angle) {
      this.fabricObject.setCoords();
    }
    this.props.canvas.requestRenderAll();
  }
  render() {
    return null;
  }
}

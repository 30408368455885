import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import withWidth, { isWidthUp } from '../../withWidth';

import TimeDisplay from './TimeDisplay';
import toggleActivePlayer from './toggleActivePlayer';
import togglePause from './togglePause';

class ClockHeader extends Component {
  playerSection(player) {
    const { game, log } = this.props;
    const isActivePlayer = game.attrs.clockActivePlayer === player;
    if (!isActivePlayer && isWidthUp(this.props.width, 'sm')) return null;
    const backgroundColor = isActivePlayer && !isWidthUp(this.props.width, 'sm') ? 'rgba(255, 255, 255, 0.3)' : '';
    return (
      <span style={{ width: 90, textAlign: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRadius: 5, overflow: 'hidden', backgroundColor }}>
        {game.attrs[`player${player}Label`]}
        <br/>
        <TimeDisplay game={game} clockActivePlayer={game.attrs.clockActivePlayer} player={player} log={log} />
      </span>
    )
  }
  render() {
    const { game, log } = this.props;
    if (!game.attrs.clockEnabled) return null;
    return (
      <Fragment>
        <Button
          color="inherit"
          style={{ paddingRight: 0, paddingBottom: 0, paddingTop: 0 }}
          onClick={() => toggleActivePlayer(game, log)}
        >
          {this.playerSection(1)}
          {!isWidthUp(this.props.width, 'sm') && (
            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              <SwapCallsIcon />
            </div>
          )}
          {this.playerSection(2)}
        </Button>
        <IconButton color="inherit" aria-label="Menu" onClick={() => togglePause(game, log)}>
          {game.attrs.clockPaused ? <PlayArrowIcon /> : <PauseIcon />}
        </IconButton>
      </Fragment>
    );
  }
}

export default withWidth()(ClockHeader);

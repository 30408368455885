import { fabric } from 'fabric-with-gestures';

export default function({ height, pixelsPerInch, width }) {
  const objects = [];
  for (var i = 6; i < height - 5; i++) {
    let stroke, strokeDashArray;
    if (i % 2 === 0) {
      stroke = '#ffffff';
      strokeDashArray = null;
    } else {
      stroke = '#ffffff';
      strokeDashArray = [5, 5];
    };
    const line = new fabric.Line([0, i * pixelsPerInch, width * pixelsPerInch, i * pixelsPerInch], {
      strokeWidth: 1,
      stroke,
      strokeDashArray,
      opacity: 1,
      deploymentLine: true,
      originX: 'center',
      originY: 'center',
    });
    objects.push(line);
    const text = i > height / 2 ? height - i : i;
    const label = new fabric.Text(`${text}”`, {
      left: pixelsPerInch * width / 2,
      top: pixelsPerInch * i,
      strokeWidth: 1,
      stroke,
      fill: stroke,
      opacity: 0.5,
      fontSize: 0.5 * pixelsPerInch,
      deploymentLine: true,
      originX: 'center',
      originY: 'center',
    });
    objects.push(label);
  }
  [3, 45].forEach(x => {
    const line = new fabric.Line([x * pixelsPerInch, 0, x * pixelsPerInch, height * pixelsPerInch], {
      strokeWidth: 1,
      stroke: '#ffffff',
      opacity: 0.5,
      deploymentLine: true,
      originX: 'center',
      originY: 'center',
    });
    objects.push(line)
  });
  return objects;
}
import React, { Component, Fragment } from 'react';

import MapIcon from '@mui/icons-material/Map';

import ActionButton from '../../../game/ActionButton';
import ScenarioDialog from './ScenarioDialog';

export default class extends Component {
  state = {
    open: false
  }
  render() {
    return (
      <Fragment>
        <ActionButton
          icon={MapIcon}
          primary="Scenario"
          onClick={() => this.setState({ open: true } )}
        />
        <ScenarioDialog
          connection={this.props.connection}
          log={this.props.log}
          game={this.props.game}
          open={this.state.open}
          onClose={() => this.setState({ open: false } )}
        />
      </Fragment>
    );
  }
}

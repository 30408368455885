import React, { Component, Fragment } from 'react';

import TollIcon from '@mui/icons-material/Toll';

import ActionButton from '../../../game/ActionButton';
import AllocateResourcesDialog from './AllocateResourcesDialog';

export default class extends Component {
  state = {
    open: false
  }
  render() {
    return (
      <Fragment>
        <ActionButton
          icon={TollIcon}
          primary="Allocate"
          onClick={() => this.setState({ open: true } )}
        />
        <AllocateResourcesDialog
          connection={this.props.connection}
          game={this.props.game}
          open={this.state.open}
          onClose={() => this.setState({ open: false } )}
        />
      </Fragment>
    );
  }
}

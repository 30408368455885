import { COLORS } from '../ColorPicker';

export function setColor({ selections }) {
  const tokens = selections.filter(selection => selection.type === 'token');
  if (!tokens.length) return [];

  return Object.keys(COLORS).map(color => {
    return {
      label: `Set Base: ${COLORS[color]}`,
      avatar: '/emoji/lower_left_paintbrush.png',
      color,
      run: () => {
        selections.forEach(selection => selection.update({ color }))
      }
    };
  });
}

export function setTint({ selections }) {
  const tokens = selections.filter(selection => selection.type === 'token');
  if (!tokens.length) return [];

  return Object.keys(COLORS).map(color => {
    return {
      label: `Set Tint: ${COLORS[color]}`,
      avatar: '/emoji/lower_left_paintbrush.png',
      color,
      run: () => {
        selections.forEach(selection => selection.update({ tint: color }))
      }
    };
  });
}

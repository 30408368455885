import React, { Component, Fragment } from 'react';

import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';

import ListDetailDialog from '../../../ListDetailDialog';
import modelToToken from './modelToToken';
import ColorPicker from '../../../game/ColorPicker';

const CATEGORIES = {
  "Battle Engine": "Battle Engines & Structures",
  "Command Attachment": "Units",
  "Infernal Master": "Infernal Masters",
  "Horror": "Horrors",
  "Monstrosity": "Warjacks",
  "Solo": "Solos",
  "Structure": "Battle Engines & Structures",
  "Unit": "Units",
  "Weapon Attachment": "Units",
  "Warbeast": "Warbeasts",
  "Warcaster": "Warcasters",
  "Warcaster Attachment": "Solos",
  "Warcaster Unit": "Warcasters",
  "Warjack": "Warjacks",
  "Warlock": "Warlocks",
  "Warlock Attachment": "Solos",
  "Warlock Unit": "Warlocks",
};

export default class AddModelDialog extends Component {
  state = {
    searchText: '',
  }
  modelItem(model, indent: false) {
    return (
      <Fragment key={`${model.name} ${model.faction}`}>
        <ListItem dense button onClick={() => this.addModel(model)}>
          <ListItemAvatar style={{ marginLeft: (indent ? 16 : 0) }}>
            <Avatar src={model.avatar}/>
          </ListItemAvatar>
          <ListItemText>{model.alias || model.name}</ListItemText>
        </ListItem>
        {(model.includes || []).map(model => this.modelItem(model, { indent: true }))}
      </Fragment>
    );
  }
  addModel(model) {
    const { selection } = this.props;
    let x = selection ? selection.attrs.x : 24;
    let y = selection ? selection.attrs.y : 24;
    const attrs = Object.assign({}, modelToToken(model), {
      x,
      y,
      facing: 0,
      color: this.props.color
    });
    this.props.onAddToken(attrs);
  }
  modelList(faction) {
    const models = this.props.moduleData.models.filter(model => model.faction === faction);
    const categories = Array.from(new Set(models.map(model => {
      // if (!CATEGORIES[model.type]) throw `Missing category for ${model.name}: ${model.type}`;
      return CATEGORIES[model.type];
    })));
    return categories.map((category, i) => (
      <Fragment key={`category-${i}`}>
        <ListSubheader style={{ backgroundColor: '#fff' }}>{category}</ListSubheader>
        {models.filter(model => CATEGORIES[model.type] === category).map(model => this.modelItem(model))}
      </Fragment>
    ))
  }
  factionDetail = (faction) => {
    return (
      <Fragment>
        <ColorPicker
          label="Base Color"
          value={this.props.color}
          onChange={(e) => this.props.onChangeColor(e.target.value)}
        />
        <List>
          {this.modelList(faction)}
        </List>
      </Fragment>
    )
  }
  filteredModels() {
    const { searchText } = this.state;
    let searchTextLower = searchText.toLowerCase();
    const allModels = [];
    this.props.moduleData.models.forEach(model => {
      allModels.push(model);
      (model.includes || []).forEach(included => allModels.push(included))
    });
    const matches = allModels.filter(model => {
      return model.name.toLowerCase().indexOf(searchTextLower) >= 0 || (model.alias || '').toLowerCase().indexOf(searchTextLower) >= 0
    });
    return matches.slice(0, 25);
  }
  searchDetail = () => {
    return (
      <Fragment>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              name="search"
              label="Search Text"
              value={this.state.searchText}
              onChange={e => this.setState({ searchText: e.target.value })}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ColorPicker
              label="Base Color"
              value={this.props.color}
              onChange={(e) => this.props.onChangeColor(e.target.value)}
            />
          </Grid>
        </Grid>
        <List>
          {this.filteredModels().map(model => this.modelItem(model))}
        </List>
      </Fragment>
    )
  }
  render() {
    const { onClose, open } = this.props;
    if (!open) return null;

    return (
      <ListDetailDialog
        onClose={onClose}
        title="Add Model"
        selectFirst
        itemCategories={{ "Quick Add": ['Search'], "Factions": [...new Set(this.props.moduleData.models.map(model => model.faction))] }}
        detailTitle={(faction) => faction}
        renderItem={(faction) => (
          <Fragment>
            <ListItemText primary={faction} />
          </Fragment>
        )}
        renderDetail={(faction) => {
          if (faction === 'Search') {
            return this.searchDetail();
          }
          return this.factionDetail(faction);
        }}
      />
    )
  }
}

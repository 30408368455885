import React, { Component } from 'react';
import ReactGA from 'react-ga4';

import Avatar from '@mui/material/Avatar';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { auraColor, auraRadius, meleeRange, toggleAura } from './commands/aura';
import { setColor, setTint } from './commands/color';
import { addEffects, customEffect, removeEffects } from './commands/effects';
import { addModel } from './commands/models';
import { addWidgets, customToken } from './commands/widgets';

import Dialog from '../WTDialog';

class CommandDialog extends Component {
  state = {
    selectedIndex: 0,
    text: ''
  }
  bindKeyboardEvents = (input) => {
    if (!input) return;
    input.addEventListener('keydown', this.keydown);
  }
  keydown = e => {
    const { selectedIndex } = this.state;
    if (e.code === 'ArrowDown') {
      this.setState({ selectedIndex: selectedIndex + 1 })
    }
    if (e.code === 'ArrowUp') {
      this.setState({ selectedIndex: Math.max(0, selectedIndex - 1) })
    }
    if (e.code === 'Enter') {
      this.runCommand(selectedIndex);
    }
  }
  runCommand(i) {
    let command = this.commands()[i];
    if (command) {
      command.run();
      ReactGA.event({
        category: 'Command',
        action: command.label,
      });
    }
    this.props.onClose();
  }
  commands() {
    const { text } = this.state;
    const { canvas, connection, extensions, game, log, moduleData, selection, selections } = this.props;
    const { system } = game.attrs;
    let commands = [];
    [
      removeEffects,
      addWidgets,
      toggleAura,
      addEffects,
      auraColor,
      auraRadius,
      setColor,
      setTint,
      meleeRange,
      addModel,
      customEffect,
      customToken,
    ].concat(extensions.commands || []).forEach(generator => {
      commands = commands.concat(generator.apply(null, [{ canvas, connection, extensions, game, log, moduleData, selection, selections, system, text }]))
    });
    const textLetters = text.split("").map(l => l.toLowerCase());
    return commands.filter(command => {
      let match = true;
      let lastMatch = 0;
      let label = command.label.toLowerCase();
      textLetters.forEach(l => {
        lastMatch = label.indexOf(l, lastMatch);
        if (lastMatch < 0) match = false;
      });
      return match;
    }).slice(0, 25);
  }
  render() {
    if (!this.props.open) return null;

    return (
      <Dialog open fullWidth maxWidth="sm" onClose={this.props.onClose}>
        <DialogTitle>Command Mode</DialogTitle>
        <DialogContent>
          <Typography paragraph variant="body2" color="textSecondary">Use command mode to quickly add effects, widgets, models, or adjust auras.</Typography>
          <TextField
            name="search"
            label="Command"
            value={this.state.text}
            onChange={(e) => { this.setState({ selectedIndex: 0, text: e.target.value })}}
            fullWidth
            style={{ marginBottom: 16 }}
            autoFocus
            inputRef={input => this.bindKeyboardEvents(input)}
            variant="standard"
          />
          <List style={{ marginLeft: -24, marginRight: -24 }}>
            {this.commands().map((command, i) => (
              <ListItem key={`command${i}`} button onClick={() => this.runCommand(i)} selected={this.state.selectedIndex === i}>
                {command.icon ? (
                  <ListItemIcon>{command.icon}</ListItemIcon>
                ) : (
                  <ListItemAvatar>
                    <Avatar src={command.avatar} alt={command.label} style={{ backgroundColor: command.color }}>{command.iconText}</Avatar>
                  </ListItemAvatar>
                )}
                <ListItemText>{command.label}</ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    )
  }
}

export default CommandDialog;

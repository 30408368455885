import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { onValue, ref, remove } from '@firebase/database';

import { db } from '../../../initFirebase';
import Dialog from '../../../WTDialog';
import scenarios from '../data/scenarios';
import { widthFor } from '../../../map/Token';
import settings from '../data/settings';

const MAPS_WITH_ZONES = ['beach', 'city', 'desert', 'fishcord', 'grasslands', 'swamp'];
const { gameSettings } = settings;

export default class ScenarioDialog extends Component {
  resetScenario = () => {
    const { connection } = this.props;
    return new Promise(resolve => {
      onValue(ref(db, connection.rootPath()), data => {
        const refsToRemove = [];
        data.child('shapes').forEach(shape => {
          const val = shape.val();
          if (val.scenario) {
            refsToRemove.push(shape.ref);
          }
        });
        data.child("deployments").forEach((shape) => {
          const val = shape.val();
          if (val.scenario) {
            refsToRemove.push(shape.ref);
          }
        });
        data.child('tokens').forEach(token => {
          const val = token.val();
          if (val.scenario) {
            refsToRemove.push(token.ref);
          }
        });
        data.child('widgets').forEach(widget => {
          const val = widget.val();
          if (val.widgetType === 'Counter') {
            refsToRemove.push(widget.ref);
          }
        });
        Promise.all(refsToRemove.map(ref => remove(ref))).then(resolve);
      }, { onlyOnce: true });
    });
  }
  setScenario = (scenario) => {
    this.props.log(`Selected scenario: ${scenario.name}`);
    this.createScenario(scenario);
    this.props.onClose();
  }
  randomScenario = (scenarios) => {
    const roll = Math.floor(Math.random() * scenarios.length);
    const scenario = scenarios[roll];
    this.props.log(`Rolled ${roll + 1} for scenario: ${scenario.name}`);
    this.createScenario(scenario);
    this.props.onClose();
  }
  createScenario(scenario) {
    this.resetScenario().then(() => this.createScenarioElements(scenario));
  }
  createScenarioElements = (scenario) => {
    const {elements, deployment} = scenario;
    const { connection, game } = this.props;
    let rectZoneIndex = 1;
    let roundZoneIndex = 1;
    elements.forEach(element => {
      const attrs = {
        x: element.x,
        y: element.y,
        facing: element.facing || 0,
        deletable: true,
        scenario: true,
      };

      if (element.type === 'Circle') {
        attrs.type = 'Circle';
        attrs.width = element.radius || 12;
        MAPS_WITH_ZONES.forEach(map => {
          if (game.attrs.backgroundImageUrl === `/backgrounds/${map}.jpg`) {
            attrs.type = 'Image';
            attrs.imageUrl = `/terrain/${map}/zone-round${roundZoneIndex}.png`;
            roundZoneIndex += 1;
            attrs.height = attrs.width;
          }
        });
        attrs.fill = element.fill || '#9e9e9e';
        attrs.label = element.label || '';
        attrs.stroke = element.stroke || '#000000';
        connection.add('shape', attrs);
      }
      if (element.type === 'Objective') {
        attrs.width = widthFor(element.size || '54mm');
        attrs.color = element.color || '#000000';
        if (element.imageUrl) attrs.imageUrl = element.imageUrl;
        attrs.resource1 = element.health === undefined ? 5 : element.health;
        attrs.label = element.label || 'Objective';
        attrs.immobile = element.immobile === undefined ? true : element.immobile;
        connection.addToken(attrs);
      }
      if (element.type === 'Rect') {
        attrs.type = 'Rect';
        if (element.imageUrl) attrs.imageUrl = element.imageUrl;
        attrs.facing = element.facing || 0;
        attrs.width = element.width;
        attrs.height = element.height;
        attrs.fill = element.fill || '#9e9e9e';
        attrs.label = element.label || '';
        attrs.stroke = element.stroke || '#000000';
        connection.add('shape', attrs);
      }
      if (element.type === 'Line') {
        attrs.type = 'Line';
        attrs.width = element.width;
        attrs.label = element.label || '';
        attrs.stroke = element.stroke || '#9e9e9e';
        connection.add('shape', attrs);
      }
      if (element.type === 'Image') {
        attrs.type = 'Image';
        attrs.width = element.width;
        attrs.height = element.height;
        attrs.imageUrl = element.imageUrl;
        attrs.label = element.label || '';
        attrs.stroke = element.stroke || '#9e9e9e';
        connection.add('shape', attrs);
      }
    });

    deployment.forEach((deployment) => {
			const attrs = {
				x: deployment.x,
				y: deployment.y,
				facing: deployment.facing || 0,
				deletable: true,
				scenario: true,
			};

			if (deployment.type === "Rect") {
				attrs.type = "Rect";
				if (deployment.imageUrl) attrs.imageUrl = deployment.imageUrl;
				attrs.width = deployment.width;
				attrs.height = deployment.height;
				attrs.fill = deployment.fill || "#9e9e9e";
				attrs.label = deployment.label || "";
				attrs.stroke = deployment.stroke || "#000000";
				connection.add("deployment", attrs);
			}
      if (deployment.type === "Polygon") {
				attrs.type = "Polygon";
				if (deployment.imageUrl) attrs.imageUrl = deployment.imageUrl;
				attrs.points = deployment.points;
				attrs.fill = deployment.fill || "#9e9e9e";
				attrs.label = deployment.label || "";
				attrs.stroke = deployment.stroke || "#000000";
				connection.add("deployment", attrs);
			}
		});

    connection.add("widget", {
      widgetType: "Counter",
      x: 2,
      y: 28,
      label: "VPs",
      value: 0,
      fill: gameSettings.player1Color,
      facing: 0,
      deletable: false,
    });

    connection.add("widget", {
      widgetType: "Counter",
      x: 2,
      y: 20,
      label: "VPs",
      value: 0,
      fill: gameSettings.player2Color,
      facing: 0,
      deletable: false,
    });

    connection.add('widget', {
      widgetType: 'Counter',
      x: 2,
      y: 24,
      label: 'Turn',
      value: 1,
      fill: '#9e9e9e',
      facing: 0,
      deletable: true
    })
  }
  render() {
    const { onClose, open } = this.props;
    if (!open) return null;

    return (
      <Dialog maxWidth="md" open onClose={onClose}>
        <DialogTitle>Select a scenario</DialogTitle>
        <DialogContent>
          <div>
            <Button style={{ marginBottom: 16, marginRight: 16 }} color="primary" variant="contained" onClick={() => this.resetScenario().then(onClose)}>Clear</Button>
          </div>
          {scenarios.map((section, i) => (
            <Fragment key={i}>
              <Typography variant="h4">{section.section}</Typography>
              {section.description && (
                <Typography variant="body2" style={{ marginBottom: 16 }}>{section.description}</Typography>
              )}
              <Button style={{ marginBottom: 16, marginRight: 16 }} color="primary" variant="contained" onClick={() => this.randomScenario(section.scenarios)}>Random</Button>
              <div key={i} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {section.scenarios.map((scenario, j) => (
                  <Card key={j} elevation={8} style={{ marginRight: 16, marginBottom: 16, width: 250 }}>
                    <CardActionArea onClick={() => { this.setScenario(scenario) }} style={{height: "100%", display: "flex", flexDirection: "column", alignItems: "stretch"}}>
                      <CardHeader title={scenario.name} style={{flexGrow: 1}}/>
                      <CardMedia style={{ height: 250 }} title={scenario.name} image={scenario.image} />
                    </CardActionArea>
                  </Card>
                ))}
              </div>
            </Fragment>
          ))}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

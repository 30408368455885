import React, { Component, Fragment } from 'react';
import Fab from '@mui/material/Fab';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import CasinoIcon from '@mui/icons-material/Casino';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import EditSelectionState from '../game/EditSelectionState';
import { drawerWidth } from '../game/SelectionDrawer';


class SelectionTooltip extends Component {
  state = {
    expanded: false
  }
  toggleExpanded = (e) => {
    this.setState({ expanded: !this.state.expanded });
  }
  render() {
    const { selection } = this.props;
    let title;
    let isToken = selection && selection.type === 'token';
    if (isToken) {
      title = selection.attrs.label || '<No Label>';
    }
    if (selection && selection.type === 'shape') {
      title = selection.attrs.type;
    }
    if (selection && selection.type === 'widget') {
      title = selection.attrs.widgetType;
    }
    if (selection && selection.type === 'deck') {
      title = selection.attrs.label;
    }
    return (
      <div
        style={{ position: 'fixed', bottom: 0, right: 0, left: 0, zIndex: 1150, width: drawerWidth, right: -1 * drawerWidth, left: 'auto' }}
      >
        <Fab
          color="primary"
          style={{position: 'absolute', top: 8 * -2, right: 8 * 2, transform: 'translateY(-100%)', }}
          onClick={this.props.onRoll}
        >
          <CasinoIcon />
        </Fab>
        <Paper
          square
          style={{ paddingBottom: "env(safe-area-inset-bottom)" }}
        >
          {title && (
            <Fragment>
              <div style={{ paddingBottom: 16, display: "flex", justifyContent: 'space-between', alignContent: 'center' }} onClick={this.toggleExpanded}>
                <Typography variant="h6" style={{ marginTop: 8, flexShrink: 1, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {selection &&
                    <ExpandMoreIcon
                      style={this.state.expanded ? {
                          transform: 'rotate(0deg) translateY(4px)',
                          transition: theme.transitions.create('transform', {
                            duration: theme.transitions.duration.shortest,
                          })
                        } : {
                          transform: 'rotate(180deg) translateY(-4px)',
                        }
                      }
                    />
                  }
                  {title}
                </Typography>
                <div style={{ flexShrink: 0 }}>{this.props.actions}</div>
              </div>
              <Collapse in={selection && this.state.expanded} timeout="auto" unmountOnExit style={{ padding: 16, maxHeight: '50vh', overflowY: 'auto' }}>
                <EditSelectionState extensions={this.props.extensions} effects={this.props.effects} log={this.props.log} game={this.props.game} selection={selection} />
              </Collapse>
            </Fragment>
          )}
        </Paper>
      </div>
    );
  }
}

export default SelectionTooltip;

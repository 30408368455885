// import React, { Fragment } from "react";
import settings from "./settings";

const player1Color = settings.gameSettings.player1Color;
const player2Color = settings.gameSettings.player2Color;

export default [
	{
		section: "First Blood Scenario Pack 2024",
		description: (
			<>
				View the full rules online&nbsp;
				<a
					href="https://www.para-bellum.com/community/#organized-play"
					target="_blank"
					rel="noreferrer noopener"
					style={{ textDecoration: "underline" }}
				>
					here
				</a>
			</>
		),
		scenarios: [
			{
				name: "Meeting Engagement",
				elements: [
					{ type: "Circle", x: 12, y: 24, radius: 12 },
					{ type: "Circle", x: 36, y: 24, radius: 12 },
					{ type: "Objective", x: 12, y: 18, color: player2Color },
					{ type: "Objective", x: 12, y: 30, color: player1Color },
					{ type: "Objective", x: 36, y: 18, color: player2Color },
					{ type: "Objective", x: 36, y: 30, color: player1Color },
				],
				deployment: [
					{
						type: "Rect",
						x: 24,
						y: 42,
						height: 12,
						width: 48,
						fill: player1Color,
					},
					{
						type: "Rect",
						x: 24,
						y: 6,
						height: 12,
						width: 48,
						fill: player2Color,
					},
				],
				image: "/Conquest/FB/scenarios/meeting-engagement.jpg",
			},
			{
				name: "Outflank",
				elements: [
					{ type: "Circle", x: 12, y: 12, radius: 9 },
					{ type: "Circle", x: 36, y: 36, radius: 9 },
					{ type: "Objective", x: 18, y: 30, color: player1Color },
					{ type: "Objective", x: 30, y: 18, color: player2Color },
				],
				deployment: [
					{
						type: "Rect",
						x: 6,
						y: 42,
						height: 12,
						width: 12,
						fill: player1Color,
					},
					{
						type: "Rect",
						x: 42,
						y: 6,
						height: 12,
						width: 12,
						fill: player2Color,
					},
				],
				image: "/Conquest/FB/scenarios/outflank.jpg",
			},
			{
				name: "Shieldwall",
				elements: [
					{ type: "Circle", x: 6, y: 24, radius: 6 },
					{ type: "Circle", x: 24, y: 24, radius: 6 },
					{ type: "Circle", x: 42, y: 24, radius: 6 },
				],
				deployment: [
					{
						type: "Rect",
						x: 24,
						y: 42,
						height: 12,
						width: 24,
						fill: player1Color,
					},
					{
						type: "Rect",
						x: 24,
						y: 6,
						height: 12,
						width: 24,
						fill: player2Color,
					},
				],
				image: "/Conquest/FB/scenarios/shieldwall.jpg",
			},
			{
				name: "Raid",
				elements: [
					{ type: "Objective", x: 6, y: 6 },
					{ type: "Objective", x: 18, y: 18 },
					{ type: "Objective", x: 30, y: 30 },
					{ type: "Objective", x: 42, y: 42 },
				],
				deployment: [
					{
						type: "Polygon",
						x: 12,
						y: 36,
						points: [
							{ x: 0, y: 24 },
							{ x: 12, y: 24 },
							{ x: 12, y: 36 },
							{ x: 24, y: 36 },
							{ x: 24, y: 48 },
							{ x: 0, y: 48 },
						],
						fill: player1Color,
					},
					{
						type: "Polygon",
						x: 36,
						y: 12,
						points: [
							{ x: 24, y: 0 },
							{ x: 48, y: 0 },
							{ x: 48, y: 24 },
							{ x: 36, y: 24 },
							{ x: 36, y: 12 },
							{ x: 24, y: 12 },
						],
						fill: player2Color,
					},
				],
				image: "/Conquest/FB/scenarios/raid.jpg",
			},
			{
				name: "Area Control",
				elements: [
					{ type: "Circle", x: 6, y: 6, radius: 6, fill: player2Color },
					{ type: "Circle", x: 18, y: 18, radius: 6 },
					{ type: "Circle", x: 30, y: 30, radius: 6 },
					{ type: "Circle", x: 42, y: 42, radius: 6, fill: player1Color },
				],
				deployment: [
					{
						type: "Rect",
						x: 18,
						y: 42,
						height: 12,
						width: 36,
						fill: player1Color,
					},
					{
						type: "Rect",
						x: 30,
						y: 6,
						height: 12,
						width: 36,
						fill: player2Color,
					},
				],
				image: "/Conquest/FB/scenarios/area-control.jpg",
			},
			{
				name: "Broken Lines",
				elements: [
					{ type: "Circle", x: 42, y: 6, radius: 6, fill: player2Color },
					{ type: "Circle", x: 18, y: 18, radius: 6 },
					{ type: "Circle", x: 30, y: 30, radius: 6 },
					{ type: "Circle", x: 6, y: 42, radius: 6, fill: player1Color },
					{ type: "Objective", x: 6, y: 6, color: player2Color },
					{ type: "Objective", x: 42, y: 42, color: player1Color },
				],
				deployment: [
					{
						type: "Rect",
						x: 18,
						y: 36,
						height: 24,
						width: 12,
						fill: player1Color,
					},
					{
						type: "Rect",
						x: 30,
						y: 12,
						height: 24,
						width: 12,
						fill: player2Color,
					},
				],
				image: "/Conquest/FB/scenarios/broken-lines.jpg",
			},
			{
				name: "Tug-Of-War",
				elements: [
					{ type: "Circle", x: 6, y: 6, radius: 6, fill: player2Color },
					{ type: "Circle", x: 42, y: 42, radius: 6, fill: player1Color },
					{ type: "Objective", x: 24, y: 24, immobile: false },
				],
				deployment: [
					{
						type: "Polygon",
						x: 12,
						y: 36,
						points: [
							{ x: 0, y: 36 },
							{ x: 12, y: 36 },
							{ x: 12, y: 24 },
							{ x: 24, y: 24 },
							{ x: 24, y: 48 },
							{ x: 0, y: 48 },
						],
						fill: player1Color,
					},
					{
						type: "Polygon",
						x: 36,
						y: 12,
						points: [
							{ x: 24, y: 0 },
							{ x: 48, y: 0 },
							{ x: 48, y: 12 },
							{ x: 36, y: 12 },
							{ x: 36, y: 24 },
							{ x: 24, y: 24 },
						],
						fill: player2Color,
					},
				],
				image: "/Conquest/FB/scenarios/tug-of-war.jpg",
			},
			{
				name: "Tic-Tac-Blood",
				elements: [
					{ type: "Objective", x: 12, y: 12 },
					{ type: "Objective", x: 12, y: 24 },
					{ type: "Objective", x: 12, y: 36, color: player1Color },
					{ type: "Objective", x: 24, y: 12 },
					{ type: "Objective", x: 24, y: 24 },
					{ type: "Objective", x: 24, y: 36 },
					{ type: "Objective", x: 36, y: 12, color: player2Color },
					{ type: "Objective", x: 36, y: 24 },
					{ type: "Objective", x: 36, y: 36 },
				],
				deployment: [
					{
						type: "Rect",
						x: 6,
						y: 42,
						height: 12,
						width: 12,
						fill: player1Color,
					},
					{
						type: "Rect",
						x: 42,
						y: 6,
						height: 12,
						width: 12,
						fill: player2Color,
					},
				],
				image: "/Conquest/FB/scenarios/tic-tac-blood.jpg",
			},
			{
				name: "Advanced Maneuvers",
				elements: [
					{ type: "Objective", x: 24, y: 36, color: player1Color },
					{ type: "Objective", x: 36, y: 24, color: player1Color },
					{ type: "Objective", x: 12, y: 24, color: player2Color },
					{ type: "Objective", x: 24, y: 12, color: player2Color },
					{ type: "Circle", x: 12, y: 36, radius: 6 },
					{ type: "Circle", x: 36, y: 12, radius: 6 },
				],
				deployment: [
					{
						type: "Polygon",
						x: 12,
						y: 12,
						points: [
							{ x: 0, y: 0 },
							{ x: 24, y: 0 },
							{ x: 0, y: 24 },
						],
						fill: player2Color,
					},
					{
						type: "Polygon",
						x: 36,
						y: 36,
						points: [
							{ x: 24, y: 48 },
							{ x: 48, y: 24 },
							{ x: 48, y: 48 },
						],
						fill: player1Color,
					},
				],
				image: "/Conquest/FB/scenarios/advanced-maneuvers.jpg",
			},
			{
				name: "Pincer",
				elements: [
					{ type: "Objective", x: 12, y: 30, color: player1Color },
					{ type: "Objective", x: 36, y: 18, color: player2Color },
					{ type: "Circle", x: 12, y: 18, radius: 9 },
					{ type: "Circle", x: 36, y: 30, radius: 9 },
				],
				deployment: [
					{
						type: "Rect",
						x: 12,
						y: 42,
						height: 12,
						width: 24,
						fill: player1Color,
					},
					{
						type: "Rect",
						x: 36,
						y: 6,
						height: 12,
						width: 24,
						fill: player2Color,
					},
				],
				image: "/Conquest/FB/scenarios/pincer.jpg",
			},
			{
				name: "Into the Grinder",
				elements: [
					{ type: "Objective", x: 24, y: 6 },
					{ type: "Objective", x: 24, y: 18, color: player1Color },
					{ type: "Objective", x: 24, y: 30, color: player2Color },
					{ type: "Objective", x: 24, y: 42 },
					{ type: "Circle", x: 24, y: 24, radius: 12 },
				],
				deployment: [
					{
						type: "Polygon",
						x: 12,
						y: 24,
						points: [
							{ x: 0, y: 0 },
							{ x: 24, y: 24 },
							{ x: 0, y: 48 },
						],
						fill: player1Color,
					},
					{
						type: "Polygon",
						x: 36,
						y: 24,
						points: [
							{ x: 24, y: 24 },
							{ x: 48, y: 0 },
							{ x: 48, y: 48 },
						],
						fill: player2Color,
					},
				],
				image: "/Conquest/FB/scenarios/into-the-grinder.jpg",
			},
			{
				name: "Fall into Rank",
				elements: [
					{ type: "Objective", x: 24, y: 18, color: player2Color },
					{ type: "Objective", x: 24, y: 30, color: player1Color },
					{ type: "Circle", x: 24, y: 24, radius: 12 },
					{ type: "Circle", x: 12, y: 12, radius: 6 },
					{ type: "Circle", x: 36, y: 36, radius: 6 },
				],
				deployment: [
					{
						type: "Polygon",
						x: 12,
						y: 36,
						points: [
							{ x: 0, y: 48 },
							{ x: 0, y: 24 },
							{ x: 24, y: 48 },
						],
						fill: player1Color,
					},
					{
						type: "Polygon",
						x: 36,
						y: 12,
						points: [
							{ x: 24, y: 0 },
							{ x: 48, y: 0 },
							{ x: 48, y: 24 },
						],
						fill: player2Color,
					},
				],
				image: "/Conquest/FB/scenarios/fall-into-rank.jpg",
			},
		],
	},
];

import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Dialog from '../../../WTDialog';
import GamePlans from '../GamePlans';

export default class GamePlanDialog extends Component {
  state = {
    revealPlayer1: false,
    revealPlayer2: false,
  }
  deal() {
    const { game } = this.props;
    const array = [...GamePlans];
    let i = array.length - 1;
    for (i; i > 0; i--) {
      const j = Math.floor(Math.random() * i)
      const temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
    const player1Cards = game.attrs.player1ExtraCard ? 8 : 7;
    const player2Cards = game.attrs.player2ExtraCard ? 8 : 7;
    game.update({ player1Discard: [], player2Discard: [], player1GamePlan: {}, player2GamePlan: {}, player1Cards: array.slice(0, player1Cards), player2Cards: array.slice(player1Cards, player1Cards + player2Cards) });
  }
  discard(player, i) {
    const { game } = this.props;
    let { phase }  = game.attrs;
    let cards = [...game.attrs[`player${player}Cards`]];
    cards.splice(i, 1);
    const otherPlayer = player === 1 ? 2 : 1;
    if (cards.length <= this.handLimit(player) && game.attrs[`player${otherPlayer}Cards`].length <= this.handLimit(otherPlayer)) {
      phase = null;
    }
    game.update({ [`player${player}Cards`]: cards, phase })
  }
  pickUpDiscard(player) {
    const { game } = this.props;
    game.update({ [`player${player}Cards`]: game.attrs[`player${player}Discard`], [`player${player}Discard`]: [] })
  }
  needsToDiscard() {
    const { game } = this.props;
    const { player1Cards, player2Cards } = game.attrs;
    return (player1Cards || []).length > this.handLimit(1) || (player2Cards || []).length > this.handLimit(2);
  }
  choose(player, i) {
    const { game } = this.props;
    let cards = [...(game.attrs[`player${player}Cards`] || [])];
    const discard = [...(game.attrs[`player${player}Discard`] || [])];
    const played = cards.splice(i, 1)[0];
    discard.push(played);
    game.update({ gamePlansRevealed: false, [`player${player}GamePlan`]: played, [`player${player}Cards`]: cards, [`player${player}Discard`]: discard })
  }
  revealSelected = () => {
    const { game } = this.props;
    let { player1GamePlan, player2GamePlan, player1Label, player2Label } = game.attrs;
    game.update({ gamePlansRevealed: true }).then(() => {
      player1GamePlan = player1GamePlan || { label: 'None', influence: 0, initiative: 0 };
      player2GamePlan = player2GamePlan || { label: 'None', influence: 0, initiative: 0 };
      this.props.onLog(`${player1Label} played ${player1GamePlan.label} (+${player1GamePlan.initiative} Initiative, ${player1GamePlan.influence >= 0 ? '+' : ''}${player1GamePlan.influence} Influence). ${player2Label} played ${player2GamePlan.label} (+${player2GamePlan.initiative} Initiative, ${player2GamePlan.influence >= 0 ? '+' : ''}${player2GamePlan.influence} Influence).`);
    });
  }
  revealPlayer(player) {
    const otherPlayer = player === 1 ? 2 : 1;
    if (this.state[`revealPlayer${otherPlayer}`]) {
      if (!window.confirm("Are you sure you want to view both players’ cards?")) {
        return;
      }
      this.props.onLog(`Viewing both players’ game plans`);
    } else {
      this.props.onLog(`Viewing ${this.props.game.attrs[`player${player}Label`]}’s game plans`);
    }
    this.setState({ [`revealPlayer${player}`]: true });
  }
  handLimit(player) {
    const { game } = this.props;
    const extraCard = game.attrs[`player${player}ExtraCard`];
    return extraCard ? 6 : 5;
  }
  hand(player) {
    const { game } = this.props;
    const { gamePlansRevealed } = game.attrs;
    const playerLabel = game.attrs[`player${player}Label`];
    const selectedGamePlan = game.attrs[`player${player}GamePlan`];
    const reveal = this.state[`revealPlayer${player}`];
    const cards = game.attrs[`player${player}Cards`] || [];
    const discardedCards = game.attrs[`player${player}Discard`] || [];
    const extraCard = game.attrs[`player${player}ExtraCard`];
    const handLimit = this.handLimit(player);
    const discard = cards.length > handLimit;
    return (
      <Fragment>
        <Typography gutterBottom variant="h3">{playerLabel}</Typography>
        {selectedGamePlan && (
          <Fragment>
            <Typography gutterBottom variant="h6">
              Selected Plan
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <Card
                style={{ marginRight: 24, marginBottom: 24 }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    image={gamePlansRevealed ? selectedGamePlan.imageUrl : `/GuildBall/game-plans/deck.jpg`}
                    style={{ height: 300, width: 220, objectFit: 'contain' }}
                  />
                </CardActionArea>
              </Card>
            </div>
          </Fragment>
        )}
        {!selectedGamePlan && (cards.length > 0 || discardedCards.length > 0) && (
          <Fragment>
            <Typography gutterBottom variant="h6" style={{ display: 'flex' }}>
              <span>Hand</span>
              {reveal ? (
                <Button style={{ marginLeft: 16 }} variant="contained" onClick={() => { this.setState({ [`revealPlayer${player}`]: false })}}>Hide</Button>
              ) : (
                <Button style={{ marginLeft: 16 }} color="primary" variant="contained" onClick={() => this.revealPlayer(player)}>View</Button>
              )}
            </Typography>
            {discard && (
              <Typography paragraph>Discard down to {handLimit} game plans</Typography>
            )}
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              {reveal && cards.length === 0 && (
                <Card
                  onClick={() => this.pickUpDiscard(player)}
                  style={{ marginRight: 24, marginBottom: 24 }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={`/GuildBall/game-plans/deck.jpg`}
                      style={{ height: 300, width: 220, objectFit: 'contain' }}
                    />
                  </CardActionArea>
                  <CardActions>
                    <Button>Pick Up Discard</Button>
                  </CardActions>
                </Card>
              )}
              {cards.map((card, i) => (
                <Card
                  key={card.label}
                  onClick={() => { if (!reveal || (!discard && this.needsToDiscard())) return; discard ? this.discard(player, i) : this.choose(player, i) }}
                  style={{ marginRight: 24, marginBottom: 24 }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={reveal ? card.imageUrl : '/GuildBall/game-plans/deck.jpg'}
                      style={{ height: 300, width: 220, objectFit: 'contain' }}
                    />
                  </CardActionArea>
                  {reveal && (discard || !this.needsToDiscard()) && (
                    <CardActions>
                      <Button>{discard ? 'Discard' : 'Choose'}</Button>
                    </CardActions>
                  )}
                </Card>
              ))}
            </div>
          </Fragment>
        )}
        {(cards.length === 0 && discardedCards.length === 0) && (
          <FormControlLabel
            control={
              <Checkbox
                checked={extraCard}
                onChange={(e) => { game.update({ [`player${player}ExtraCard`]: !extraCard }) }}
                name="extraCard"
                color="primary"
              />
            }
            label="Extra card?"
          />
        )}
      </Fragment>
    )
  }
  reset = () => {
    this.props.game.update({
      player1Discard: [], player2Discard: [], player1GamePlan: {}, player2GamePlan: {}, player1Cards: [], player2Cards: []
    })
  }
  render() {
    const { game, onClose, open } = this.props;
    if (!open) return null;

    return (
      <Dialog open={open} fullScreen onClose={onClose}>
        <DialogTitle style={{ height: 100 }}>
          Game Plans
          {game.attrs.player1GamePlan && game.attrs.player2GamePlan && (
            game.attrs.gamePlansRevealed ? (
              <Button style={{ marginLeft: 140 }} variant="contained" color="primary" onClick={() => game.update({ player1GamePlan: null, player2GamePlan: null, gamePlansRevealed: false })}>Discard</Button>
            ) : (
              <Button style={{ marginLeft: 24 }} variant="contained" color="primary" onClick={this.revealSelected}>Reveal</Button>
            )
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {[1,2].map(player => (
              <Grid key={player} item xs={12} sm={6}>
                {this.hand(player)}
              </Grid>
            ))}
            {!game.attrs.player1Cards && !game.attrs.player1Discard && (
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">Deck</Typography>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <Card
                    onClick={() => this.deal()}
                    style={{ marginRight: 24, marginBottom: 24 }}
                  >
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        image={`/GuildBall/game-plans/deck.jpg`}
                        style={{ height: 300, width: 220, objectFit: 'contain' }}
                      />
                    </CardActionArea>
                    <CardActions>
                      <Button>Deal</Button>
                    </CardActions>
                  </Card>
                </div>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.reset}>Reset</Button>
          <Button onClick={this.props.onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

import React, { Fragment } from 'react';

export default [
  {
    "section": "Steamroller 2024",
    "description": <Fragment>Steamroller is the official organized play format. Get <a href="https://privateerpress.com/steamroller/" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>the Steamroller 2024 packet</a> for rules.</Fragment>,
    "scenarios": [
      {
        "name": "Recon MK4",
        "elements": [
          { type: "Objective", size: "30mm", color: '#f44336', x: 18 + 0.59055, y: 18 + 0.59055, },
          { type: "Flag", size: "20mm", label: "Cache (Player1)", color: '#f44336', x: 48 - 23 - 0.393701, y: 20 + 0.393701, },
          { type: "Flag", size: "20mm", label: "Cache (Player2)", color: '#f44336', x: 48 - 23 - 0.393701, y: 18 + 0.393701, },
          { type: "Flag", size: "20mm", color: '#f44336', x: 48 - 12 - 0.393701, y: 23 + 0.393701, },
          { type: "Objective", size: "30mm", color: '#2196f3', x: 48 - 18 - 0.59055, y: 48 - 18 - 0.59055, },
          { type: "Flag", size: "20mm", label: "Cache (Player2)", color: '#2196f3', x: 23 + 0.393701, y: 48 - 18 - 0.393701, },
          { type: "Flag", size: "20mm", label: "Cache (Player1)", color: '#2196f3', x: 23 + 0.393701, y: 48 - 20 - 0.393701, },
          { type: "Flag", size: "20mm", color: '#2196f3', x: 12 + 0.393701, y: 48 - 23 - 0.393701, },
        ],
        "image": "/Warmahordes/scenarios/recon_mk4.jpg",
        "killbox": true
      },
      {
        "name": "Battle Lines",
        "elements": [
          { type: "Flag", size: "20mm", color: '#f44336', x: 48 - 12 - 0.393701, y: 19 + 0.393701, },
          { type: "Objective", immobile: false, size: "30mm", color: '#f44336', x: 9 + 0.59055, y: 20 + 0.59055, },
          { type: "Objective", immobile: false, size: "40mm", color: '#f44336', x: 20 + 0.787402, y: 23 + 0.787402, },
          { type: "Flag", size: "20mm", color: '#2196f3', x: 12 + 0.393701, y: 48 - 19 - 0.393701, },
          { type: "Objective", immobile: false, size: "30mm", color: '#2196f3', x: 48 - 9 - 0.59055, y: 48 - 20 - 0.59055, },
          { type: "Objective", immobile: false, size: "40mm", color: '#2196f3', x: 48 - 20 - 0.787402, y: 48 - 23 - 0.787402, },
        ],
        "image": "/Warmahordes/scenarios/battle_lines.jpg",
        "killbox": true
      },
      {
        "name": "Wolves at Our Heels",
        "elements": [
          { type: "Flag", size: "20mm", label: "Cache (Player1)", color: '#f44336', x: 12 + 0.393701, y: 20 + 0.393701, },
          { type: "Flag", size: "20mm", label: "Cache (Player2)", color: '#f44336', x: 16 + 0.393701, y: 16 + 0.393701, },
          { type: "Objective", size: "50mm", color: '#f44336', x: 19 + 0.984252, y: 23 + 0.984252, },
          { type: "Objective", immobile: false, size: "40mm", color: '#f44336', x: 48 - 8 - 0.787402, y: 15 + 0.787402, },
          { type: "Flag", size: "20mm", label: "Cache (Player1)", color: '#2196f3', x: 48 - 12 - 0.393701, y: 48 - 20 - 0.393701, },
          { type: "Flag", size: "20mm", label: "Cache (Player2)", color: '#2196f3', x: 48 - 16 - 0.393701, y: 48 - 16 - 0.393701, },
          { type: "Objective", size: "50mm", color: '#2196f3', x: 48 - 19 - 0.984252, y: 48 - 23 - 0.984252, },
          { type: "Objective", immobile: false, size: "40mm", color: '#2196f3', x: 8 + 0.787402, y: 48 - 15 - 0.787402, },
        ],
        "image": "/Warmahordes/scenarios/wolves_at_our_heels.jpg",
        "killbox": true
      },
      {
        "name": "Payload",
        "elements": [
          { type: "Objective", immobile: false, size: "50mm", color: '#f44336', x: 9 + 0.984252, y: 16 + 0.984252, },
          { type: "Objective", size: "40mm", color: '#f44336', x: 20 + 0.787402, y: 20 + 0.787402, },
          { type: "Flag", size: "20mm", color: '#f44336', x: 48 - 16 - 0.393701, y: 19 + 0.393701, },
          { type: "Objective", label: "Objective (Player2)", size: "30mm", color: '#f44336', x: 8 + 0.59055, y: 20 + 0.59055, },
          { type: "Objective", immobile: false, size: "50mm", color: '#2196f3', x: 48 - 9 - 0.984252, y: 48 - 16 - 0.984252, },
          { type: "Objective", size: "40mm", color: '#2196f3', x: 48 - 20 - 0.787402, y: 48 - 20 - 0.787402, },
          { type: "Flag", size: "20mm", color: '#2196f3', x: 16 + 0.393701, y: 48 - 19 - 0.393701, },
          { type: "Objective", label: "Objective (Player2)", size: "30mm", color: '#2196f3', x: 48 - 8 - 0.59055, y: 48 - 20 - 0.59055, },
        ],
        "image": "/Warmahordes/scenarios/payload.jpg",
        "killbox": true
      },
      {
        "name": "Two Fronts",
        "elements": [
          { type: "Objective", label: "Objective (Player1)", size: "50mm", color: '#f44336', x: 12 + 0.984252, y: 18 + 0.984252, },
          { type: "Objective", label: "Objective (Player2)", size: "50mm", color: '#f44336', x: 48 - 12 - 0.984252, y: 18 + 0.984252, },
          { type: "Objective", label: "Objective (Player1)", size: "40mm", color: '#f44336', x: 48 - 10 - 0.787402, y: 20 + 0.787402, },
          { type: "Objective", label: "Objective (Player2)", size: "40mm", color: '#f44336', x: 10 + 0.787402, y: 20 + 0.787402, },
          { type: "Objective", label: "Objective (Player2)", size: "30mm", color: '#f44336', x: 10 + 0.78740 + 11 + 0.59055 + 0.78740, y: 20 + 0.59055, },
          { type: "Objective", label: "Objective (Player2)", size: "50mm", color: '#2196f3', x: 12 + 0.984252, y: 48 - 18 - 0.984252, },
          { type: "Objective", label: "Objective (Player1)", size: "50mm", color: '#2196f3', x: 48 - 12 - 0.984252, y: 48 - 18 - 0.984252, },
          { type: "Objective", label: "Objective (Player2)", size: "40mm", color: '#2196f3', x: 48 - 10 - 0.787402, y: 48 - 20 - 0.787402, },
          { type: "Objective", label: "Objective (Player1)", size: "40mm", color: '#2196f3', x: 10 + 0.787402, y: 48 - 20 - 0.787402, },
          { type: "Objective", label: "Objective (Player2)", size: "30mm", color: '#2196f3', x: 48 - 10 - 0.78740 - 11 - 0.59055 - 0.78740, y: 48 - 20 - 0.59055, },
        ],
        "image": "/Warmahordes/scenarios/two_fronts.jpg",
        "killbox": true
      },
      {
        "name": "Invasion",
        "elements": [
          { type: "Flag", size: "20mm", color: '#f44336', x: 48 - 14 - 0.393701, y: 49 - 14 - 0.393701, },
          { type: "Objective", immobile: false, label: "Objective", size: "50mm", color: '#f44336', x: 48 - 20 - 0.787402, y: 20 + 0.787402, },
          { type: "Objective", immobile: false, label: "Objective", size: "40mm", color: '#f44336', x: 5 + 0.787402, y: 22 + 0.787402, },
          { type: "Flag", size: "20mm", color: '#2196f3', x: 14 + 0.393701, y: 14 + 0.393701, },
          { type: "Objective", immobile: false, label: "Objective", size: "50mm", color: '#2196f3', x: 20 + 0.787402, y: 48 - 20 - 0.787402, },
          { type: "Objective", immobile: false, label: "Objective", size: "40mm", color: '#2196f3', x: 48 - 5 - 0.787402, y: 48 - 22 - 0.787402, },
        ],
        "image": "/Warmahordes/scenarios/invasion.jpg",
        "killbox": true
      },
    ]
  },
  {
    "section": "Steamroller 2023",
    "description": <Fragment>Steamroller is the official organized play format. Get <a href="https://home.privateerpress.com/wp-content/uploads/2022/11/Steamroller-Rules-2023_FINAL.pdf" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>the Steamroller 2023 packet</a> for rules.</Fragment>,
    "scenarios": [
      {
        "name": "King of the Hill",
        "elements": [
          { type: "Rect", x: 9, y: 24, height: 6, width: 12, },
          { type: "Rect", x: 39, y: 24, height: 6, width: 12, },
          { type: "Circle", x: 24, y: 24, },
          { type: "Flag", x: 24, y: 24, },
          { type: "Objective", x: 15, y: 21, },
          { type: "Objective", x: 33, y: 27, },
        ],
        "image": "/Warmahordes/scenarios/king_of_the_hill2021.jpg",
        "killbox": true
      },
      {
        "name": "Bunkers",
        "elements": [
          { type: "Rect", x: 24, y: 15, height: 6, width: 12, },
          { type: "Rect", x: 24, y: 33, height: 6, width: 12, },
          { type: "Flag", x: 12, y: 24, },
          { type: "Flag", x: 36, y: 24, },
          { type: "Objective", x: 24, y: 15, },
          { type: "Objective", x: 24, y: 33, },
        ],
        "image": "/Warmahordes/scenarios/bunkers2021.jpg",
        "killbox": true
      },
      {
        "name": "Spread the Net",
        "elements": [
          { type: "Rect", x: 12, y: 19, height: 6, width: 12, },
          { type: "Rect", x: 36, y: 29, height: 6, width: 12, },
          { type: "Circle", x: 24, y: 24, },
          { type: "Flag", x: 36, y: 19, },
          { type: "Flag", x: 12, y: 29, },
          { type: "Objective", x: 36, y: 29, },
          { type: "Objective", x: 12, y: 19, },
        ],
        "image": "/Warmahordes/scenarios/spread_the_net2021.jpg",
        "killbox": true
      },
      {
        "name": "Invasion",
        "elements": [
          { type: "Rect", x: 24, y: 24, facing: 90, height: 6, width: 12 },
          { type: "Circle", x: 12, y: 24, },
          { type: "Circle", x: 36, y: 24, },
          { type: "Objective", x: 24, y: 18, },
          { type: "Objective", x: 24, y: 30, },
        ],
        "image": "/Warmahordes/scenarios/invasion.jpg",
        "killbox": true
      },
      {
        "name": "Split Decision",
        "elements": [
          { type: "Circle", x: 16, y: 24, },
          { type: "Flag", x: 16, y: 24, },
          { type: "Flag", x: 30, y: 16, },
          { type: "Flag", x: 30, y: 32, },
          { type: "Objective", x: 34, y: 16, },
          { type: "Objective", x: 34, y: 32, },
        ],
        "image": "/Warmahordes/scenarios/split_decision.jpg",
        "killbox": true
      },
      {
        "name": "Recon II",
        "elements": [
          { type: "Rect", x: 15, y: 24, facing: 90, height: 6, width: 12 },
          { type: "Rect", x: 33, y: 24, facing: 90, height: 6, width: 12 },
          { type: "Objective", x: 24, y: 18, },
          { type: "Objective", x: 24, y: 30, },
          { type: "Flag", x: 18, y: 18, },
          { type: "Flag", x: 30, y: 30, },
        ],
        "image": "/Warmahordes/scenarios/recon2.jpg",
        "killbox": true
      },
    ],
  },
  {
    "section": "TrollScrum Test Scenarios",
    "description": <Fragment>Rules: first to win by 5. You can score the terrain elements on scenario 7 with a unit if they are all within 4 of it. Objectives are basic DEF 5, ARM 18, 15 boxes. Send feedback to TrollScrum on the <a style={{ textDecoration: 'underline'}} href="https://discord.gg/eP4SphVQ" rel="noreferrer noopeneer" target="_blank">TrollScrum Discord</a>.</Fragment>,
    "scenarios": [
      {
        "name": "TrollScrum 1",
        "elements": [
          { type: "Rect", x: 24, y: 24, height: 6, width: 12, },
          { type: "Flag", x: 10, y: 24, },
          { type: "Flag", x: 48 - 10, y: 24, },
          { type: "Flag", x: 24, y: 15, },
          { type: "Flag", x: 24, y: 48 - 15, },
        ],
        "image": "/Warmahordes/scenarios/trollscrum_scenario1.jpg",
        "killbox": true
      },
      {
        "name": "TrollScrum 2",
        "elements": [
          { type: "Rect", x: 24, y: 24, height: 12, width: 6, },
          { type: "Circle", x: 9, y: 48 - 18, },
          { type: "Circle", x: 48 - 9, y: 18, },
          { type: "Objective", x: 24, y: 24 - 6, },
          { type: "Objective", x: 24, y: 24 + 6, },
        ],
        "image": "/Warmahordes/scenarios/trollscrum_scenario2.jpg",
        "killbox": true
      },
      {
        "name": "TrollScrum 3",
        "elements": [
          { type: "Rect", x: 24, y: 24, height: 6, width: 12, },
          { type: "Circle", x: 12, y: 48 - 18, },
          { type: "Circle", x: 48 - 12, y: 18, },
          { type: "Objective", x: 12, y: 48 - 18, },
          { type: "Objective", x: 48 - 12, y: 18, },
        ],
        "image": "/Warmahordes/scenarios/trollscrum_scenario3.jpg",
        "killbox": true
      },
      {
        "name": "TrollScrum 4",
        "elements": [
          { type: "Rect", x: 9, y: 24, height: 6, width: 12, },
          { type: "Rect", x: 48 - 9, y: 24, height: 6, width: 12, },
          { type: "Objective", x: 15, y: 24 - 3, },
          { type: "Objective", x: 48 - 15, y: 24 + 3, },
          { type: "Flag", x: 24, y: 16, },
          { type: "Flag", x: 24, y: 48 - 16, },
        ],
        "image": "/Warmahordes/scenarios/trollscrum_scenario4.jpg",
        "killbox": true
      },
      {
        "name": "TrollScrum 5",
        "elements": [
          { type: "Rect", x: 6, y: 24, height: 6, width: 12, },
          { type: "Rect", x: 48 - 6, y: 24, height: 6, width: 12, },
          { type: "Objective", x: 12, y: 24 - 3, },
          { type: "Objective", x: 48 - 12, y: 24 + 3, },
          { type: "Circle", x: 24, y: 16, },
          { type: "Circle", x: 24, y: 48 - 16, },
        ],
        "image": "/Warmahordes/scenarios/trollscrum_scenario5.jpg",
        "killbox": true
      },
      {
        "name": "TrollScrum 6",
        "elements": [
          { type: "Rect", x: 24, y: 24, height: 6, width: 12, },
          { type: "Rect", x: 24, y: 24, height: 12, width: 6, },
          { type: "Flag", x: 15, y: 16, },
          { type: "Flag", x: 48 - 15, y: 48 - 16, },
        ],
        "image": "/Warmahordes/scenarios/trollscrum_scenario6.jpg",
        "killbox": true
      },
      {
        "name": "TrollScrum 7",
        "elements": [
          { type: "Rect", x: 16, y: 18, height: 6, width: 12, },
          { type: "Rect", x: 48 - 16, y: 48 - 18, height: 6, width: 12, },
          { type: "Circle", x: 24, y: 24, },
          { type: "Circle", x: 24, y: 24, radius: 5, imageUrl: "/terrain/structure2.png" },
          { type: "Image", x: 48 - 14, y: 16, height: 3.05, width: 5.04, imageUrl: "/terrain/trench1.png" },
          { type: "Image", x: 14, y: 48 - 16, height: 3.05, width: 5.04, imageUrl: "/terrain/trench1.png" },
        ],
        "image": "/Warmahordes/scenarios/trollscrum_scenario7.jpg",
        "killbox": true
      },
    ],
  },
  {
    "section": "Steamroller 2021",
    "description": <Fragment>Steamroller is the official organized play format. Get <a href="https://home.privateerpress.com/wp-content/uploads/2021/06/Steamroller-Rules-2021_v4.pdf" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>the Steamroller packet</a> for rules.</Fragment>,
    "scenarios": [
      {
        "name": "King of the Hill",
        "elements": [
          { type: "Rect", x: 9, y: 24, height: 6, width: 12, },
          { type: "Rect", x: 39, y: 24, height: 6, width: 12, },
          { type: "Circle", x: 24, y: 24, },
          { type: "Flag", x: 24, y: 24, },
          { type: "Objective", x: 15, y: 21, },
          { type: "Objective", x: 33, y: 27, },
        ],
        "image": "/Warmahordes/scenarios/king_of_the_hill2021.jpg",
        "killbox": true
      },
      {
        "name": "Bunkers",
        "elements": [
          { type: "Rect", x: 24, y: 15, height: 6, width: 12, },
          { type: "Rect", x: 24, y: 33, height: 6, width: 12, },
          { type: "Flag", x: 12, y: 24, },
          { type: "Flag", x: 36, y: 24, },
          { type: "Objective", x: 24, y: 15, },
          { type: "Objective", x: 24, y: 33, },
        ],
        "image": "/Warmahordes/scenarios/bunkers2021.jpg",
        "killbox": true
      },
      {
        "name": "Spread the Net",
        "elements": [
          { type: "Rect", x: 12, y: 19, height: 6, width: 12, },
          { type: "Rect", x: 36, y: 29, height: 6, width: 12, },
          { type: "Circle", x: 24, y: 24, },
          { type: "Flag", x: 36, y: 19, },
          { type: "Flag", x: 12, y: 29, },
          { type: "Objective", x: 36, y: 29, },
          { type: "Objective", x: 12, y: 19, },
        ],
        "image": "/Warmahordes/scenarios/spread_the_net2021.jpg",
        "killbox": true
      },
      {
        "name": "Invasion",
        "elements": [
          { type: "Rect", x: 24, y: 24, facing: 90, height: 6, width: 12 },
          { type: "Circle", x: 12, y: 24, },
          { type: "Circle", x: 36, y: 24, },
          { type: "Objective", x: 24, y: 18, },
          { type: "Objective", x: 24, y: 30, },
        ],
        "image": "/Warmahordes/scenarios/invasion.jpg",
        "killbox": true
      },
      {
        "name": "Split Decision",
        "elements": [
          { type: "Circle", x: 16, y: 24, },
          { type: "Flag", x: 16, y: 24, },
          { type: "Flag", x: 30, y: 16, },
          { type: "Flag", x: 30, y: 32, },
          { type: "Objective", x: 34, y: 16, },
          { type: "Objective", x: 34, y: 32, },
        ],
        "image": "/Warmahordes/scenarios/split_decision.jpg",
        "killbox": true
      },
      {
        "name": "Recon II",
        "elements": [
          { type: "Rect", x: 15, y: 24, facing: 90, height: 6, width: 12 },
          { type: "Rect", x: 33, y: 24, facing: 90, height: 6, width: 12 },
          { type: "Objective", x: 24, y: 18, },
          { type: "Objective", x: 24, y: 30, },
          { type: "Flag", x: 18, y: 18, },
          { type: "Flag", x: 30, y: 30, },
        ],
        "image": "/Warmahordes/scenarios/recon2.jpg",
        "killbox": true
      },
    ],
  },
  {
    "section": "Brawlmachine 1.2",
    "description": <Fragment>Brawlmachine is a 25 point format developed by <a href="https://www.loswarmachine.com/brawlmachine/2022/1/6/brawlmachine-12" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>Line of Sight</a>. Visit <a href="https://www.facebook.com/groups/641956019753567" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>the Brawlmachine Facebook Group</a> for rules.</Fragment>,
    "scenarios": [
      {
        "name": "Binary",
        "elements": [
          { type: "Circle", x: 31, y: 21 },
          { type: "Circle", x: 17, y: 27, },
          { type: "Flag", x: 17, y: 17, },
          { type: "Flag", x: 31, y: 31, },
        ],
        "image": "/Warmahordes/scenarios/binary.jpg",
        "killbox": true
      },
      {
        "name": "Ignition 2",
        "elements": [
          { type: "Flag", x: 12, y: 24 },
          { type: "Flag", x: 34, y: 24, },
          { type: "Rect", x: 12, y: 24, height: 12, width: 6, },
          { type: "Circle", x: 24, y: 24, },
        ],
        "image": "/Warmahordes/scenarios/ignition2.jpg",
        "killbox": true
      },
      {
        "name": "Orbits",
        "elements": [
          { type: "Rect", x: 13, y: 24, height: 6, width: 12, },
          { type: "Circle", x: 48 - 15, y: 24, },
          { type: "Flag", x: 24, y: 16, },
          { type: "Flag", x: 24, y: 32, },
        ],
        "image": "/Warmahordes/scenarios/orbits.jpg",
        "killbox": true
      },
      {
        "name": "Singularity 2",
        "elements": [
          { type: "Circle", x: 24, y: 24 },
          { type: "Rect", x: 24, y: 24, height: 12, width: 6, },
          { type: "Flag", x: 12, y: 19, },
          { type: "Flag", x: 36, y: 48 - 19, },
          { type: "Objective", x: 24, y: 18, },
          { type: "Objective", x: 24, y: 30, },
        ],
        "image": "/Warmahordes/scenarios/singularity2.jpg",
        "killbox": true
      },
      {
        "name": "Syzygy",
        "elements": [
          { type: "Circle", x: 24, y: 24 },
          { type: "Flag", x: 16, y: 16, },
          { type: "Flag", x: 32, y: 32, },
          { type: "Objective", x: 18, y: 18, },
          { type: "Objective", x: 30, y: 30, },
        ],
        "image": "/Warmahordes/scenarios/syzygy.jpg",
        "killbox": true
      },
      {
        "name": "Asteroids 2",
        "elements": [
          { type: "Rect", x: 24, y: 24, height: 12, width: 6, },
          { type: "Flag", x: 16, y: 16, },
          { type: "Flag", x: 32, y: 32, },
          { type: "Objective", x: 30, y: 18, },
          { type: "Objective", x: 18, y: 30, },
        ],
        "image": "/Warmahordes/scenarios/asteroids2.jpg",
        "killbox": true
      },
    ]
  },
  {
    "section": "Kommandant",
    "description": <Fragment>Kommandant (4 Player Warmachine Format). See <a href="https://www.youtube.com/watch?v=IbGMjD-Qkx8" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>MrMalorian’s YouTube video for the trial rules</a></Fragment>,
    "scenarios": [
      {
        "name": "Kommandant",
        "elements": [
          { type: "Circle", x: 24, y: 24 },
          { type: "Flag", x: 16, y: 16, },
          { type: "Flag", x: 16, y: 32, },
          { type: "Flag", x: 32, y: 16, },
          { type: "Flag", x: 32, y: 32, },
          { type: "Circle", x: 0, y: 0, radius: 24, fill: 'transparent' },
          { type: "Circle", x: 0, y: 48, radius: 24, fill: 'transparent' },
          { type: "Circle", x: 48, y: 0, radius: 24, fill: 'transparent' },
          { type: "Circle", x: 48, y: 48, radius: 24, fill: 'transparent' },
          { type: "Circle", x: 0, y: 0, radius: 32, fill: 'transparent' },
          { type: "Circle", x: 0, y: 48, radius: 32, fill: 'transparent' },
          { type: "Circle", x: 48, y: 0, radius: 32, fill: 'transparent', },
          { type: "Circle", x: 48, y: 48, radius: 32, fill: 'transparent', },
        ],
        "image": "/Warmahordes/scenarios/kommandant.jpg",
      },
    ]
  },
  {
    section: "Journeyman 2017",
    scenarios: [
      {
        "name": "Trial By Fire",
        "elements": [
          { type: "Circle", x: 24, y: 24, },
        ],
        "image": "/Warmahordes/scenarios/trial_by_fire.jpg",
        "killbox": false
      },
      {
        "name": "Journey’s End",
        "elements": [
          { type: "Objective", x: 24, y: 10, },
          { type: "Objective", x: 24, y: 38, },
        ],
        "image": "/Warmahordes/scenarios/journeys_end.jpg",
        "killbox": false
      },
    ]
  },
  {
    "section": "Summer Rampage 2020",
    "scenarios": [
      {
        "name": "The Best Defense",
        "elements": [
          {type: "Objective", health: 30, x: 24, y: 20, label: "Super Weapon", size: "120mm", immobile: false },
          {type: "Objective", health: 20, x: 12, y: 20, label: "Turbine", size: "50mm", immobile: false },
          {type: "Objective", health: 20, x: 36, y: 20, label: "Turbine", size: "50mm", immobile: false },
        ],
        "image": "/Warmahordes/scenarios/summer_rampage_2020_best_defense.jpg",
        "killbox": false
      },
      {
        "name": "Runaways",
        "elements": [
          {type: "Circle", radius: 3, x: 12, y: 24, fill: 'transparent', stroke: '#000000' },
          {type: "Objective", x: 11.5, y: 24.75, label: "Refugee", size: "30mm", immobile: false },
          {type: "Objective", x: 11.5, y: 23.25, label: "Refugee", size: "30mm", immobile: false },
          {type: "Objective", x: 12.75, y: 24, label: "Refugee", size: "30mm", immobile: false },
          {type: "Circle", radius: 3, x: 36, y: 24, fill: 'transparent', stroke: '#000000' },
          {type: "Objective", x: 35.5, y: 24.75, label: "Refugee", size: "30mm", immobile: false },
          {type: "Objective", x: 35.5, y: 23.25, label: "Refugee", size: "30mm", immobile: false },
          {type: "Objective", x: 36.75, y: 24, label: "Refugee", size: "30mm", immobile: false },
          {type: "Objective", x: 24, y: 24, label: "Refugee", size: "30mm", immobile: false },
        ],
        "image": "/Warmahordes/scenarios/summer_rampage_2020_runaways.jpg",
        "killbox": false
      },
      {
        "name": "Gate Crashers",
        "elements": [],
        "image": "/Warmahordes/scenarios/summer_rampage_2020_gate_crashers.jpg",
        "killbox": false
      },
    ]
  },
  {
    "section": "Oblivion Narrative Campaign",
    "scenarios": [
      {
        "name": "A War for Peace",
        "elements": [
          {"facing":0,"height":5,"imageUrl":"/terrain/structure2.png","stroke":"#ffffff","type":"Image","label": "Watchtower","width":5,"x":24,"y":24},
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false
      },
      {
        "name": "Seeds of Hope",
        "elements": [
          {"x":21,"y":17,"facing":41,"height":6.3,"width":6.3,"imageUrl":"/terrain/forest4.png","stroke":"#ffffff","type":"Image"},
          {"x":38,"y":20,"facing":70,"height":5.1,"width":3.51,"imageUrl":"/terrain/forest1.png","stroke":"#ffffff","type":"Image"},
          {"x":31,"y":33,"facing":130,"height":7.58,"width":4.8,"imageUrl":"/terrain/forest3.png","stroke":"#ffffff","type":"Image"},
          {"x":14,"y":28,"facing":23,"height":4.71,"width":3.81,"imageUrl":"/terrain/forest2.png","stroke":"#ffffff","type":"Image"},
          { type: "Flag", x: 24, y: 24, label: "Relic", size: "30mm", immobile: false },
          { stroke: '#ffffff', type: "Line", x: 24, y: 24, width: 48, },
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false,
      },
      {
        "name": "The Sanguine Trail",
        "elements": [
          { stroke: '#ffffff', fill: '#f44336', label: 'Antagonist Deployment', type: "Rect", x: 6, y: 6, height: 12, width: 12, },
          { stroke: '#ffffff', fill: '#f44336', label: 'Antagonist Deployment', type: "Rect", x: 42, y: 6, height: 12, width: 12, },
          { stroke: '#ffffff', fill: '#2196f3', label: 'Scoring Zone', type: "Rect", x: 24, y: 2.5, height: 5, width: 48, },
          { stroke: '#ffffff', fill: '#2196f3', label: 'Protagonist Deployment', type: "Rect", x: 24, y: 44.5, height: 7, width: 48, },
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false,
      },
      {
        "name": "Toward the Light",
        "elements": [
          { type: "Objective", x: 24, y: 18, label: "Wounded Archon", health: 0 },
          { stroke: '#ffffff', type: "Circle", label: "Antagonist Deployment", x: 0, y: 0, fill: '#f44336', width: 24 },
          { stroke: '#ffffff', type: "Circle", label: "Antagonist Deployment", x: 48, y: 0, fill: '#f44336', width: 24 },
          { stroke: '#ffffff', fill: '#2196f3', label: 'Protagonist Deployment', type: "Rect", x: 24, y: 43, height: 10, width: 48, },
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false,
      },
      {
        "name": "Left Behind",
        "elements": [
          { type: "Flag", x: 18, y: 12, label: "Morrowan Dead", size: "30mm" },
          { type: "Flag", x: 30, y: 12, label: "Morrowan Dead", size: "30mm" },
          { type: "Flag", x: 12, y: 24, label: "Morrowan Dead", size: "30mm" },
          { type: "Flag", x: 18, y: 24, label: "Morrowan Dead", size: "30mm" },
          { type: "Flag", x: 30, y: 24, label: "Morrowan Dead", size: "30mm" },
          { type: "Flag", x: 36, y: 24, label: "Morrowan Dead", size: "30mm" },
          { type: "Flag", x: 18, y: 36, label: "Morrowan Dead", size: "30mm" },
          { type: "Flag", x: 30, y: 36, label: "Morrowan Dead", size: "30mm" },
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false,
      },
      {
        "name": "Fueling the Fire",
        "elements": [
          { type: "Objective", x: 24, y: 12, label: "Fuel Canister", health: 0 },
          { type: "Objective", x: 24, y: 36, label: "Fuel Canister", health: 0 },
          { type: "Objective", x: 12, y: 24, label: "Fuel Canister", health: 0 },
          { type: "Objective", x: 36, y: 24, label: "Fuel Canister", health: 0 },
          { type: "Objective", x: 24, y: 36, label: "Fuel Canister", health: 0 },
          { type: "Rect", width: 5, height: 5, fill: '#9e9e9e', x: 24, y: 24, label: "Depot" },
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false,
      },
      {
        "name": "Reckless Assault",
        "elements": [
          {"x":16,"y":36,"facing":0,"height":0.75,"width":4,"imageUrl":"/terrain/wall1.png","stroke":"#ffffff","type":"Image"},
          {"x":21,"y":36,"facing":0,"height":0.75,"width":4,"imageUrl":"/terrain/wall1.png","stroke":"#ffffff","type":"Image"},
          {"x":27,"y":36,"facing":0,"height":0.75,"width":4,"imageUrl":"/terrain/wall1.png","stroke":"#ffffff","type":"Image"},
          {"x":32,"y":36,"facing":0,"height":0.75,"width":4,"imageUrl":"/terrain/wall1.png","stroke":"#ffffff","type":"Image"},
          { type: "Objective", x: 28, y: 39, immobile: false, label: "Heavy Cannon", health: 0 },
          { type: "Objective", x: 20, y: 39, immobile: false, label: "Heavy Cannon", health: 0 },
          { type: "Objective", x: 24, y: 39, immobile: false, size: "120mm", imageUrl: "/terrain/structure2.png", health: 40 },
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false,
      },
      {
        "name": "Burial at Sea",
        "elements": [
          { stroke: '#ffffff', fill: '#f44336', label: 'Antagonist Deployment', type: "Rect", x: 30, y: 9, height: 18, width: 36, },
          { stroke: '#ffffff', fill: '#2196f3', label: 'Protagonist Deployment', type: "Rect", x: 30, y: 43, height: 10, width: 36, },
          { stroke: '#ffffff', fill: '#03a9f4', label: 'Body of Water', type: "Rect", x: 6, y: 24, height: 48, width: 12, },
          {"x":12,"y":20,"facing":90,"height":6.38,"width":3.79,"imageUrl":"/terrain/structure1.png","stroke":"#ffffff", "type":"Image"},
          {"x":24,"y":20,"facing":90,"height":6.38,"width":3.79,"imageUrl":"/terrain/structure1.png","stroke":"#ffffff", "type":"Image"},
          {"x":36,"y":20,"facing":90,"height":6.38,"width":3.79,"imageUrl":"/terrain/structure1.png","stroke":"#ffffff", "type":"Image"},
          {"x":12,"y":28,"facing":90,"height":5.33,"width":5.33,"imageUrl":"/terrain/structure3.png","stroke":"#ffffff", "type":"Image"},
          {"x":24,"y":28,"facing":90,"height":5.33,"width":5.33,"imageUrl":"/terrain/structure3.png","stroke":"#ffffff", "type":"Image"},
          {"x":36,"y":28,"facing":90,"height":5.33,"width":5.33,"imageUrl":"/terrain/structure3.png","stroke":"#ffffff", "type":"Image"},
          { type: "Objective", x: 22, y: 24, immobile: false, label: "Supply Cache", health: 0 },
          { type: "Objective", x: 26, y: 24, immobile: false, label: "Supply Cache", health: 0 },
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false,
      },
      {
        "name": "The End is Nigh",
        "elements": [
          { type: 'Circle', x: 24, y: 24 },
          {"x":12,"y":20,"facing":90,"height":6.38,"width":3.79,"imageUrl":"/terrain/structure1.png","stroke":"#ffffff", "type":"Image"},
          {"x":24,"y":20,"facing":90,"height":6.38,"width":3.79,"imageUrl":"/terrain/structure1.png","stroke":"#ffffff", "type":"Image"},
          {"x":36,"y":20,"facing":90,"height":6.38,"width":3.79,"imageUrl":"/terrain/structure1.png","stroke":"#ffffff", "type":"Image"},
          {"x":12,"y":28,"facing":90,"height":5.33,"width":5.33,"imageUrl":"/terrain/structure3.png","stroke":"#ffffff", "type":"Image"},
          {"x":24,"y":28,"facing":90,"height":5.33,"width":5.33,"imageUrl":"/terrain/structure3.png","stroke":"#ffffff", "type":"Image"},
          {"x":36,"y":28,"facing":90,"height":5.33,"width":5.33,"imageUrl":"/terrain/structure3.png","stroke":"#ffffff", "type":"Image"},
          { type: "Objective", x: 12, y: 20, immobile: false, label: "House Damage", health: 20, size: "30mm" },
          { type: "Objective", x: 24, y: 20, immobile: false, label: "House Damage", health: 20, size: "30mm" },
          { type: "Objective", x: 36, y: 20, immobile: false, label: "House Damage", health: 20, size: "30mm" },
          { type: "Objective", x: 12, y: 28, immobile: false, label: "House Damage", health: 20, size: "30mm" },
          { type: "Objective", x: 24, y: 28, immobile: false, label: "House Damage", health: 20, size: "30mm" },
          { type: "Objective", x: 36, y: 28, immobile: false, label: "House Damage", health: 20, size: "30mm" },
          { type: "Flag", label: 'Villager', x: 19, y: 24, immobile: false, size: "30mm" },
          { type: "Flag", label: 'Villager', x: 21, y: 24, immobile: false, size: "30mm" },
          { type: "Flag", label: 'Villager', x: 23, y: 24, immobile: false, size: "30mm" },
          { type: "Flag", label: 'Villager', x: 25, y: 24, immobile: false, size: "30mm" },
          { type: "Flag", label: 'Villager', x: 27, y: 24, immobile: false, size: "30mm" },
          { type: "Flag", label: 'Villager', x: 29, y: 24, immobile: false, size: "30mm" },
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false,
      },
      {
        "name": "Clouded Visions",
        "elements": [
          { type: 'Rect', fill: '#795548', stroke: '#ffffff', label: 'Church', x: 24, y: 24, height: 12, width: 12 },
          { type: "Flag", label: 'Morrowan Priest', x: 24, y: 24, immobile: false, size: "30mm" },
          {"x":30,"y":24,"facing":90,"height":0.75,"width":4,"imageUrl":"/terrain/wall2.png","stroke":"#ffffff","type":"Image"},
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false,
      },
      {
        "name": "The Last Ride",
        "elements": [
          { type: 'Rect', fill: '#795548', label: 'Train Tracks', x: 24, y: 24, height: 12, width: 48 },
          { type: 'Rect', label: 'Train Station', x: 24, y: 15, height: 6, width: 12 },
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false,
      },
      {
        "name": "The Gateway to Oblivion",
        "elements": [
          { type: 'Circle', fill: '#f44336', stroke: '#ffffff', label: 'Infernal Portal', x: 24, y: 24 },
          { type: "Flag", label: 'Ritualist', x: 16, y: 16, size: "30mm" },
          { type: "Flag", label: 'Ritualist', x: 32, y: 16, size: "30mm" },
          { type: "Flag", label: 'Ritualist', x: 32, y: 32, size: "30mm" },
          { type: "Flag", label: 'Ritualist', x: 16, y: 32, size: "30mm" },
        ],
        "image": "/Warmahordes/scenarios/oblivion.jpg",
        "killbox": false,
      },
    ]
  },
]

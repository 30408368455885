import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { copyGame, touchGameSort } from '../initFirebase';

export default class GameItem extends Component {
  state = {
    anchorEl: null,
    openGame: false
  }
  openGame = () => {
    touchGameSort({ userId: this.props.user.uid, gameId: this.props.game.id }).then(() => {
      this.setState({ openGame: true });
    });
  }
  handleClickMore = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  copyGame = () => {
    copyGame(this.props.game.id, this.props.user.uid).then(() => {
      window.location.reload();
    });
  };
  deleteGame = () => {
    const { game, onRemove } = this.props;
    this.setState({ anchorEl: null });
    onRemove(game);
  };
  render() {
    const { game } = this.props;
    const { anchorEl, openGame } = this.state;
    if (openGame) {
      return <Redirect to={`${game.path}`} push/>
    }
    return (
      <ListItem disablePadding>
        <ListItemButton onClick={this.openGame.bind(game)}>
          <ListItemAvatar>
            <Avatar src={game.attrs.backgroundImageUrl} />
          </ListItemAvatar>
          <ListItemText primary={`${game.attrs.player1Label} vs ${game.attrs.player2Label}`} secondary={`${game.attrs.system} • ${new Date(game.attrs.createdAt).toLocaleDateString()}`}/>
        </ListItemButton>
        <ListItemSecondaryAction>
          <IconButton aria-label="More" onClick={this.handleClickMore}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.copyGame}>Copy</MenuItem>
            <MenuItem onClick={this.deleteGame}>Delete</MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

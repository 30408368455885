export default function setup(connection) {
  // alert(cards.length)
  // connection.add('deck', {
  //   x: 18,
  //   y: 18,
  //   width: 3.45,
  //   height: 5,
  //   cards,
  // });
  // connection.add('deck', {
  //   x: 18,
  //   y: 18,
  //   width: 3.45,
  //   height: 5,
  //   cards,
  // });
}

// Dir.glob('/Users/lars/Projects/wartable/public/GuildBall/terrain/*.png').each { |f| width,height = `identify -format %wx%h "#{f}"`.split('x').map { |s| s.to_i }; puts({ label: File.basename(f).gsub(/-/, ' ').gsub('.png', '').titlecase, imageUrl: "/GuildBall/terrain/#{File.basename(f)}", width: width/42.6, height: height/42.6 }.to_json + ",") }
import React, { Fragment } from 'react';

export default [
  {
    label: 'Guild Ball',
    backgroundImageUrl: '/backgrounds/pitch.jpg',
    credit: <Fragment>This terrain set was contributed by the <a href="http://www.vassalengine.org/wiki/Module:Guild_Ball" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>Guild Ball Vassal Module</a> team.</Fragment>,
    terrain: [
      {"label":"Barrier","imageUrl":"/GuildBall/terrain/barrier-1.png","width":8,"height":8},
      {"label":"Barrier","imageUrl":"/GuildBall/terrain/barrier-2.png","width":8,"height":8},
      {"label":"Barrier","imageUrl":"/GuildBall/terrain/barrier-3.png","width":8,"height":8},
      {"label":"Barrier","imageUrl":"/GuildBall/terrain/barrier-4.png","width":8,"height":8},
      {"label":"Barrier","imageUrl":"/GuildBall/terrain/barrier-5.png","width":8,"height":8},
      {"label":"Barrier","imageUrl":"/GuildBall/terrain/barrier-6.png","width":8,"height":8},
      {"label":"Barrier Statue","imageUrl":"/GuildBall/terrain/barrier-statue1.png","width":8,"height":8},
      {"label":"Fast Ice","imageUrl":"/GuildBall/terrain/fast-ice1-grass.png","width":8,"height":8},
      {"label":"Fast Ice","imageUrl":"/GuildBall/terrain/fast-ice2-grass.png","width":8,"height":8},
      {"label":"Fast Ice","imageUrl":"/GuildBall/terrain/fast-ice3-grass.png","width":8,"height":8},
      {"label":"Fast","imageUrl":"/GuildBall/terrain/fast1-grass.png","width":8,"height":8},
      {"label":"Fast","imageUrl":"/GuildBall/terrain/fast2-grass.png","width":8,"height":8},
      {"label":"Fast","imageUrl":"/GuildBall/terrain/fast3-grass.png","width":8,"height":8},
      {"label":"Forest","imageUrl":"/GuildBall/terrain/forest1-grass.png","width":8,"height":8},
      {"label":"Forest","imageUrl":"/GuildBall/terrain/forest2-grass.png","width":8,"height":8},
      {"label":"Forest","imageUrl":"/GuildBall/terrain/forest3-grass.png","width":8,"height":8},
      {"label":"Forest","imageUrl":"/GuildBall/terrain/forest4-grass.png","width":8,"height":8},
      {"label":"Forest","imageUrl":"/GuildBall/terrain/forest5-grass.png","width":8,"height":8},
      {"label":"Forest","imageUrl":"/GuildBall/terrain/forest6-grass.png","width":4.107981220657277*1.42,"height":3.5666666667},
      {"label":"Forest","imageUrl":"/GuildBall/terrain/forest7-grass.png","width":5.0646666667,"height":5},
      {"label":"Obstacle Barrel","imageUrl":"/GuildBall/terrain/obstacle-barrel-1.png","width":8,"height":8},
      {"label":"Obstacle Barrel","imageUrl":"/GuildBall/terrain/obstacle-barrel-2.png","width":8,"height":8},
      {"label":"Obstacle Barrel","imageUrl":"/GuildBall/terrain/obstacle-barrel-3.png","width":8,"height":8},
      {"label":"Obstacle Crate","imageUrl":"/GuildBall/terrain/obstacle-crate-1.png","width":8,"height":8},
      {"label":"Obstacle Crate","imageUrl":"/GuildBall/terrain/obstacle-crate-2.png","width":8,"height":8},
      {"label":"Obstacle Crate","imageUrl":"/GuildBall/terrain/obstacle-crate-3.png","width":8,"height":8},
      {"label":"Obstacle Fish","imageUrl":"/GuildBall/terrain/obstacle-fish1.png","width":8,"height":8},
      {"label":"Obstacle Fish","imageUrl":"/GuildBall/terrain/obstacle-fish2.png","width":8,"height":8},
      {"label":"Obstacle Fish","imageUrl":"/GuildBall/terrain/obstacle-fish3.png","width":8,"height":8},
      {"label":"Obstacle Fish","imageUrl":"/GuildBall/terrain/obstacle-fish4.png","width":8,"height":8},
      {"label":"Obstacle Fish","imageUrl":"/GuildBall/terrain/obstacle-fish5.png","width":8,"height":8},
      {"label":"Obstacle Hay","imageUrl":"/GuildBall/terrain/obstacle-hay1.png","width":8,"height":8},
      {"label":"Obstacle Hay","imageUrl":"/GuildBall/terrain/obstacle-hay2.png","width":8,"height":8},
      {"label":"Oil","imageUrl":"/GuildBall/terrain/oil1.png","width":8,"height":8},
      {"label":"Oil","imageUrl":"/GuildBall/terrain/oil2.png","width":8,"height":8},
      {"label":"Rough","imageUrl":"/GuildBall/terrain/rough1-grass.png","width":8,"height":8},
      {"label":"Rough","imageUrl":"/GuildBall/terrain/rough2-grass.png","width":8,"height":8},
      {"label":"Rough","imageUrl":"/GuildBall/terrain/rough3-grass.png","width":8,"height":8},
      {"label":"Rough","imageUrl":"/GuildBall/terrain/rough4-grass.png","width":8,"height":8},
      {"label":"Rough","imageUrl":"/GuildBall/terrain/rough5-grass.png","width":4.9333333333,"height":2.9333333333},
      {"label":"Rough","imageUrl":"/GuildBall/terrain/rough6-grass.png","width":4.9333333333,"height":4.3333333333},
    ],
  },
  {
    label: 'Black Pitch',
    backgroundImageUrl: '/GuildBall/backgrounds/BlackPitch.jpg',
    terrain: []
  },
  {
    label: 'Green Pitch',
    backgroundImageUrl: '/GuildBall/backgrounds/Pitch-NewGrass.jpg',
    terrain: []
  },
  {
    label: 'Grey Pitch',
    backgroundImageUrl: '/GuildBall/backgrounds/Pitch2.jpg',
    terrain: []
  },
];

import BaseWidget from './BaseWidget';
import { fabric } from 'fabric-with-gestures';

export default class Ruler extends BaseWidget {
  objects() {
    const objects = [];
    const size = this.props.record.attrs.size;
    this.rect = new fabric.Rect({
      left: this.inchesToPixels(-0.5),
      top: this.inchesToPixels(0),
      width: this.inchesToPixels(1),
      height: this.inchesToPixels(size),
      stroke: '',
      strokeWidth: 0,
      opacity: 1,
    });
    this.rect.set('fill', new fabric.Gradient({
      type: 'linear',
      coords: {
        x1: 0,
        y1: 0,
        x2: this.inchesToPixels(1),
        y2: 0,
      },
      colorStops: [
        { offset: 0, color: 'rgb(244,67,54, 0)', },
        { offset: 0.4, color: 'rgba(244,67,54,0.0)', },
        { offset: 0.45, color: 'rgba(244,67,54,0.1)', },
        { offset: 0.49, color: 'rgba(244,67,54,0.2)', },
        { offset: 0.5, color: 'rgba(244,67,54,1)', },
        { offset: 0.51, color: 'rgba(244,67,54,0.2)', },
        { offset: 0.55, color: 'rgba(244,67,54,0.1)', },
        { offset: 0.6, color: 'rgba(244,67,54,0.0)', },
        { offset: 1, color: 'rgba(244,67,54, 0)', },
      ]
    }));
    objects.push(this.rect);
    return objects;
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    const objectAttrs = super.fabricObjectAttrs(attrs, pixelsPerInch);
    return {
      ...objectAttrs,
      centeredRotation: false,
      originX: 'center',
      originY: 'bottom',
    };
  }
  updateFabricObject() {
    this.fabricObject.remove(this.rect);
    this.fabricObject.addWithUpdate(this.rect);
    this.rect.set({ height: this.inchesToPixels(this.props.record.attrs.size) });
    super.updateFabricObject();
  }
  canRotate() {
    return true;
  }
}

export default [
  {
    "name": "Kaylex, Rogue Paragon",
    "avatar": "/Breachstorm/avatars/kaylex.jpg",
    "cards": [
      "/Breachstorm/cards/kaylex-0.jpg",
      "/Breachstorm/cards/kaylex-1.jpg"
    ],
    "resource1": 10,
    "size": "30mm",
    "resource2": 4,
    "resource2Max": 4,
    "maxMeleeRange": 1,
    "factions": ["Contractors"]
  },
  {
    "name": "Qarius, Synod Agent",
    "avatar": "/Breachstorm/avatars/qarius.jpg",
    "cards": [
      "/Breachstorm/cards/qarius-0.jpg",
      "/Breachstorm/cards/qarius-1.jpg"
    ],
    "resource1": 24,
    "size": "30mm",
    "resource2": 4,
    "resource2Max": 4,
    "resource3": 2,
    "resource3Max": 2,
    "maxMeleeRange": 1,
    "factions": ["Contractors"]
  },
  {
    "name": "Captain Johan Lukas",
    "avatar": "/Breachstorm/avatars/captain_johan_lukas.jpg",
    "cards": [
      "/Breachstorm/cards/captain_johan_lukas-0.jpg",
      "/Breachstorm/cards/captain_johan_lukas-1.jpg"
    ],
    "resource1": 26,
    "size": "30mm",
    "resource2": 4,
    "resource2Max": 4,
    "resource3": 3,
    "resource3Max": 3,
    "maxMeleeRange": 1,
    "factions": ["Homeworld Confederacy"]
  },
  {
    "name": "M-TAC Breacher",
    "avatar": "/Breachstorm/avatars/mtac_breacher.jpg",
    "cards": [
      "/Breachstorm/cards/mtac_breacher-0.jpg",
      "/Breachstorm/cards/mtac_breacher-1.jpg"
    ],
    "resource1": 26,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "maxMeleeRange": 1,
    "factions": ["Homeworld Confederacy"]
  },
  {
    "name": "M-TAC Fireteam Leader",
    "avatar": "/Breachstorm/avatars/mtac_fireteam_leader.jpg",
    "cards": [
      "/Breachstorm/cards/mtac_fireteam_leader-0.jpg",
      "/Breachstorm/cards/mtac_fireteam_leader-1.jpg"
    ],
    "resource1": 26,
    "size": "30mm",
    "resource2": 4,
    "resource2Max": 4,
    "resource3": 3,
    "resource3Max": 3,
    "maxMeleeRange": 1,
    "factions": ["Homeworld Confederacy"]
  },
  {
    "name": "M-TAC Rifleman",
    "avatar": "/Breachstorm/avatars/mtac_rifleman.jpg",
    "cards": [
      "/Breachstorm/cards/mtac_rifleman-0.jpg",
      "/Breachstorm/cards/mtac_rifleman-1.jpg"
    ],
    "resource1": 26,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "maxMeleeRange": 1,
    "factions": ["Homeworld Confederacy"]
  },
  {
    "name": "M-TAC Support Gunner",
    "avatar": "/Breachstorm/avatars/mtac_support_gunner.jpg",
    "cards": [
      "/Breachstorm/cards/mtac_support_gunner-0.jpg",
      "/Breachstorm/cards/mtac_support_gunner-1.jpg"
    ],
    "resource1": 26,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "maxMeleeRange": 1,
    "factions": ["Homeworld Confederacy"]
  },
  {
    "name": "Major Bastien Khara-kha",
    "avatar": "/Breachstorm/avatars/major_bastien_kharakha.jpg",
    "cards": [
      "/Breachstorm/cards/major_bastien_kharakha-0.jpg",
      "/Breachstorm/cards/major_bastien_kharakha-1.jpg"
    ],
    "resource1": 28,
    "size": "30mm",
    "resource2": 4,
    "resource2Max": 4,
    "resource3": 3,
    "resource3Max": 3,
    "maxMeleeRange": 1,
    "factions": ["Homeworld Confederacy"]
  },
  {
    "name": "Titan Battlesuit FSL",
    "avatar": "/Breachstorm/avatars/titan_battlesuit_fsl.jpg",
    "cards": [
      "/Breachstorm/cards/titan_battlesuit_fsl-0.jpg",
      "/Breachstorm/cards/titan_battlesuit_fsl-1.jpg"
    ],
    "resource1": 24,
    "size": "40mm",
    "resource2": 2,
    "resource2Max": 2,
    "maxMeleeRange": 1,
    "factions": ["Homeworld Confederacy"]
  },
  {
    "name": "Epulex Incubator",
    "avatar": "/Breachstorm/avatars/epulex_incubator.jpg",
    "cards": [
      "/Breachstorm/cards/epulex_incubator-0.jpg",
      "/Breachstorm/cards/epulex_incubator-1.jpg"
    ],
    "resource1": 36,
    "size": "40mm",
    "resource2": 4,
    "resource2Max": 4,
    "maxMeleeRange": 1,
    "factions": ["Volucrid Host"]
  },
  {
    "name": "Epulex Larva",
    "avatar": "/Breachstorm/avatars/epulex_larva.jpg",
    "cards": [
      "/Breachstorm/cards/epulex_larva-0.jpg",
      "/Breachstorm/cards/epulex_larva-1.jpg"
    ],
    "resource1": 15,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "factions": ["Volucrid Host"]
  },
  {
    "name": "Volgox Crawler",
    "avatar": "/Breachstorm/avatars/volgox_crawlers.jpg",
    "cards": [
      "/Breachstorm/cards/volgox_crawlers-0.jpg",
      "/Breachstorm/cards/volgox_crawlers-1.jpg"
    ],
    "resource1": 25,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "maxMeleeRange": 1,
    "factions": ["Volucrid Host"]
  },
  {
    "name": "Volgox Firefly",
    "avatar": "/Breachstorm/avatars/volgox_firefly.jpg",
    "cards": [
      "/Breachstorm/cards/volgox_firefly-0.jpg",
      "/Breachstorm/cards/volgox_firefly-1.jpg"
    ],
    "resource1": 25,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "maxMeleeRange": 1,
    "factions": ["Volucrid Host"]
  },
  {
    "name": "Volgox Howler",
    "avatar": "/Breachstorm/avatars/volgox_howler.jpg",
    "cards": [
      "/Breachstorm/cards/volgox_howler-0.jpg",
      "/Breachstorm/cards/volgox_howler-1.jpg"
    ],
    "resource1": 28,
    "size": "40mm",
    "resource2": 3,
    "resource2Max": 3,
    "maxMeleeRange": 2,
    "factions": ["Volucrid Host"]
  },
  {
    "name": "Volgox Spitter",
    "avatar": "/Breachstorm/avatars/volgox_spitter.jpg",
    "cards": [
      "/Breachstorm/cards/volgox_spitter-0.jpg",
      "/Breachstorm/cards/volgox_spitter-1.jpg"
    ],
    "resource1": 30,
    "size": "40mm",
    "resource2": 2,
    "resource2Max": 2,
    "maxMeleeRange": 1,
    "factions": ["Volucrid Host"]
  },
  {
    "name": "Pride Packleader",
    "avatar": "/Breachstorm/avatars/pride_packleader.jpg",
    "cards": [
      "/Breachstorm/cards/pride_packleader-0.jpg",
      "/Breachstorm/cards/pride_packleader-1.jpg"
    ],
    "resource1": 26,
    "size": "30mm",
    "resource2": 3,
    "resource2Max": 3,
    "resource3": 3,
    "resource3Max": 3,
    "maxMeleeRange": 1,
    "factions": ["Zhren'thrar Prides"]
  },
  {
    "name": "Packmistress Feraq",
    "avatar": "/Breachstorm/avatars/packmistress_feraq.jpg",
    "cards": [
      "/Breachstorm/cards/packmistress_feraq-0.jpg",
      "/Breachstorm/cards/packmistress_feraq-1.jpg"
    ],
    "resource1": 28,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "resource3": 2,
    "resource3Max": 3,
    "maxMeleeRange": 3,
    "factions": ["Zhren'thrar Prides"]
  },
  {
    "name": "Pride Firstclaw",
    "avatar": "/Breachstorm/avatars/pride_firstclaw.jpg",
    "cards": [
      "/Breachstorm/cards/pride_firstclaw-0.jpg",
      "/Breachstorm/cards/pride_firstclaw-1.jpg"
    ],
    "resource1": 28,
    "size": "30mm",
    "resource2": 3,
    "resource2Max": 3,
    "resource3": 2,
    "resource3Max": 2,
    "maxMeleeRange": 2,
    "factions": ["Zhren'thrar Prides"]
  },
  {
    "name": "Khash'Parah",
    "avatar": "/Breachstorm/avatars/khashparah.jpg",
    "cards": [
      "/Breachstorm/cards/khashparah-0.jpg",
      "/Breachstorm/cards/khashparah-1.jpg"
    ],
    "resource1": 36,
    "size": "40mm",
    "resource2": 3,
    "resource2Max": 3,
    "resource3": 1,
    "resource3Max": 1,
    "maxMeleeRange": 2,
    "factions": ["Zhren'thrar Prides"]
  },
  {
    "name": "Pride Packwarrior",
    "avatar": "/Breachstorm/avatars/pride_packwarrior.jpg",
    "cards": [
      "/Breachstorm/cards/pride_packwarrior-0.jpg",
      "/Breachstorm/cards/pride_packwarrior-1.jpg"
    ],
    "resource1": 22,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "maxMeleeRange": 1,
    "factions": ["Zhren'thrar Prides"]
  },
  {
    "name": "Warpack Artificier",
    "avatar": "/Breachstorm/avatars/warpack_artificier.jpg",
    "cards": [
      "/Breachstorm/cards/warpack_artificier-0.jpg",
      "/Breachstorm/cards/warpack_artificier-1.jpg"
    ],
    "resource1": 22,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "maxMeleeRange": 1,
    "factions": ["Zhren'thrar Prides"]
  },
  {
    "name": "Khariphesh Dreamspeaker",
    "avatar": "/Breachstorm/avatars/khariphesh_dreamspeaker.jpg",
    "cards": [
      "/Breachstorm/cards/khariphesh_dreamspeaker-0.jpg",
      "/Breachstorm/cards/khariphesh_dreamspeaker-1.jpg"
    ],
    "resource1": 22,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "maxMeleeRange": 2,
    "factions": ["Zhren'thrar Prides"]
  },
]
import BaseWidget from './BaseWidget';
import { fabric } from 'fabric-with-gestures';

import adjustFontSize from '../../adjustFontSize';

export default class Counter extends BaseWidget {
  objects() {
    let width = 1.9685;
    this.circle = new fabric.Circle({
      left: 0,
      top: 0,
      originY: 'center',
      originX: 'center',
      radius: this.inchesToPixels(width / 2),
      fill: '#555',
      stroke: '#555'
    });
    this.label = new fabric.Text(' ', {
      left: 0,
      top: this.inchesToPixels(-0.5),
      originY: 'center',
      originX: 'center',
      fill: '#fff',
      fontSize: this.inchesToPixels(0.5),
    });
    this.value = new fabric.Text(' ', {
      left: 0,
      top: this.inchesToPixels(0.25),
      originY: 'center',
      originX: 'center',
      fill: '#fff',
      fontSize: this.inchesToPixels(1),
      fontWeight: 'bold',
    });
    return [this.circle, this.label, this.value];
  }
  updateFabricObject() {
    let { attrs } = this.props.record;
    this.circle.set({ fill: attrs.fill, stroke: attrs.fill });
    this.label.set({ text: `${attrs.label}` });
    adjustFontSize(this.label, { width: this.inchesToPixels(1.5), height: this.inchesToPixels(0.5) });
    this.value.set({ text: `${attrs.value}` });
    return super.updateFabricObject();
  }
}

import timeElapsed from './timeElapsed';

export default function timeRemaining(player, game) {
  let timeRemaining;
  if (player === 1) {
    if (game.attrs.clockPlayer1Expired) {
      timeRemaining = 0;
    } else {
      timeRemaining = game.attrs.clockPlayer1TimeRemaining || 0;
    }
  } else {
    if (game.attrs.clockPlayer2Expired) {
      timeRemaining = 0;
    } else {
      timeRemaining = game.attrs.clockPlayer2TimeRemaining || 0;
    }
  }
  timeRemaining = timeRemaining - timeElapsed(player, game);
  timeRemaining = Math.max(timeRemaining, 0);
  return timeRemaining;
}

import React, { Component } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Dialog from '../WTDialog';
import Log from './Log';

export default class LogDialog extends Component {
  render() {
    if (!this.props.open) return null;
    return <Dialog open={this.props.open} onClose={this.props.onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Game Log</DialogTitle>
      <DialogContent>
        <Log connection={this.props.connection}/>
      </DialogContent>
      <DialogActions>
        <Button onClick={this.props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  }
}

export default {
  "id": "GuildBall",
  "banner": "/GuildBall/banner.jpg",
  "title": "Guild Ball",
  "description": "Guild Ball is a fast-paced mob football skirmish game that takes place in the Empire of the Free Cities.",
  "discord": "https://discord.gg/ucxQN2q",
  "rules": "https://www.longshanks.org/tools/",
  "gameSettings": {
    system: 'GuildBall',
    width: 36,
    height: 36,
    resource1Label: 'Health',
    resource2Label: 'Influence',
    resource3Label: '',
    name: 'Guild Ball',
    backgroundImageUrl: '/GuildBall/backgrounds/pitch.jpg',
    showArcs: false,
    player1Color: '#673ab7',
    player1Label: 'Player 1',
    player2Color: '#00bcd4',
    player2Label: 'Player 2',
    clockEnabled: false,
    clockPaused: true,
    clockActivePlayer: 1,
    clockPlayer1TimeRemaining: 60 * 60 * 1000,
    clockPlayer2TimeRemaining: 60 * 60 * 1000,
  },
};
export default [
  {
    "name": "Hozumi Daihanji",
    "cards": [
      "/Bushido/cards/hozumi_daihanji_front.jpg",
      "/Bushido/cards/hozumi_daihanji_back.jpg"
    ],
    "avatar": "/Bushido/avatars/hozumi_daihanji.jpg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": ["Minimoto Clan", "Prefecture of Ryu"]
  },
  {
    "name": "Hozumi Koga",
    "cards": [
      "/Bushido/cards/hozumi_koga_front.jpg",
      "/Bushido/cards/hozumi_koga_back.jpg"
    ],
    "avatar": "/Bushido/avatars/hozumi_koga.jpg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": ["Temple of Ro-Kan", "The Descension"]
  },
  {
    "name": "Hozumi Maha",
    "cards": [
      "/Bushido/cards/hozumi_maha_front.jpg",
      "/Bushido/cards/hozumi_maha_back.jpg"
    ],
    "avatar": "/Bushido/avatars/hozumi_maha.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": ["Cult of Yurei", "The Kinshi Temple"]
  },
  {
    "name": "Kenmeina",
    "cards": [
      "/Bushido/cards/kenmeina_front.jpg",
      "/Bushido/cards/kenmeina_back.jpg"
    ],
    "avatar": "/Bushido/avatars/kenmeina.jpg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 4,
    "factions": ["Silvermoon Syndicate", "Jung Pirates"]
  },
  {
    "name": "Haikboku",
    "cards": [
      "/Bushido/cards/kiyozumi_haiboku_2pss_front.jpeg",
      "/Bushido/cards/kiyozumi_haiboku_2pss_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/kiyozumi_haiboku.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": ["Silvermoon Syndicate", "Minimoto Clan"]
  },
  {
    "name": "Hinadori",
    "cards": [
      "/Bushido/cards/kiyozumi_hinadori_2pss_front.jpeg",
      "/Bushido/cards/kiyozumi_hinadori_2pss_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/kiyozumi_hinadori.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": ["Shiho Clan", "Prefecture of Ryu", "Temple of Ro-Kan"]
  },
  {
    "name": "Kicho",
    "cards": [
      "/Bushido/cards/kiyozumi_kicho_2pss_front.jpeg",
      "/Bushido/cards/kiyozumi_kicho_2pss_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/kiyozumi_kicho.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": ["The Descension", "Shadow Wind Clan"]
  },
  {
    "name": "Sadaka",
    "cards": [
      "/Bushido/cards/kiyozumi_sadaka_2pss_front.jpeg",
      "/Bushido/cards/kiyozumi_sadaka_2pss_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/kiyozumi_sadaka.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": ["The Descension", "Shadow Wind Clan"]
  },
  {
    "name": "Ryokeen",
    "cards": [
      "/Bushido/cards/ryokeen_2pss_front.jpeg",
      "/Bushido/cards/ryokeen_2pss_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/ryokeen.jpeg",
    "resource1": 5,
    "size": "50mm",
    "resource2": 2,
    "resource2Max": 0,
    "factions": ["The Descension", "Temple of Ro-Kan", "Cult of Yurei", "Savage Wave"]
  },
  {
    "name": "Taiyo",
    "cards": [
      "/Bushido/cards/taiyo_2pss_front.jpeg",
      "/Bushido/cards/taiyo_2pss_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/taiyo.jpeg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": ["Temple of Ro-Kan", "Shiho Clan"]
  },
  {
    "name": "Bakemono Archers",
    "cards": [
      "/Bushido/cards/bakemono_archers_sw_rs_profile_card_front.jpeg",
      "/Bushido/cards/bakemono_raiders_sw_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/bakemono_archers_sw_rs.jpeg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "factions": [
      "Savage Wave"
    ]
  },
  {
    "name": "Bakemono Beaters",
    "cards": [
      "/Bushido/cards/bakemono_beaters_sw_rs_profile_card_front.jpeg",
      "/Bushido/cards/bakemono_raiders_sw_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/bakemono_beaters_sw_rs.jpeg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "factions": [
      "Savage Wave"
    ]
  },
  {
    "name": "Bakemono Bushi",
    "cards": [
      "/Bushido/cards/bakemono_bushi_sw_rs_profile_card_front.jpeg",
      "/Bushido/cards/bakemono_raiders_sw_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/bakemono_bushi_sw_rs.jpeg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "factions": [
      "Savage Wave"
    ]
  },
  {
    "name": "Bakemono Raiders",
    "cards": [
      "/Bushido/cards/bakemono_raiders_sw_rs_profile_card_front.jpeg",
      "/Bushido/cards/bakemono_raiders_sw_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/bakemono_raiders_sw_rs.jpeg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "factions": [
      "Savage Wave"
    ]
  },
  {
    "name": "Bakemono Spearmen",
    "cards": [
      "/Bushido/cards/bakemono_spearmen_sw_rs_profile_card_front.jpeg",
      "/Bushido/cards/bakemono_raiders_sw_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/bakemono_spearmen_sw_rs.jpeg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "factions": [
      "Savage Wave"
    ]
  },
  {
    "name": "Batsu",
    "cards": [
      "/Bushido/cards/batsu_profile_card_front.jpeg",
      "/Bushido/cards/batsu_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/batsu.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "factions": [
      "The Kinshi Temple"
    ]
  },
  {
    "name": "Dafukaia",
    "cards": [
      "/Bushido/cards/dafukaia_sts_rs_special_card_front.jpeg",
      "/Bushido/cards/dafukaia_sts_rs_special_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/dafukaia_sts_rs.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": [
      "Silvermoon Syndicate"
    ]
  },
  {
    "name": "Hakushi",
    "cards": [
      "/Bushido/cards/hakushi_sts_rs_special_card_front.jpeg",
      "/Bushido/cards/hakushi_sts_rs_special_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/hakushi_sts_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 0,
    "factions": [
      "Silvermoon Syndicate"
    ]
  },
  {
    "name": "Haru",
    "cards": [
      "/Bushido/cards/haru_tor_rs_profile_card_front.jpeg",
      "/Bushido/cards/haru_tor_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/haru_tor_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 4,
    "factions": [
      "Temple of Ro-Kan"
    ]
  },
  {
    "name": "Haruki",
    "cards": [
      "/Bushido/cards/haruki_ito_rs_profile_card_front.jpeg",
      "/Bushido/cards/haruki_ito_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/haruki_ito_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Ito Clan"
    ]
  },
  {
    "name": "Hauru",
    "cards": [
      "/Bushido/cards/hauru_min_rs_profile_card_front.jpeg",
      "/Bushido/cards/hauru_min_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/hauru_min_rs.jpeg",
    "resource1": 7,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Minimoto Clan"
    ]
  },
  {
    "name": "Hirohibo",
    "cards": [
      "/Bushido/cards/hirohibo_rs_profile_card_front.jpeg",
      "/Bushido/cards/hirohibo_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/hirohibo_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "The Descension"
    ]
  },
  {
    "name": "Hiromasa",
    "cards": [
      "/Bushido/cards/hiromasa_por_rs_profile_card_front.jpeg",
      "/Bushido/cards/hiromasa_por_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/hiromasa_por_rs.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Prefecture of Ryu"
    ]
  },
  {
    "name": "Hishigata",
    "cards": [
      "/Bushido/cards/hishigata_profile_card_front.jpeg",
      "/Bushido/cards/hishigata_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/hishigata.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 0,
    "factions": [
      "The Kinshi Temple"
    ]
  },
  {
    "name": "Honbo",
    "cards": [
      "/Bushido/cards/honbo_rs_profile_card_front.jpeg",
      "/Bushido/cards/honbo_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/honbo_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "The Descension"
    ]
  },
  {
    "name": "Honoko",
    "cards": [
      "/Bushido/cards/honoko_sts_rs_special_card_front.jpeg",
      "/Bushido/cards/honoko_sts_rs_special_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/honoko_sts_rs.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Silvermoon Syndicate"
    ]
  },
  {
    "name": "Akoshi",
    "cards": [
      "/Bushido/cards/akoshi_front.jpg",
      "/Bushido/cards/akoshi_back.jpg"
    ],
    "avatar": "/Bushido/avatars/akoshi.jpg",
    "resource1": 3,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 0,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Akuba",
    "cards": [
      "/Bushido/cards/akuba_front.jpg",
      "/Bushido/cards/akuba_back.jpg"
    ],
    "avatar": "/Bushido/avatars/akuba.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 1,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Animated Warriors",
    "cards": [
      "/Bushido/cards/animated_warriors_front.jpg",
      "/Bushido/cards/animated_warriors_back.jpg"
    ],
    "avatar": "/Bushido/avatars/animated_warriors.jpg",
    "resource1": 3,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 0,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Araka",
    "cards": [
      "/Bushido/cards/araka_front.jpg",
      "/Bushido/cards/araka_back.jpg"
    ],
    "avatar": "/Bushido/avatars/araka.jpg",
    "resource1": 12,
    "size": "50mm",
    "resource2": 0,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },  
  {
    "name": "Armoured Kairai",
    "cards": [
      "/Bushido/cards/armoured_kairai_front.jpg",
      "/Bushido/cards/armoured_kairai_back.jpg"
    ],
    "avatar": "/Bushido/avatars/armoured_kairai.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Eiko",
    "cards": [
      "/Bushido/cards/eiko_front.jpg",
      "/Bushido/cards/eiko_back.jpg"
    ],
    "avatar": "/Bushido/avatars/eiko.jpg",
    "resource1": 3,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 0,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Envy",
    "cards": [
      "/Bushido/cards/envy_front.jpg",
      "/Bushido/cards/envy_back.jpg"
    ],
    "avatar": "/Bushido/avatars/envy.jpg",
    "resource1": 1,
    "size": "40mm",
    "resource2": 0,
    "resource2Max": 7,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Fuwa Kaidan",
    "cards": [
      "/Bushido/cards/fuwa_kaidan_front.jpg",
      "/Bushido/cards/fuwa_kaidan_back.jpg"
    ],
    "avatar": "/Bushido/avatars/fuwa_kaidan.jpg",
    "resource1": 1,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 4,
    "factions": [
      "Cult of Yurei"
    ]
  },  
  {
    "name": "Gendo",
    "cards": [
      "/Bushido/cards/gendo_front.jpg",
      "/Bushido/cards/gendo_back.jpg"
    ],
    "avatar": "/Bushido/avatars/gendo.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Gengo",
    "cards": [
      "/Bushido/cards/gengo_front.jpg",
      "/Bushido/cards/gengo_back.jpg"
    ],
    "avatar": "/Bushido/avatars/gengo.jpg",
    "resource1": 7,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Gok",
    "cards": [
      "/Bushido/cards/gok_front.jpg",
      "/Bushido/cards/gok_back.jpg"
    ],
    "avatar": "/Bushido/avatars/gok.jpg",
    "resource1": 9,
    "size": "50mm",
    "resource2": 2,
    "resource2Max": 7,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Gluttony",
    "cards": [
      "/Bushido/cards/gluttony_front.jpg",
      "/Bushido/cards/gluttony_back.jpg"
    ],
    "avatar": "/Bushido/avatars/gluttony.jpg",
    "resource1": 1,
    "size": "40mm",
    "resource2": 0,
    "resource2Max": 7,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Hikari",
    "cards": [
      "/Bushido/cards/hikari_front.jpg",
      "/Bushido/cards/hikari_back.jpg"
    ],
    "avatar": "/Bushido/avatars/hikari.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 0,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Ibara",
    "cards": [
      "/Bushido/cards/ibara_front.jpg",
      "/Bushido/cards/ibara_back.jpg"
    ],
    "avatar": "/Bushido/avatars/ibara.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Ikiryo",
    "cards": [
      "/Bushido/cards/ikiryo_front.jpg",
      "/Bushido/cards/ikiryo_back.jpg"
    ],
    "avatar": "/Bushido/avatars/ikiryo.jpg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 3,
    "resource2Max": 8,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Josei",
    "cards": [
      "/Bushido/cards/josei_front.jpg",
      "/Bushido/cards/josei_back.jpg"
    ],
    "avatar": "/Bushido/avatars/josei.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Kairai Farmer",
    "cards": [
      "/Bushido/cards/kairai_farmer_front.jpg",
      "/Bushido/cards/kairai_farmer_back.jpg"
    ],
    "avatar": "/Bushido/avatars/kairai_farmer.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Kairai Militia",
    "cards": [
      "/Bushido/cards/kairai_militia_front.jpg",
      "/Bushido/cards/kairai_militia_back.jpg"
    ],
    "avatar": "/Bushido/avatars/kairai_militia.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Kairai Villager",
    "cards": [
      "/Bushido/cards/kairai_villager_front.jpg",
      "/Bushido/cards/kairai_villager_back.jpg"
    ],
    "avatar": "/Bushido/avatars/kairai_villager.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Kusatta Kairai",
    "cards": [
      "/Bushido/cards/kusatta_kairai_front.jpg",
      "/Bushido/cards/kusatta_kairai_back.jpg"
    ],
    "avatar": "/Bushido/avatars/kusatta_kairai.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Large Rat Swarm",
    "cards": [
      "/Bushido/cards/large_rat_swarm_front.jpg",
      "/Bushido/cards/large_rat_swarm_back.jpg"
    ],
    "avatar": "/Bushido/avatars/large_rat_swarm.jpg",
    "resource1": 3,
    "size": "50mm",
    "resource2": 0,
    "resource2Max": 0,
    "factions": [
      "Cult of Yurei"
    ]
  },  
  {
    "name": "Marionette",
    "cards": [
      "/Bushido/cards/marionette_front.jpg",
      "/Bushido/cards/marionette_back.jpg"
    ],
    "avatar": "/Bushido/avatars/marionette.jpg",
    "resource1": 1,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 4,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Masaema Tadao",
    "cards": [
      "/Bushido/cards/masaema_tadao_front.jpg",
      "/Bushido/cards/masaema_tadao_back.jpg"
    ],
    "avatar": "/Bushido/avatars/masaema_tadao.jpg",
    "resource1": 7,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 8,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Minarai",
    "cards": [
      "/Bushido/cards/minarai_front.jpg",
      "/Bushido/cards/minarai_back.jpg"
    ],
    "avatar": "/Bushido/avatars/minarai.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Mo Ises",
    "cards": [
      "/Bushido/cards/mo_ises_front.jpg",
      "/Bushido/cards/mo_ises_back.jpg"
    ],
    "avatar": "/Bushido/avatars/mo_ises.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Mokoti",
    "cards": [
      "/Bushido/cards/mokoti_front.jpg",
      "/Bushido/cards/mokoti_back.jpg"
    ],
    "avatar": "/Bushido/avatars/mokoti.jpg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Plague Rat",
    "cards": [
      "/Bushido/cards/plague_rat_front.jpg",
      "/Bushido/cards/plague_rat_back.jpg"
    ],
    "avatar": "/Bushido/avatars/plague_rat.jpg",
    "resource1": 2,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Risen Kairai",
    "cards": [
      "/Bushido/cards/risen_kairai_front.jpg",
      "/Bushido/cards/risen_kairai_back.jpg"
    ],
    "avatar": "/Bushido/avatars/risen_kairai.jpg",
    "resource1": 2,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 2,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Risu",
    "cards": [
      "/Bushido/cards/risu_front.jpg",
      "/Bushido/cards/risu_back.jpg"
    ],
    "avatar": "/Bushido/avatars/risu.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Rokuro",
    "cards": [
      "/Bushido/cards/rokuro_front.jpg",
      "/Bushido/cards/rokuro_back.jpg"
    ],
    "avatar": "/Bushido/avatars/rokuro.jpg",
    "resource1": 5,
    "size": "40mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Senbo",
    "cards": [
      "/Bushido/cards/senbo_front.jpg",
      "/Bushido/cards/senbo_back.jpg"
    ],
    "avatar": "/Bushido/avatars/senbo.jpg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 7,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Shichiro",
    "cards": [
      "/Bushido/cards/shichiro_front.jpg",
      "/Bushido/cards/shichiro_back.jpg"
    ],
    "avatar": "/Bushido/avatars/shichiro.jpg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Shinji",
    "cards": [
      "/Bushido/cards/shinji_front.jpg",
      "/Bushido/cards/shinji_back.jpg"
    ],
    "avatar": "/Bushido/avatars/shinji.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Shojo",
    "cards": [
      "/Bushido/cards/shojo_front.jpg",
      "/Bushido/cards/shojo_back.jpg"
    ],
    "avatar": "/Bushido/avatars/shojo.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Shokuji",
    "cards": [
      "/Bushido/cards/shokuji_front.jpg",
      "/Bushido/cards/shokuji_back.jpg"
    ],
    "avatar": "/Bushido/avatars/shokuji.jpg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 7,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Sloth",
    "cards": [
      "/Bushido/cards/sloth_front.jpg",
      "/Bushido/cards/sloth_back.jpg"
    ],
    "avatar": "/Bushido/avatars/sloth.jpg",
    "resource1": 1,
    "size": "40mm",
    "resource2": 0,
    "resource2Max": 7,
    "factions": [
      "Cult of Yurei"
    ]
  },  
  {
    "name": "Small Rat Swarm",
    "cards": [
      "/Bushido/cards/small_rat_swarm_front.jpg",
      "/Bushido/cards/small_rat_swarm_back.jpg"
    ],
    "avatar": "/Bushido/avatars/small_rat_swarm.jpg",
    "resource1": 1,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 1,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Souta",
    "cards": [
      "/Bushido/cards/souta_front.jpg",
      "/Bushido/cards/souta_back.jpg"
    ],
    "avatar": "/Bushido/avatars/souta.jpg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 7,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Taka",
    "cards": [
      "/Bushido/cards/taka_front.jpg",
      "/Bushido/cards/taka_back.jpg"
    ],
    "avatar": "/Bushido/avatars/taka.jpg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 7,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "The Gaki",
    "cards": [
      "/Bushido/cards/the_gaki_front.jpg",
      "/Bushido/cards/the_gaki_back.jpg"
    ],
    "avatar": "/Bushido/avatars/the_gaki.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },  
  {
    "name": "The Harionago",
    "cards": [
      "/Bushido/cards/the_harionago_front.jpg",
      "/Bushido/cards/the_harionago_back.jpg"
    ],
    "avatar": "/Bushido/avatars/the_harionago.jpg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "The Penanggalan",
    "cards": [
      "/Bushido/cards/the_penanggalan_front.jpg",
      "/Bushido/cards/the_penanggalan_back.jpg"
    ],
    "avatar": "/Bushido/avatars/the_penanggalan.jpg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 7,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "The Wanyudo",
    "cards": [
      "/Bushido/cards/the_wanyudo_front.jpg",
      "/Bushido/cards/the_wanyudo_back.jpg"
    ],
    "avatar": "/Bushido/avatars/the_wanyudo.jpg",
    "resource1": 7,
    "size": "40mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": [
      "Cult of Yurei"
    ]
  },  
  {
    "name": "The Wraith",
    "cards": [
      "/Bushido/cards/the_wraith_front.jpg",
      "/Bushido/cards/the_wraith_back.jpg"
    ],
    "avatar": "/Bushido/avatars/the_wraith.jpg",
    "resource1": 1,
    "size": "50mm",
    "resource2": 0,
    "resource2Max": 10,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Umeka",
    "cards": [
      "/Bushido/cards/umeka_front.jpg",
      "/Bushido/cards/umeka_back.jpg"
    ],
    "avatar": "/Bushido/avatars/umeka.jpg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Wrath",
    "cards": [
      "/Bushido/cards/wrath_front.jpg",
      "/Bushido/cards/wrath_back.jpg"
    ],
    "avatar": "/Bushido/avatars/wrath.jpg",
    "resource1": 1,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 7,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Yama Uba",
    "cards": [
      "/Bushido/cards/yama_uba_front.jpg",
      "/Bushido/cards/yama_uba_back.jpg"
    ],
    "avatar": "/Bushido/avatars/yama_uba.jpg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Yugio",
    "cards": [
      "/Bushido/cards/yugio_front.jpg",
      "/Bushido/cards/yugio_back.jpg"
    ],
    "avatar": "/Bushido/avatars/yugio.jpg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Ig",
    "cards": [
      "/Bushido/cards/ig_sw_rs_profile_card_front.jpeg",
      "/Bushido/cards/ig_sw_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/ig_sw_rs.jpeg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "factions": [
      "Savage Wave"
    ]
  },
  {
    "name": "Ima",
    "cards": [
      "/Bushido/cards/ima_por_rs_profile_card_front.jpeg",
      "/Bushido/cards/ima_por_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/ima_por_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Prefecture of Ryu"
    ]
  },
  {
    "name": "Isas",
    "cards": [
      "/Bushido/cards/isas_ito_rs_profile_card_front.jpeg",
      "/Bushido/cards/isas_ito_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/isas_ito_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Ito Clan"
    ]
  },
  {
    "name": "Ishi",
    "cards": [
      "/Bushido/cards/ishi_ito_rs_profile_card_front.jpeg",
      "/Bushido/cards/ishi_ito_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/ishi_ito_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Ito Clan"
    ]
  },
  {
    "name": "Ito Kinu",
    "cards": [
      "/Bushido/cards/ito_kinu_ito_rs_profile_card_front.jpeg",
      "/Bushido/cards/ito_kinu_ito_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/ito_kinu_ito_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": [
      "Ito Clan"
    ]
  },
  {
    "name": "Jori",
    "cards": [
      "/Bushido/cards/jori_rs_profile_card_front.jpeg",
      "/Bushido/cards/jori_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/jori_rs.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Silvermoon Syndicate"
    ]
  },
  {
    "name": "Jung Tora",
    "cards": [
      "/Bushido/cards/jung_tora_rs_profile_card_front.jpeg",
      "/Bushido/cards/jung_tora_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/jung_tora_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Jung Pirates"
    ]
  },
  {
    "name": "Kazuki",
    "cards": [
      "/Bushido/cards/kazuki_profile_card_front.jpeg",
      "/Bushido/cards/kazuki_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/kazuki.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Shiho Clan"
    ]
  },
  {
    "name": "Kira",
    "cards": [
      "/Bushido/cards/kira_tor_rs_profile_card_front.jpeg",
      "/Bushido/cards/kira_tor_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/kira_tor_rs.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Temple of Ro-Kan"
    ]
  },
  {
    "name": "Kodai",
    "cards": [
      "/Bushido/cards/kodai_front.jpeg",
      "/Bushido/cards/kodai_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/kodai.jpeg",
    "resource1": 3,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 0,
    "factions": [
      "The Kinshi Temple"
    ]
  },
  {
    "name": "Ku",
    "cards": [
      "/Bushido/cards/ku_profile_card_front.jpeg",
      "/Bushido/cards/ku_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/ku.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "factions": [
      "The Kinshi Temple"
    ]
  },
  {
    "name": "Kyou",
    "cards": [
      "/Bushido/cards/kyou_ito_rs_profile_card_front.jpeg",
      "/Bushido/cards/kyou_ito_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/kyou_ito_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Ito Clan"
    ]
  },
  {
    "name": "Maru",
    "cards": [
      "/Bushido/cards/maru_profile_card_front.jpeg",
      "/Bushido/cards/maru_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/maru.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "factions": [
      "The Kinshi Temple"
    ]
  },
  {
    "name": "Masaema Aya",
    "cards": [
      "/Bushido/cards/masaema_aya_min_rs_profile_card_front.jpeg",
      "/Bushido/cards/masaema_aya_min_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/masaema_aya_min_rs.jpeg",
    "resource1": 7,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Minimoto Clan"
    ]
  },
  {
    "name": "Masaema Yoshinobu",
    "cards": [
      "/Bushido/cards/masaema_yoshinobu_min_rs_profile_card_front.jpeg",
      "/Bushido/cards/masaema_yoshinobu_min_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/masaema_yoshinobu_min_rs.jpeg",
    "resource1": 7,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Minimoto Clan"
    ]
  },
  {
    "name": "Master Shi",
    "cards": [
      "/Bushido/cards/master_shi_front.jpeg",
      "/Bushido/cards/master_shi_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/master_shi.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 3,
    "resource2Max": 3,
    "factions": [
      "The Kinshi Temple"
    ]
  },
  {
    "name": "Minimoto Yuji",
    "cards": [
      "/Bushido/cards/minimoto_yuji_min_rs_profile_card_front.jpeg",
      "/Bushido/cards/minimoto_yuji_min_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/minimoto_yuji_min_rs.jpeg",
    "resource1": 7,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Minimoto Clan"
    ]
  },
  {
    "name": "Miyakomo Asami",
    "cards": [
      "/Bushido/cards/miyakomo_asami_jun_rs_profile_card_front.jpeg",
      "/Bushido/cards/miyakomo_asami_jun_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/miyakomo_asami_jun_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": [
      "Jung Pirates"
    ]
  },
  {
    "name": "Nagashikaku",
    "cards": [
      "/Bushido/cards/nagashikaku_profile_card_front.jpeg",
      "/Bushido/cards/nagashikaku_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/nagashikaku.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 0,
    "resource2Max": 0,
    "factions": [
      "The Kinshi Temple"
    ]
  },
  {
    "name": "Narahobo",
    "cards": [
      "/Bushido/cards/narahobo_rs_profile_card_front.jpeg",
      "/Bushido/cards/narahobo_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/narahobo_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": [
      "The Descension"
    ]
  },
  {
    "name": "Old Zo",
    "cards": [
      "/Bushido/cards/old_zo_sts_rs_special_card_front.jpeg",
      "/Bushido/cards/old_zo_sts_rs_special_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/old_zo_sts_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 10,
    "factions": [
      "Silvermoon Syndicate"
    ]
  },
  {
    "name": "Raiko",
    "cards": [
      "/Bushido/cards/raiko_min_rs_profile_card_front.jpeg",
      "/Bushido/cards/raiko_min_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/raiko_min_rs.jpeg",
    "resource1": 7,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Minimoto Clan"
    ]
  },
  {
    "name": "Risu",
    "cards": [
      "/Bushido/cards/risu_rs_profile_card_front.jpeg",
      "/Bushido/cards/risu_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/risu_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Ryota",
    "cards": [
      "/Bushido/cards/ryota_jun_rs_profile_card_front.jpeg",
      "/Bushido/cards/ryota_jun_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/ryota_jun_rs.jpeg",
    "resource1": 7,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Jung Pirates"
    ]
  },
  {
    "name": "Sangaku",
    "cards": [
      "/Bushido/cards/sangaku_front.jpeg",
      "/Bushido/cards/sangaku_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/sangaku.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "factions": [
      "The Kinshi Temple"
    ]
  },
  {
    "name": "Saruka",
    "cards": [
      "/Bushido/cards/saruka_tor_rs_profile_card_front.jpeg",
      "/Bushido/cards/saruka_tor_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/saruka_tor_rs.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Temple of Ro-Kan"
    ]
  },
  {
    "name": "Seibo",
    "cards": [
      "/Bushido/cards/seibo_rs_profile_card_front.jpeg",
      "/Bushido/cards/seibo_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/seibo_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "The Descension"
    ]
  },
  {
    "name": "Shiho Sayaka",
    "cards": [
      "/Bushido/cards/shiho_sayaka_profile_card_front.jpeg",
      "/Bushido/cards/shiho_sayaka_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/shiho_sayaka.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Shiho Clan"
    ]
  },
  {
    "name": "Shiho Takuya",
    "cards": [
      "/Bushido/cards/shiho_takuya_profile_card_front.jpeg",
      "/Bushido/cards/shiho_takuya_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/shiho_takuya.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Shiho Clan"
    ]
  },
  {
    "name": "Shiho Wimpie",
    "cards": [
      "/Bushido/cards/shiho_wimpie_profile_card_front.jpeg",
      "/Bushido/cards/shiho_wimpie_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/shiho_wimpie.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Shiho Clan"
    ]
  },
  {
    "name": "Shiho Yusuke",
    "cards": [
      "/Bushido/cards/shiho_yusuke_profile_card_front.jpeg",
      "/Bushido/cards/shiho_yusuke_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/shiho_yusuke.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Shiho Clan"
    ]
  },
  {
    "name": "Shikaku",
    "cards": [
      "/Bushido/cards/shikaku_profile_card_front.jpeg",
      "/Bushido/cards/shikaku_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/shikaku.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 2,
    "factions": [
      "The Kinshi Temple"
    ]
  },
  {
    "name": "Sho",
    "cards": [
      "/Bushido/cards/sho_jun_rs_profile_card_front.jpeg",
      "/Bushido/cards/sho_jun_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/sho_jun_rs.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Jung Pirates"
    ]
  },
  {
    "name": "Shonosuke",
    "cards": [
      "/Bushido/cards/shonosuke_rs_profile_card_front.jpeg",
      "/Bushido/cards/shonosuke_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/shonosuke_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "Silvermoon Syndicate"
    ]
  },
  {
    "name": "Shubo",
    "cards": [
      "/Bushido/cards/shubo_rs_profile_card_front.jpeg",
      "/Bushido/cards/shubo_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/shubo_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 1,
    "resource2Max": 6,
    "factions": [
      "The Descension"
    ]
  },
  {
    "name": "Takashi Akira",
    "cards": [
      "/Bushido/cards/takashi_akira_por_rs_profile_card_front.jpeg",
      "/Bushido/cards/takashi_akira_por_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/takashi_akira_por_rs.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": [
      "Prefecture of Ryu"
    ]
  },
  {
    "name": "Takashi Mika",
    "cards": [
      "/Bushido/cards/takashi_mika_por_rs_profile_card_front.jpeg",
      "/Bushido/cards/takashi_mika_por_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/takashi_mika_por_rs.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Prefecture of Ryu"
    ]
  },
  {
    "name": "Takashi Tetsuya",
    "cards": [
      "/Bushido/cards/takashi_tetsuya_por_rs_profile_card_front.jpeg",
      "/Bushido/cards/takashi_tetsuya_por_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/takashi_tetsuya_por_rs.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Prefecture of Ryu"
    ]
  },
  {
    "name": "Ume",
    "cards": [
      "/Bushido/cards/ume_tor_rs_profile_card_front.jpeg",
      "/Bushido/cards/ume_tor_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/ume_tor_rs.jpeg",
    "resource1": 5,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 4,
    "factions": [
      "Temple of Ro-Kan"
    ]
  },
  {
    "name": "Wei-Shu",
    "cards": [
      "/Bushido/cards/wei-shu_sw_rs_profile_card_front.jpeg",
      "/Bushido/cards/wei-shu_sw_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/wei-shu_sw_rs.jpeg",
    "resource1": 4,
    "size": "30mm",
    "resource2": 3,
    "resource2Max": 3,
    "factions": [
      "Savage Wave"
    ]
  },
  {
    "name": "Yama Uba",
    "cards": [
      "/Bushido/cards/yama_uba_coy_rs_profile_card_front.jpeg",
      "/Bushido/cards/yama_uba_coy_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/yama_uba_coy_rs.jpeg",
    "resource1": 6,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 8,
    "factions": [
      "Cult of Yurei"
    ]
  },
  {
    "name": "Yim",
    "cards": [
      "/Bushido/cards/yim_tor_rs_profile_card_front.jpeg",
      "/Bushido/cards/yim_tor_rs_profile_card_back.jpeg"
    ],
    "avatar": "/Bushido/avatars/yim_tor_rs.jpeg",
    "resource1": 7,
    "size": "30mm",
    "resource2": 2,
    "resource2Max": 6,
    "factions": [
      "Temple of Ro-Kan"
    ]
  }
]
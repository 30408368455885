export default [
  {
    label: 'Caught Out Of Position',
    imageUrl: '/GuildBall/game-plans/caught_out_of_position.jpg',
    initiative: 7,
    influence: -1,
  },
  {
    label: 'Risky Play',
    imageUrl: '/GuildBall/game-plans/risky_play.jpg',
    initiative: 7,
    influence: 0,
  },
  {
    label: 'Play It To The Crowd',
    imageUrl: '/GuildBall/game-plans/play_it_to_the_crowd.jpg',
    initiative: 6,
    influence: 0,
  },
  {
    label: 'Pick Up the Pace',
    imageUrl: '/GuildBall/game-plans/pick_up_the_pace.jpg',
    initiative: 6,
    influence: 0,
  },
  {
    label: 'Boot Sponsorship',
    imageUrl: '/GuildBall/game-plans/boot_sponsorship.jpg',
    initiative: 5,
    influence: 0,
  },
  {
    label: 'Mud Slide',
    imageUrl: '/GuildBall/game-plans/mud_slide.jpg',
    initiative: 5,
    influence: 0,
  },
  {
    label: 'Punching Bag',
    imageUrl: '/GuildBall/game-plans/punching_bag.jpg',
    initiative: 4,
    influence: -1,
  },
  {
    label: 'Form a Wall',
    imageUrl: '/GuildBall/game-plans/form_a_wall.jpg',
    initiative: 4,
    influence: 0,
  },
  {
    label: 'Sidestep',
    imageUrl: '/GuildBall/game-plans/sidestep.jpg',
    initiative: 4,
    influence: 0,
  },
  {
    label: 'Pass Out The Orange Slices',
    imageUrl: '/GuildBall/game-plans/pass_out_the_orange_slices.jpg',
    initiative: 4,
    influence: 0,
  },
  {
    label: 'Hit Em Where It Hurts',
    imageUrl: '/GuildBall/game-plans/hit_em_where_it_hurts.jpg',
    initiative: 4,
    influence: 0,
  },
  {
    label: 'Lock Them Down',
    imageUrl: '/GuildBall/game-plans/lock_them_down.jpg',
    initiative: 3,
    influence: 0,
  },
  {
    label: 'Cool Head',
    imageUrl: '/GuildBall/game-plans/cool_head.jpg',
    initiative: 3,
    influence: 0,
  },
  {
    label: 'Aggressive Defence',
    imageUrl: '/GuildBall/game-plans/aggressive_defence.jpg',
    initiative: 3,
    influence: 1,
  },
  {
    label: 'Never Say Die',
    imageUrl: '/GuildBall/game-plans/never_say_die.jpg',
    initiative: 2,
    influence: 1,
  },
  {
    label: 'Raw Enthusiasm',
    imageUrl: '/GuildBall/game-plans/raw_enthusiasm.jpg',
    initiative: 2,
    influence: 1,
  },
  {
    label: 'Give Me 110%',
    imageUrl: '/GuildBall/game-plans/give_me_110.jpg',
    initiative: 1,
    influence: 2,
  },
  {
    label: 'Break Out The Knives',
    imageUrl: '/GuildBall/game-plans/break_out_the_knives.jpg',
    initiative: 1,
    influence: 0,
  },
];

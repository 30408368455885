import React, { Component, Fragment } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import withWidth, { isWidthUp } from '../withWidth';

import ColorPicker, { randomColor } from './ColorPicker';
import Dialog from '../WTDialog';

export function convertToLabel(text) {
  return text.split(/\s/).reduce((response,word)=> response+=word.indexOf('#') === 0 ? word : word.slice(0,1),'').slice(0, 2);
}

class AddEffectDialog extends Component {
  state = {
    color: randomColor(),
    recentEffects: [],
    text: '',
    selectedIndex: 0,
  }
  componentWillUpdate(newProps) {
    if (newProps.open !== this.props.open) {
      this.setState({ selectedIndex: 0 });
    }
  }
  addEffect = (effect) => {
    const { selection } = this.props;
    const effects = selection.attrs.effects || [];
    const newEffects = effects.slice(0);
    const attrs = {
      ...effect
    };
    newEffects.push(attrs);
    this.props.log(`Added ${effect.text} to ${selection.attrs.label}`);
    selection.update({ effects: newEffects });
    this.props.onClose();
    const recentEffects = [...this.state.recentEffects];
    const currentIndex = recentEffects.findIndex(e => e.text === effect.text);
    if (currentIndex >= 0) {
      recentEffects.splice(currentIndex, 1);
    }
    recentEffects.unshift(effect);
    if (recentEffects.length > 5) {
      recentEffects.splice(5, 1);
    }
    this.setState({
      color: randomColor(),
      recentEffects,
      text: '',
    });
  }
  add = () => {
    const { color, text } = this.state;
    this.addEffect({ color, text });
  }
  removeEffect = (effectIndex) => {
    const { selection } = this.props;
    const effects = selection.attrs.effects;
    const effect = effects[effectIndex];
    this.props.log(`Removed ${effect.text} from ${selection.attrs.label}`);
    this.props.onClose();
    let newEffects = [...effects];
    newEffects.splice(effectIndex, 1);
    selection.update({ effects: newEffects });
    return;
  }
  effectItem = (effect, i) => {
    return (
      <ListItem component="li" button onClick={effect.onSelect} key={i} selected={effect.selected}>
        <ListItemAvatar>
          <Avatar src={effect.icon} alt={effect.text} style={{ backgroundColor: effect.color }}>{effect.labelText || effect.text[0]}</Avatar>
        </ListItemAvatar>
        <ListItemText>{effect.text}</ListItemText>
      </ListItem>
    )
  }
  sections() {
    const sections = this.effectsList();
    return (
      <List style={{ paddingTop: 0, marginLeft: -24, marginRight: -24 }}>
        {Object.keys(sections).map(title =>
          <Fragment key={title}>
            <ListSubheader style={{ backgroundColor: '#fff' }}>{title}</ListSubheader>
            {sections[title].map((effect, i) => {
              return this.effectItem(effect, i);
            })}
          </Fragment>
        )}
      </List>
    )
  }
  effectsList() {
    const { selection } = this.props;

    const effects = {};
    const currentEffects = [];
    (selection.attrs.effects || []).forEach((effect, i) => {
      currentEffects.push({
        ...effect,
        text: `Remove "${effect.text}"`,
        onSelect: () => this.removeEffect(i),
      });
    })
    effects['Current Effects'] = currentEffects;
    let gameEffects = this.props.effects;

    effects['Recent Effects'] = this.state.recentEffects.map(effect => (
      {
        ...effect,
        onSelect: () => this.addEffect(effect),
      }
    ));
    gameEffects.forEach(section => {
      effects[section.title] = section.values.map(value => (
        {
          ...value,
          onSelect: () => this.addEffect(value)
        }
      ));
    });
    const searchText = this.state.text;
    if (searchText.length > 0) {
      Object.keys(effects).forEach(section => {
        const filteredEffects = effects[section].filter(effect => effect.text.toLowerCase().indexOf(this.state.text.toLowerCase()) >= 0);
        effects[section] = filteredEffects;
      });
    }
    Object.keys(effects).forEach(section => {
      const values = effects[section];
      if (values.length === 0) {
        delete effects[section]
      }
    });
    if (searchText.length > 0) {
      const { color } = this.state;
      const labelText = convertToLabel(searchText);
      const customEffect = {
        text: `Add "${searchText}"`,
        labelText,
        color,
        onSelect: () => this.addEffect({ labelText, text: searchText, color }),
      }
      effects['Custom Effect'] = [customEffect];
    }
    let index = 0;
    let { selectedIndex } = this.state;
    Object.keys(effects).forEach(section => {
      effects[section].forEach(effect => {
        if (index === selectedIndex) {
          effect.selected = true;
        }
        index++;
      })
    });
    return effects;
  }
  bindKeyboardEvents = (input) => {
    if (!input) return;
    input.addEventListener('keydown', e => {
      const { selectedIndex } = this.state;
      if (e.code === 'ArrowDown') {
        this.setState({ selectedIndex: selectedIndex + 1 })
      }
      if (e.code === 'ArrowUp') {
        this.setState({ selectedIndex: Math.max(0, selectedIndex - 1) })
      }
    })
  }
  onSubmit() {
    const effects = this.effectsList();
    Object.keys(effects).forEach(section => {
      effects[section].forEach(effect => {
        if (effect.selected) {
          effect.onSelect();
        }
      });
    });
  }
  render() {
    const { onClose, open, width } = this.props;
    if (!open) return null;

    const { color, text } = this.state;
    return <Dialog open={open} transitionDuration={0} fullWidth maxWidth="xs" onClose={onClose}>
      <DialogTitle>Effects</DialogTitle>
      <DialogContent>
        <form onSubmit={e => { e.preventDefault(); this.onSubmit() }}>
          <TextField
            name="search"
            label="Search"
            value={text}
            onChange={(e) => { this.setState({ selectedIndex: 0, text: e.target.value })}}
            fullWidth
            style={{ marginBottom: 16 }}
            autoFocus={isWidthUp('sm', width)}
            inputRef={input => this.bindKeyboardEvents(input)}
            variant="standard"
          />
        </form>
        {this.sections()}
        {this.state.text.length > 0 && (
          <ColorPicker
            label="Custom Effect Color"
            name="color"
            value={color}
            fullWidth
            onChange={(e) => { this.setState({ color: e.target.value })}}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  }
}

export default withWidth()(AddEffectDialog);

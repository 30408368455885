import { fabric } from 'fabric-with-gestures';
import FabricComponent from './FabricComponent';
import { LAYER_GRID } from './Layers';

export default class DeploymentLines extends FabricComponent {
  createFabricObject() {
    const { extensions, game, pixelsPerInch } = this.props;
    const width = game.attrs.width;
    const height = game.attrs.height;
    let objects = [];
    if (extensions.deployment) {
      objects = extensions.deployment({ height, pixelsPerInch, width });
    }
    return new fabric.Group(objects, {
      top: 0,
      left: 0,
      width: width * pixelsPerInch,
      height: height * pixelsPerInch,
      evented: false,
      selectable: false,
      layer: LAYER_GRID,
    });
  }
  updateFabricObject() {
    const { game, pixelsPerInch } = this.props;
    const width = game.attrs.width;
    const height = game.attrs.height;
    this.fabricObject.set({
      scaleX: width * pixelsPerInch / this.fabricObject.width ,
      scaleY: height * pixelsPerInch / this.fabricObject.height,
    });
    this.fabricObject.setCoords();
  }
}

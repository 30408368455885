import AllocateResourcesAction from './AllocateResourcesAction';
import DeployListAction from './DeployListAction';
import ListsAction from './ListsAction';
import LoadMapAction from './LoadMapAction';
import ScenarioAction from './ScenarioAction';

export default [
  LoadMapAction,
  ScenarioAction,
  ListsAction,
  DeployListAction,
  AllocateResourcesAction,
];

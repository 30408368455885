import ReactGA from 'react-ga4';

import Models from '../data/models';
import { widthFor } from '../../../map/Token';

const TINTS = [
  "#f44336", // Red
  "#2196f3", // "Blue"
  "#673ab7", // "Deep Purple",
  "#4caf50", // "Green"
  "#ff5722", // "Deep Orange",
  "#009688", // "Teal",
  "#e91e63", // "Pink",
  "#4caf50", // "Green",
  "#00bcd4", // "Cyan",
  "#9c27b0", //: "Purple",
  "#3f51b5", //: "Indigo",
  "#03a9f4", //: "Light Blue",
  "#795548", //: "Brown",
  "#9e9e9e", //: "Grey",
  "#607d8b", //: "Blue Grey"'
];

export default function textToModels(text, { color }) {
  if (text.match(/PC\s+CARD/)) {
    return parseWarmachineAppList(text, { color });
  }
  return parseWarroomAppList(text, { color });
}

function compareString(a, b) {
  if (!a) return false;
  const aNormalized = a.replace(/,/, '').replace(/’/, '\'');
  const bNormalized = (b || '').replace(/,/, '').replace(/’/, '\'');
  return aNormalized.localeCompare(bNormalized, undefined, { sensitivity: 'base' }) === 0
}

function parseWarmachineAppList(text, { color }) {
  ReactGA.event({
    category: 'Deploy',
    action: 'MKIV Warmachine App List'
  });
  var lines = text.split(/\n/).filter(line => line.trim().length > 0);

  // remove PC at the beginning of line
  lines = lines.map(line => line.replace(/^\d\d?/, ''));

  // remove # at the end of line ex: "Jackal 1"
  lines = lines.map(line => line.replace(/ \d\s*$/, ' '));

  var commandCardLine = lines.findIndex(line => line.includes("COMMAND CARD") && !line.includes("Incorrect number"));
  if (commandCardLine >= 0) {
    lines = lines.slice(0, commandCardLine);
  }

  const groups = [];
  let currentGroup = [];
  let tintIndex = 0;
  groups.push(currentGroup);
  lines.forEach(line => {
    // attachments have extra spaces at the beginning
    if (!line.match(/           /)) {
      currentGroup = [];
      groups.push(currentGroup);
    }

    currentGroup.push(line);
  });
  const counts = {};
  return groups.map((group, groupNum) => {
    const models = [];
    let tint;
    group.forEach((line) => {
      const groupId = `${color}-${groupNum}`;
      const identicalCount = lines.filter(otherLine => otherLine === line).length;

      let name = line.trim();
      const model = Models.find(model => compareString(model.name, name) || compareString(model.alias, name));

      if (model) {
        if (!tint) {
          tint = TINTS[tintIndex];
          tintIndex = (tintIndex + 1) % TINTS.length;
        }
        const count = model.count || 1;

        if (!tint && (count > 1 || identicalCount > 1)) {
          tint = TINTS[tintIndex];
          tintIndex += 1;
          tintIndex = tintIndex % TINTS.length;
        }

        for (var i = 0; i < count; i++) {
          const effects = [];
          const instanceNum = (counts[name] || 0) + 1;
          counts[name] = instanceNum;
          if (identicalCount > 1 || count > 1) {
            effects.push({ color: tint, text: `#${instanceNum}` });
          }
          if (model.standardBearer) {
            effects.push({ color: '#9e9e9e', text: "Standard Bearer", icon: "/emoji/triangular_flag_on_post.png" });
          }
          models.push({
            model,
            width: widthFor(model.size),
            effects,
            name,
            tint,
            group: groupId
          });
        }
        (model.includes || []).forEach(included => {
          let includeCount = included.count || 1;

          const includesHasLeader = included.type === 'Unit';
          for (var i = 0; i < includeCount; i++) {
            const effects = [];
            if (includesHasLeader && i === 0) {
              effects.push({ color: tint, text: "Leader", icon: "/emoji/star.png" });
            } else if (includeCount > 1) {
              effects.push({ color: tint, text: `#${i + 1}` });
            }
            if (included.standardBearer) {
              effects.push({ color: '#9e9e9e', text: "Standard Bearer", icon: "/emoji/triangular_flag_on_post.png" });
            }
            models.push({
              model: included,
              width: widthFor(included.size),
              effects,
              name: included.alias || included.name,
              tint: tint,
              group: groupId
            });
          }
        });
      }
    });
    return models;
  }).filter(group => group.length > 0);
}

function parseWarroomAppList(text, { color }) {
  ReactGA.event({
    category: 'Deploy',
    action: 'Warroom App List'
  });
  const lines = text.split(/\n/);
  const groups = [];
  let currentGroup = [];
  let tintIndex = 0;
  groups.push(currentGroup);
  lines.forEach(line => {
    if (!line.match(/^\s*-/)) {
      currentGroup = [];
      groups.push(currentGroup);
    }
    currentGroup.push(line);
  });
  const counts = {};
  return groups.map((group, groupNum) => {
    const models = [];
    let tint;
    group.forEach((line, groupLineNum) => {
      const groupId = `${color}-${groupNum}`;
      const identicalCount = lines.filter(otherLine => otherLine === line).length;

      let name = line.replace(/\[[^\]]*\]/g, '')
      name = name.replace(/^\s*-/, '');
      name = name.replace(/ <!.+!>/, '')
      const isAttached = line.match(/^\s*-/);
      let count = 1;

      const min = name.indexOf(" (min)") >= 0;
      const max = name.indexOf(" (max)") >= 0;
      name = name.replace(" (min)", "").replace(" (max)", "");

      let countPattern = / \((\d+)\)/;
      let match = name.match(countPattern);
      if (match) {
        count = parseInt(match[1], 10);
        name = name.replace(countPattern, '').trim();
      }

      let unitCount;
      countPattern = /& (\d)+/; // Leader & 9 Grunts. Crew Chief & 5 Grunts
      match = name.match(countPattern);
      if (match) {
        unitCount = parseInt(match[1], 10);
        name = name.replace(countPattern, '').trim();
      }

      match = name.match(/\S+ - (.*)/);
      if (match) {
        name = name.replace(" - " + match[1], '');
      }
      name = name.trim();

      if (name === "Wrack (The Creator's Might)") {
        name = "Wrack";
        count = 3;
      }
      if (name === "Blighted Nyss Swordsman Abbot & Champion") { // Conflict Chamber typo
        name = "Blighted Nyss Swordsmen Abbot & Champion";
      }
      if (name === "Helga the Conquerer") { // War Room misspelling
        name = "Helga the Conqueror";
      }
      if (name === "Gatorman Posse (Blindwater)") { // to be safe when removing old version
        name = "Gatorman Posse";
      }

      const model = Models.find(model => compareString(model.name, name) || compareString(model.alias, name));

      if (model) {
        if (!tint) {
          tint = TINTS[tintIndex];
          tintIndex = (tintIndex + 1) % TINTS.length;
        }
        if (min && model.min) count = model.min;
        if (max && model.max) count = model.max;
        if (model.count) count = model.count;

        if (unitCount) {
          if (unitCount === model.min || unitCount === model.min - 1) {
            count = model.min;
          } else if (unitCount === model.max || unitCount === model.max - 1) {
            count = model.max;
          } else if (unitCount === model.count || unitCount === model.count - 1) {
            count = model.count;
          }
        }

        const leader = !isAttached && (count > 1 || model.includes) && model.type.indexOf('Unit') >= 0;
        if (!tint && (leader || count > 1 || identicalCount > 1)) {
          tint = TINTS[tintIndex];
          tintIndex += 1;
          tintIndex = tintIndex % TINTS.length;
        }

        for (var i = 0; i < count; i++) {
          const effects = [];
          const count = (counts[name] || 0) + 1;
          counts[name] = count;
          if (leader) {
            if (i === 0) {
              effects.push({ color: tint, text: "Leader", icon: "/emoji/star.png" });
            } else {
              effects.push({ color: tint, text: `#${i}` });
            }
          } else if (identicalCount > 1 || count > 1) {
            effects.push({ color: tint, text: `#${count}` });
          }
          if (model.standardBearer) {
            effects.push({ color: '#9e9e9e', text: "Standard Bearer", icon: "/emoji/triangular_flag_on_post.png" });
          }
          models.push({
            model,
            width: widthFor(model.size),
            effects,
            name,
            tint,
            group: groupId
          });
        }
        (model.includes || []).forEach(included => {
          let includeCount = included.count || 1;
          if (min && included.min) includeCount = included.min;
          if (max && included.max) includeCount = included.max;

          if (unitCount) {
            if (unitCount === included.min || unitCount === included.min - 1) {
              includeCount = included.min;
            } else if (unitCount === included.max || unitCount === included.max - 1) {
              includeCount = included.max;
            } else if (unitCount === included.count || unitCount === included.count - 1) {
              includeCount = included.count;
            }
          }

          const includesHasLeader = included.type === 'Unit';
          for (var i = 0; i < includeCount; i++) {
            const effects = [];
            if (includesHasLeader && i === 0) {
              effects.push({ color: tint, text: "Leader", icon: "/emoji/star.png" });
            } else if (includeCount > 1) {
              effects.push({ color: tint, text: `#${i + 1}` });
            }
            if (included.standardBearer) {
              effects.push({ color: '#9e9e9e', text: "Standard Bearer", icon: "/emoji/triangular_flag_on_post.png" });
            }
            models.push({
              model: included,
              width: widthFor(included.size),
              effects,
              name: included.alias || included.name,
              tint: tint,
              group: groupId
            });
          }
        });
      }
    });
    return models;
  }).filter(group => group.length > 0);
}
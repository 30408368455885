import React, { Component } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import Dialog from '../WTDialog';
import Records from './Records';

const EXPORT_KEYS = [
  'facing',
  'fill',
  'height',
  'imageUrl',
  'label',
  'points',
  'stroke',
  'type',
  'width',
  'x',
  'y'
]

export default class SaveMapDialog extends Component {
  formattedRecords() {
    return <Records type="shape" connection={this.props.connection}>
      {(shape) => {
        const attrs = {};
        EXPORT_KEYS.forEach(key => {
          if (!shape.attrs.scenario && shape.attrs[key]) {
            attrs[key] = shape.attrs[key]
          }
        });
        if (!Object.keys(attrs).length) return null;
        return JSON.stringify(attrs) + ",\n"
      }}
    </Records>
  }
  render() {
    const { onClose, open } = this.props;
    if (!open) return null;

    return <Dialog open={open} maxWidth="md" onClose={onClose}>
      <DialogTitle>Save Map</DialogTitle>
      <DialogContent>
        <pre>{this.formattedRecords()}</pre>
        <Typography variant="body1">Save this map code to load into a new game</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  }
}

import React, { Component } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import { storage } from './initFirebase';

export default class AddImage extends Component {
  avatarSelected = (event) => {
    let file = event.target.files[0];
    let ref = storage.ref().child(`/uploads/${new Date().getTime()}`);
    ref.put(file).then(snapshot => {
      snapshot.ref.getDownloadURL().then(imageUrl => {
        this.props.onImageUploaded(imageUrl);
      });
    });
  }
  render() {
    return <div style={{ display: 'flex' }}>
      <Avatar style={{ backgroundColor: '#424242' }} src={this.props.imageUrl}> </Avatar>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="image-upload"
        type="file"
        onChange={this.avatarSelected}
      />
      <label htmlFor="image-upload">
        <Button component="span" style={{ marginLeft: 16 }}>Add Image</Button>
      </label>
    </div>;
  }
}

import React, { Component } from 'react';

import Counter from './widgets/Counter';
import DeviationTemplate from '../modules/Warmahordes/extensions/DeviationTemplate';
import Image from './widgets/Image';
import Laser from './widgets/Laser';
import RingTemplate from './widgets/RingTemplate';
import Ruler from './widgets/Ruler';
import Token from './widgets/Token';
import WallTemplate from './widgets/WallTemplate';
import { widthFor } from './Token';

const WIDGETS = { Counter, DeviationTemplate, Image, Laser, RingTemplate, Ruler, Token, WallTemplate };

export default class Widget extends Component {
  render() {
    const { canvas, extensions, pixelsPerInch, record } = this.props;
    let { widgetType } = record.attrs;
    let width = record.attrs.width;
    if (widgetType === 'RingTemplate3') {
      widgetType = 'RingTemplate';
      width = 3;
    }
    if (widgetType === 'RingTemplate4') {
      widgetType = 'RingTemplate';
      width = 4;
    }
    if (widgetType === 'RingTemplate5') {
      widgetType = 'RingTemplate';
      width = 5;
    }
    const customWidgets = extensions.widgets || {};
    const Type = customWidgets[widgetType] || WIDGETS[widgetType];
    return <Type canvas={canvas} pixelsPerInch={pixelsPerInch} record={record} width={width} />;
  }
}

import cards from './cards';
import effects from './effects';
import models from './models';
import settings from './settings';
import widgets from './widgets';

export default {
  cards,
  effects,
  models,
  settings,
  widgets,
};
import React, { Component, Fragment } from 'react';

import CardsIcon from './CardsIcon';

import ActionButton from '../../../game/ActionButton';
import GamePlanDialog from './GamePlanDialog';

export default class extends Component {
  render() {
    return (
      <Fragment>
        <ActionButton
          icon={CardsIcon}
          primary="Game Plans"
          onClick={() => this.props.game.update({ phase: 'gamePlan' })}
        />
        <GamePlanDialog
          onLog={this.props.log}
          game={this.props.game}
          open={this.props.game.attrs.phase === 'gamePlan'}
          onClose={() => this.props.game.update({ phase: null })}
        />
      </Fragment>
    );
  }
}

import React, { Component, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

import EditSelectionState from './EditSelectionState';
import GameState from './GameState';
import Log from './Log';

export const drawerWidth = 240;

const Drawer = styled(MuiDrawer)(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      paddingTop: 64,
      right: 0,
      width: drawerWidth,
      borderLeft: 0,
      display: 'flex',
    }
  }),
);

class SelectionDrawer extends Component {
  render() {
    const { connection, effects, game, selection } = this.props;
    let title;
    if (selection && selection.type === 'widget') {
      title = selection.attrs.widgetType;
    } else {
      title = selection && selection.attrs.label;
    }
    return (
      <Drawer
        anchor="right"
        variant="permanent"
      >
        {this.title(title, selection && selection.attrs.imageUrl)}
        {selection && this.props.actions}
        <div style={{ paddingLeft: 8, paddingRight: 8, position: 'relative' }}>
          {selection ?
            <EditSelectionState extensions={this.props.extensions} effects={effects} log={this.props.log} game={game} selection={selection} />
            :
            <Fragment>
              <Typography style={{ marginTop: 20 }} variant="h5" gutterBottom>Game Log</Typography>
              {false && (
                <Fragment>
                  <Typography gutterBottom>Check out the <a style={{ textDecoration: 'underline' }} href="https://www.youtube.com/embed/JIvNGtl34po">tutorial video</a> to learn how to play.</Typography>
                  <GameState game={this.props.game} />
                </Fragment>
              )}
              <div style={{ paddingBottom: 190 }}>
                <Log connection={connection} limit={20}/>
              </div>
              <a
                style={{ position: 'fixed', bottom: 0, backgroundColor: '#ffffff', borderTop: '1px solid #ccc', paddingTop: 8, marginRight: 8, paddingBottom: 4 }}
                href="https://patreon.com/wartable"
                target="_blank"
                rel="noopener noreferrer">
                <Typography variant="body2" gutterBottom style={{ textAlign: 'justify' }}>Enjoying War Table? Visit <span style={{ textDecoration: 'underline' }}>patreon.com/wartable</span> to find out more about how you can support the project and keep up with the latest updates.</Typography>
                <img alt="Patreon" src="/patreon.png"  style={{ width: '100%' }} />
              </a>
            </Fragment>
          }
        </div>
      </Drawer>
    )
  }
  title(title, imageUrl) {
    if (title && imageUrl) {
      return (
        <div style={{ position: 'relative', height: 250 }}>
          <img alt="" style={{ height: 250, objectFit: 'cover' }} src={imageUrl} />
          <div style={{ textAlign: 'center', position: 'absolute', bottom: 8, left: 8, right: 8, backgroundColor: 'rgba(0, 0, 0, 0.6)', padding: 8 }}>
            <Typography variant="h5" style={{ color: 'white' }}>{title}</Typography>
          </div>
        </div>
      )
    }
    if (title) {
      return <Typography variant="h5" style={{ paddingTop: 8, paddingLeft: 8, paddingRight: 8 }}>{title}</Typography>
    }
    return null;
  }
}

export default SelectionDrawer;

import currentTime from './currentTime';

export default function timeElapsed(player, game) {
  if (game.attrs.clockPaused) {
    return 0;
  }
  if (player !== game.attrs.clockActivePlayer) {
    return 0;
  }
  if (game.attrs.clockSwitchedAt) {
    return currentTime(game) - game.attrs.clockSwitchedAt;
  }
  return 0;
}

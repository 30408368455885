import React, { Component } from 'react';

import withWidth, { isWidthUp } from '../withWidth';

class CardsPopout extends Component {
  state = {}
  constructor(props) {
    super(props);
    window.addEventListener("message", this.receiveMessage, false);
    window.opener.addEventListener('beforeunload', () => {
      window.close();
    });
  }
  receiveMessage = (event) => {
    if (event.origin !== window.location.origin) {
      return;
    }
    const { cards, title } = event.data;
    if (cards) {
      this.setState({ cards, title });
    }
  }
  render() {
    const { width } = this.props;
    const { cards, title } = this.state;
    if (!cards) return null;
    let maxWidth = '50%';
    if (isWidthUp('md', width)) {
      maxWidth = '25%';
    }
    return (
      <div style={{ minHeight: '100vh', minWidth: '100vw', backgroundColor: 'black' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
          {cards && cards.map((card, i) => {
            return <img key={i} alt={title} src={card} style={{ objectFit: 'contain', width: maxWidth }}/>
          })}
        </div>
      </div>
    )
  }
}

export default withWidth()(CardsPopout);

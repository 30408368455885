import React, { Component, Fragment } from 'react';

import Typography from '@mui/material/Typography';

import GameBar from './GameBar';
import Logo from '../Logo';
import withTokens from './withTokens';

class Header extends Component {
  headerContent() {
    return {
      text: <Logo color="inherit"/>
    };
  }
  render() {
    const headerContent = this.headerContent();
    return (
      <Fragment>
        <GameBar extensions={this.props.extensions} game={this.props.game} log={this.props.log} toggleActionDrawer={this.props.toggleActionDrawer}>
          <Typography component="div" variant="body1" style={{ textAlign: 'center', color: 'white', flexGrow: 1 }}>
            <Typography style={{ color: 'white', flexGrow: 1, textAlign: 'center' }}>
              {headerContent.text}
            </Typography>
          </Typography>
        </GameBar>
      </Fragment>
    )
  }
}
export default withTokens(Header);

import React from 'react';
import { fabric } from 'fabric-with-gestures';

import BaseWidget from '../../../map/widgets/BaseWidget';
import { LAYER_WIDGETS } from '../../../map/Layers';

export default class KickScatter extends BaseWidget {
  objects() {
    fabric.Image.fromURL('/GuildBall/widgets/kick_scatter.png', (img) => {
      this.props.canvas.remove(this.fabricObject);
      this.fabricObject = img;
      this.imageHeight = img.height;
      this.imageWidth = img.width;
      this.fabricObject.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false,
        mtr: true
      });
      img.set(this.fabricObjectAttrs(this.props.record.attrs, this.props.pixelsPerInch));
      img.layer = LAYER_WIDGETS;
      this.props.canvas.add(img);
    });
    return new fabric.Object();
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    const objectAttrs = super.fabricObjectAttrs(attrs, pixelsPerInch);
    return {
      ...objectAttrs,
      scaleX: 2.96 * pixelsPerInch / this.imageWidth,
      scaleY: 1.48 * pixelsPerInch / this.imageHeight,
      centeredRotation: false,
      originX: 'center',
      originY: 'top',
    };
  }
  canRotate() {
    return true;
  }
}

import React, { Component, Fragment } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Records from '../../../game/Records';
import Dialog from '../../../WTDialog';
import NumberInput from '../../../NumberInput';

export default class AllocateResourcesDialog extends Component {
  handleChange(record, e) {
    let value = parseInt(e.target.value, 10);
    if (isNaN(value)) {
      value = 0;
    }
    record.update({ [e.target.name]: value });
  }
  render() {
    const { connection, game, onClose, open } = this.props;
    const { resource2Label } = game.attrs;
    if (!open) return null;

    let influenceAvailable = 0, influenceTotal = 0, lastColor = undefined;
    return <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Allocate Resources</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>Only models with {resource2Label} set are shown.</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Model</TableCell>
              <TableCell>{resource2Label}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <Records type='token' connection={connection} sortBy={(record) => { return [record.attrs.color + (record.attrs.tint || '')] }}>
              { (record, isLast) => {
                if (record.attrs.resource2 === undefined || record.attrs.resource2 === '') return null;
                const result = (
                  <Fragment key={record.id}>
                    <TableRow style={{ borderLeft: `3px solid ${record.attrs.color}`}}>
                      <TableCell>
                        {record.attrs.label}
                        {' '}
                        {(record.attrs.effects || []).map(e => e.text).filter(t => t.indexOf('#') === 0).join(',')}
                      </TableCell>
                      <TableCell style={{ textAlign: 'right' }}>
                        <NumberInput
                          min={0}
                          max={record.attrs.resource2Max}
                          value={record.attrs.resource2}
                          onChange={this.handleChange.bind(this, record)}
                          name="resource2"
                        />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                )
                if (lastColor !== record.attrs.color) {
                  influenceAvailable = 0;
                  influenceTotal = 0;
                }
                influenceAvailable = influenceAvailable + record.attrs.influence;
                influenceTotal = influenceTotal + record.attrs.resource2;
                lastColor = record.attrs.color;
                if (isLast) lastColor = undefined;
                return result;
              }}
            </Records>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  }
}

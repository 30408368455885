import BaseWidget from '../../../map/widgets/BaseWidget';
import { fabric } from 'fabric-with-gestures';

export default class DeviationTemplate extends BaseWidget {
  objects() {
    const objects = [];

    const MAX_RADIUS = 6;
    [1, 2, 3, 4, 5, 6].forEach((radius) => {
      const offset = this.props.pixelsPerInch * (MAX_RADIUS - radius);
      const circle = new fabric.Circle({
        top: offset,
        left: offset,
        radius: this.props.pixelsPerInch * radius,
        stroke: '#fff',
        fill: '',
        evented: false,
        selectable: false,
      });
      objects.push(circle);
    });

    [0, 60, 120].forEach((angle) => {
      const x = this.props.pixelsPerInch * MAX_RADIUS;
      const line = new fabric.Line([x, 0, x, this.props.pixelsPerInch * MAX_RADIUS * 2], {
        angle: angle,
        stroke: '#fff',
        evented: false,
        selectable: false,
        originX: 'center',
        originY: 'center'
      });
      objects.push(line);
    });
    [1, 2, 3, 4, 5, 6].forEach((label) => {
      const angle = (label - 5.4) * Math.PI / 3.0;
      const text = new fabric.Text(`${label}`, {
        left: this.inchesToPixels(MAX_RADIUS + MAX_RADIUS * 0.9 * Math.cos(angle)),
        top: this.inchesToPixels(MAX_RADIUS + MAX_RADIUS * 0.9 * Math.sin(angle)),
        fontSize: this.inchesToPixels(0.5),
        fill: '#fff',
        originX: 'center',
        originY: 'center',
        fontFamily: 'Roboto'
      });
      objects.push(text);
    });
    return objects;
  }
  canRotate() {
    return true;
  }
}

export default function selectNewRecord(canvas) {
  const select = (event) => {
    let { target } = event;
    if (target.record) {
      canvas.setActiveObject(target);
      canvas.off('object:added', select);
    }
  };
  canvas.on('object:added', select);
}

import React, { Component, Fragment } from 'react';

import TrainIcon from '@mui/icons-material/Train';

import ActionButton from '../../../game/ActionButton';
import DeployDialog from './DeployDialog';

export default class extends Component {
  state = {
    open: false
  }
  render() {
    return (
      <Fragment>
        <ActionButton
          icon={TrainIcon}
          primary="Deploy"
          onClick={() => this.setState({ open: true } )}
        />
        <DeployDialog
          color={this.props.color}
          onAddToken={this.props.onAddToken}
          addWidget={this.props.addWidget}
          onChangeColor={this.props.onChangeColor}
          game={this.props.game}
          open={this.state.open}
          onClose={() => this.setState({ open: false } )}
        />
      </Fragment>
    );
  }
}

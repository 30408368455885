export default [
  {
    "name": "Arcane Forces",
  },
  {
    "name": "Blessing of the Gods",
  },
  {
    "name": "Break Through",
  },
  {
    "name": "Careful Reconnaissance",
  },
  {
    "name": "Defenses",
  },
  {
    "name": "Hit & Run",
  },
  {
    "name": "Infiltration",
  },
  {
    "name": "Old Faithful",
  },
  {
    "name": "Power Swell",
  },
  {
    "name": "Put the Fires Out",
  },
  {
    "name": "Sapper",
  },
  {
    "name": "Savagery",
  },
  {
    "name": "Sentry Duty",
  },
  {
    "name": "Spotters",
  },
  {
    "name": "Take Cover!",
  },
  {
    "name": "True Inspiration",
  },
  {
    "name": "True  Inspiration",
  },
  {
    "name": "Accursed",
  },
  {
    "name": "Fortune's Path",
  },
  {
    "name": "Fortune’s Path",
  },
  {
    "name": "Ill Omens",
  },
  {
    "name": "Labyrinth",
  },
  {
    "name": "Ruin",
  },
  {
    "name": "Sacrifice",
  },
  {
    "name": "Shroud",
  },
  {
    "name": "Grave Robbing",
  },
  {
    "name": "High Alert",
  },
  {
    "name": "Power Swell",
  },
  {
    "name": "Duck and Cover!",
  },
]
export function distanceBetween(point1, point2) {
  return Math.pow(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2), 0.5);
}

export function roundDistance(distance) {
  let rounded = Math.ceil(distance * 10) / 10;
  if (rounded - distance > 0.0999999) {
    // if a model moved exactly 1" don't let floating point math errors show it as 1.1"
    return Math.floor(distance * 10) / 10;
  }
  return rounded;
}

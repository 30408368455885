import { widthFor } from '../../../map/Token';

export default function setup(connection) {
  connection.add('token', {
    x: 18,
    y: 18,
    label: 'Ball',
    facing: 0,
    imageUrl: '/GuildBall/avatars/ball.jpg',
    width: widthFor('30mm'),
    deletable: true,
  });
  connection.add('token', {
    x: 18,
    y: 6 - widthFor('50mm') / 2,
    label: 'Goal',
    facing: 0,
    width: widthFor('50mm'),
    immobile: true,
  });
  connection.add('token', {
    x: 18,
    y: 30 + widthFor('50mm') / 2,
    label: 'Goal',
    facing: 0,
    width: widthFor('50mm'),
    immobile: true,
  });
  connection.add('widget', {
    widgetType: 'Counter',
    value: 0,
    x: 0,
    y: 6 - widthFor('50mm') / 2,
    label: 'VPs',
    fill: '#9e9e9e',
    stroke: '#ffffff',
  });
  connection.add('widget', {
    widgetType: 'Counter',
    value: 0,
    x: 0,
    y: 30 + widthFor('50mm') / 2,
    label: 'VPs',
    fill: '#9e9e9e',
    stroke: '#ffffff',
  });
  connection.add('widget', {
    widgetType: 'Counter',
    value: 0,
    x: 36,
    y: 6 - widthFor('50mm') / 2,
    label: 'Momentum',
    fill: '#f44336',
    stroke: '#ffffff',
  });
  connection.add('widget', {
    widgetType: 'Counter',
    value: 0,
    x: 36,
    y: 30 + widthFor('50mm') / 2,
    label: 'Momentum',
    fill: '#f44336',
    stroke: '#ffffff',
  });
}

import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Dialog from '../WTDialog';

const DEFAULT_POLYGON_POINTS = [
  {"x": 0.4, "y": 0.4},
  {"x": 2, "y": 0},
  {"x": 3.3, "y": 1.5},
  {"x": 3.7, "y": 4},
  {"x": 2.6, "y": 5.6},
  {"x": 0.7, "y": 5.2},
  {"x": 0.1, "y": 4.3},
  {"x": 0, "y": 1.7}
];

const BACKGROUNDS = [
  { label: 'Grey', path: '/backgrounds/grey.jpg' },
  { label: 'Black', path: '/backgrounds/black.jpg' },
  { label: '3x3', path: '/backgrounds/forest_jaden_3x3.jpg' },
];

export default class AddShapeDialog extends Component {
  addShape(type) {
    let attrs = {
      type,
      x: this.props.mapWidth / 2,
      y: this.props.mapHeight / 2,
      stroke: '#ffffff',
      facing: 0,
      deletable: true,
    };
    if (type === 'Circle') {
      attrs.width = 12;
      attrs.fill = 'transparent';
      attrs.label = '';
    }
    if (type === 'Line') {
      attrs.width = this.props.mapWidth;
      attrs.label = '';
    }
    if (type === 'Polygon') {
      attrs.points = DEFAULT_POLYGON_POINTS;
      attrs.fill = 'transparent';
      attrs.label = '';
    }
    if (type === 'Rect') {
      attrs.width = 12;
      attrs.height = 6;
      attrs.fill = 'transparent';
      attrs.label = '';
    }
    if (type === 'Text') {
      attrs.text = 'Label';
    }
    this.props.connection.add('shape', attrs);
  }
  addRandom(terrain) {
    const terrainAttrs = terrain[Math.floor(Math.random() * terrain.length)];
    this.addTerrain(terrainAttrs);
  }
  addTerrain(terrainAttrs) {
    let copy = { ...terrainAttrs };
    delete copy.artist;
    let attrs = {
      x: this.props.mapWidth / 8 + Math.round(Math.random() * this.props.mapWidth * 3 / 4),
      y: this.props.mapHeight / 8 + Math.round(Math.random() * this.props.mapHeight * 3 / 4),
      facing: Math.round(Math.random() * 360),
      deletable: true,
      stroke: '#ffffff',
      type: 'Image',
      ...copy,
    };
    this.props.connection.add('shape', attrs);
  }
  terrainCard = (terrainAttrs, i) => {
    return (
      <Card
        key={i}
        onClick={this.addTerrain.bind(this, terrainAttrs)}
        style={{ width: '23%', marginRight: '2%', marginBottom: 24 }}
      >
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="body1">
              {terrainAttrs.label}
              {terrainAttrs.artist &&
                <Fragment>
                  {` by `}
                  {terrainAttrs.artist}
                </Fragment>
              }
            </Typography>
          </CardContent>
          {terrainAttrs.imageUrl && (
            <CardMedia
              component="img"
              image={terrainAttrs.imageUrl}
              style={{ height: 150, objectFit: 'contain' }}
            />
          )}
        </CardActionArea>
        <CardActions>
          <Button>Add</Button>
        </CardActions>
      </Card>
    )
  }
  backgroundCard = (title, backgroundImageUrl) => {
    return (
      <Card
        key={backgroundImageUrl}
        onClick={() => this.props.game.update({ backgroundImageUrl: backgroundImageUrl })}
        style={{ width: '23%', marginRight: '2%', marginBottom: 24 }}
      >
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="body1">{title}</Typography>
          </CardContent>
          <CardMedia
            component="img"
            image={backgroundImageUrl}
            style={{ height: 150, objectFit: 'contain' }}
          />
        </CardActionArea>
        <CardActions>
          <Button>Choose</Button>
        </CardActions>
      </Card>
    )
  }
  randomCard = (terrain) => {
    return (
      <Card
        onClick={() => this.addRandom(terrain)}
        style={{ width: '23%', marginRight: '2%', marginBottom: 24 }}
      >
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="body1">Random</Typography>
          </CardContent>
          <CardMedia
            component="img"
            image="/emoji/question.png"
            style={{ height: 150, objectFit: 'contain' }}
          />
        </CardActionArea>
        <CardActions>
          <Button>Add</Button>
        </CardActions>
      </Card>
    )
  }
  terrainSets() {
    return this.props.extensions.Terrain || [];
    return [];
  }
  render() {
    const { onClose, open } = this.props;
    if (!open) return null;

    return (
      <Dialog open={open} maxWidth="md" onClose={onClose}>
        <DialogTitle>Add Terrain</DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
          >
            {this.terrainSets().map((set, i) =>
              <Grid item xs={12} key={i}>
                <Typography gutterBottom variant="h6">{set.label}</Typography>
                {set.credit &&
                  <Typography paragraph variant="body2">{set.credit}</Typography>
                }
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                  {set.backgroundImageUrl && this.backgroundCard('Background', set.backgroundImageUrl)}
                  {set.terrain.map(this.terrainCard)}
                  {set.terrain.length > 3 && this.randomCard(set.terrain)}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">Additional Terrain</Typography>
              <Typography paragraph variant="body2">These backgrounds are available for improved contrast and for battle box games</Typography>
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {BACKGROUNDS.map(background => this.backgroundCard(background.label, background.path))}
                {['Circle', 'Line', 'Polygon', 'Rect', 'Text'].map((shape,i) =>
                  <Card
                    key={i}
                    onClick={this.addShape.bind(this, shape)}
                    style={{ width: '23%', marginRight: '2%', marginBottom: 24 }}
                  >
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="body1">
                          {shape}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button>Add</Button>
                    </CardActions>
                  </Card>
                )}
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

import { fabric } from 'fabric-with-gestures'
import FabricComponent from '../FabricComponent';
import { LAYER_WIDGETS } from '../Layers';

export default class BaseWidget extends FabricComponent {
  inchesToPixels(inches) {
    return inches * this.props.pixelsPerInch;
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    return {
      top: this.inchesToPixels(attrs.y),
      left: this.inchesToPixels(attrs.x),
      angle: attrs.facing,
      record: this.props.record,
      lockMovementX: attrs.immobile,
      lockMovementY: attrs.immobile,
      lockRotation: !this.canRotate() || attrs.immobile,
    };
  }
  createFabricObject() {
    const { widgetType } = this.props.record.attrs;
    const opacity = (widgetType === 'Counter' || widgetType === 'Laser' || widgetType === 'Token') ? 1 : 0.6
    let options = {
      opacity,
      originY: 'center',
      originX: 'center',
      lockScalingX: true,
      lockScalingY: true,
      subTargetCheck: true,
      record: this.props.record,
      layer: LAYER_WIDGETS,
    };
    let fabricObject = new fabric.Group(this.objects(), options);
    fabricObject.setControlsVisibility({
      bl: false,
      br: false,
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      tl: false,
      tr: false,
      mtr: this.canRotate(),
    });
    return fabricObject;
  }
  canRotate() {
    return false;
  }
}

export default [
  { label: 'Counter', type: 'Counter', attrs: { label: 'Counter', fill: '#9e9e9e', value: 0 } },
  { label: 'Small Token', type: 'Token', attrs: { label: '', fill: '#9e9e9e', width: 0.984252 } },
  { label: 'Large Token', type: 'Token', attrs: { label: '', fill: '#9e9e9e', width: 1.9685 } },
  { label: '3” Ring', type: 'RingTemplate3', attrs: { label: '', fill: '#9e9e9e' } },
  { label: '4” Ring', type: 'RingTemplate4', attrs: { label: '', fill: '#9e9e9e' } },
  { label: '5” Ring', type: 'RingTemplate5', attrs: { label: '', fill: '#9e9e9e' } },
  { label: 'Laser', type: 'Laser', attrs: { size: 16 } },
  { label: 'Ruler', type: 'Ruler', attrs: { modelOffset: true } },
];

import deployment from './deployment';
import gameActions from './gameActions';
import setup from './setup';
import Terrain from './Terrain';

export default {
  deployment,
  gameActions,
  setup,
  Terrain,
};
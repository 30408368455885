import { randomColor } from '../ColorPicker';
import { widthFor } from '../../map/Token';
import selectNewRecord from './selectNewRecord';
import modelToToken from '../modelToToken';

function deployModel({ canvas, connection, extensions, model, selection }) {
  let x = 24;
  if (selection) x = selection.attrs.x + selection.attrs.width / 2 + widthFor(model.size) / 2
  let y = selection ? selection.attrs.y : 24;
  let color = selection && selection.attrs.color ? selection.attrs.color : randomColor();
  let facing = selection ? selection.attrs.facing : 0;

  let tokenAttrs;
  if (extensions.modelToToken) {
    tokenAttrs = extensions.modelToToken(model);
  } else {
    tokenAttrs = modelToToken(model);
  }

  const attrs = {
    ...tokenAttrs,
    x,
    y,
    facing,
    color,
  };
  selectNewRecord(canvas);
  connection.addToken(attrs);
}

export function addModel({ canvas, connection, extensions, moduleData, selection, system }) {
  const models = moduleData.models || [];
  const results = [];
  models.forEach(model => {
    results.push({
      label: `Add "${model.alias || model.name}"`,
      avatar: model.avatar,
      run: () => deployModel({ canvas, connection, extensions, model, selection })
    });
    (model.includes || []).forEach(included => {
      results.push({
        label: `Add "${included.alias || included.name}"`,
        avatar: included.avatar,
        run: () => deployModel({ canvas, connection, extensions, model: included, selection })
      });
    });
  });
  return results;
}

import React, { Component } from 'react';

import formatMillis from './formatMillis';
import logExpired from './logExpired';
import timeRemaining from './timeRemaining';

export default class TimeDisplay extends Component {
  state = {
    cycle: 0, // i want the clock to update 4 times / second but the flash to happen 2 times / second
  }
  tick = () => {
    const { time } = this.state;
    const newTime = timeRemaining(this.props.player, this.props.game);
    const cycle = (this.state.cycle + 1) % 6;
    if (time && time > 0 && newTime === 0) {
      this.playAudio('/sounds/pager.mp3');
      logExpired({ player: this.props.player, game: this.props.game, log: this.props.log });
    }
    this.setState({ cycle, time: newTime });
  }
  componentDidMount() {
    this.interval = window.setInterval(this.tick, 250);
    this.tick();
  }
  componentWillUnmount() {
    window.clearInterval(this.interval);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.clockActivePlayer !== this.props.clockActivePlayer) {
      this.playAudio('/sounds/click3.m4a');
    }
  }
  playAudio(path) {
    const audio = document.createElement('AUDIO');
    audio.src = path;
    audio.play();
  }
  render() {
    const { clockActivePlayer, game, player } = this.props;
    const isPaused = game.attrs.clockPaused;
    const isZero = this.state.time === 0;
    const isActivePlayer = clockActivePlayer === player;
    const visibility = (isPaused || isZero) && isActivePlayer && this.state.cycle > 2 ? 'hidden' : '';
    return (
      <span style={{ visibility }}>
        {formatMillis(this.state.time)}
      </span>
    );
  }
}

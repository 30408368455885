import AddModelDialog from './AddModelDialog';
import gameActions from './gameActions';
import GamePlanHeader from './GamePlanHeader';
import KickScatter from './KickScatter';
import modelToToken from './modelToToken';
import numberKeyBindings from './numberKeyBindings';
import RollSelection from './RollSelection';
import setup from './setup';
import StandardScatter from './StandardScatter';
import Terrain from './Terrain';

export default {
  AddModelDialog,
  gameActions,
  CustomHeader: GamePlanHeader,
  keyboardShortcutsGeneral: [
    { label: "Roll 11-19 dice", icon: "Shift + 1 - 9" },
  ],
  modelToToken,
  numberKeyBindings,
  RollSelection,
  setup,
  Terrain,
  widgets: {
    KickScatter,
    StandardScatter,
  }
};
export default {
  "banner": "/Warmahordes/warmachine-mk4.png",
  "title": "Warmachine MKIV",
  "description": "Take control of an elite warrior mage with a battle group of devastating warjacks or warbeasts and a deadly army of soldiers in this fast-paced and aggressive 30mm tabletop miniatures game.",
  "disclaimer": "* War Table is not affiliated with Privateer Press.",
  "discord": "https://discord.gg/ntxKsPb",
  "rules": "https://home.privateerpress.com/2022/07/26/warmachine-big-news/",
  "id": "Warmachine",
  "gameSettings": {
    system: 'Warmachine',
    width: 48,
    height: 48,
    resource1Label: 'Health',
    resource2Label: 'Focus / Fury',
    resource3Label: 'Souls',
    showArcs: false,
    name: 'Warmachine & Hordes',
    backgroundImageUrl: '/backgrounds/forest_jaden.jpg',
    player1Color: '#673ab7',
    player1Label: 'Player 1',
    player2Color: '#00bcd4',
    player2Label: 'Player 2',
    clockEnabled: false,
    clockPaused: true,
    clockActivePlayer: 1,
    clockPlayer1TimeRemaining: 60 * 60 * 1000,
    clockPlayer2TimeRemaining: 60 * 60 * 1000,
  },
};
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { onValue, ref, set } from "firebase/database";

import { db } from './initFirebase';

export default class Invite extends Component {
  constructor(props) {
    super(props);
    let user = props.user;
    let { gameId, inviteCode } = props.match.params;
    let inviteSaving = set(ref(db, `/gamesByUser/${user.uid}/${gameId}`), {
      inviteCode,
      sort: new Date().getTime()
    }).then(() => {
      let gameRef = ref(db, `/games/${gameId}/game`);
      onValue(gameRef, (snapshot) => {
        const gameAttrs = snapshot.val();
        if (gameAttrs.phase === 'start') {
          set(gameRef, { phase: null });
        }
        this.setState({ inviteSaving: null });
      }, { onlyOnce: true });
    });
    this.state = { inviteSaving };
  }
  render() {
    if (this.state.inviteSaving) {
      return null;
    }
    return <Redirect to={`/games/${this.props.match.params.gameId}`} />;
  }
}

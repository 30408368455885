export default [
	{
		name: "Hold Raegh",
		factions: ["Dweghom"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/hold-raegh.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Tempered Sorcerer",
		factions: ["Dweghom"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/tempered-sorcerer.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Hellbringer Sorcerer",
		factions: ["Dweghom"],
		type: "Characters",
		size: "100mm",
		avatar: "/Conquest/avatars/hellbringer-sorcerer.jpg",
		maxMeleeRange: 1,
		resource1: 18,
	},
	{
		name: "Tempered Steelshaper",
		factions: ["Dweghom"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/tempered-steelshaper.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Ardent Kerawegh",
		factions: ["Dweghom"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/ardent-kerawegh.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Bergont Raegh",
		factions: ["Dweghom"],
		type: "Characters",
		size: "100mm",
		avatar: "/Conquest/avatars/bergont-raegh.jpg",
		maxMeleeRange: 1,
		resource1: 23,
	},
	{
		name: "Bodyguard",
		factions: ["Dweghom"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dweghom-default.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Duelist",
		factions: ["Dweghom"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dweghom-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Warrior",
		factions: ["Dweghom"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dweghom-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Advisor",
		factions: ["Dweghom"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dweghom-default.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Bannerman",
		factions: ["Dweghom"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dweghom-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Acolyte",
		factions: ["Dweghom"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dweghom-default.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Exemplar",
		factions: ["Dweghom"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/exemplar.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Flamecaster",
		factions: ["Dweghom"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/flamecaster.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Herald of Fire",
		factions: ["Dweghom"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/herald-of-fire.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Herald of Stone",
		factions: ["Dweghom"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/herald-of-stone.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Mnemancer Apprentice",
		factions: ["Dweghom"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/mnemancer-apprentice.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Herald of Magma",
		factions: ["Dweghom"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/herald-of-magma.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Flame Berserkers",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/flame-berserkers.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Hold Ballistae",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/hold-ballistae.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Hold Warriors",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/hold-warriors.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Fireforged",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/fireforged.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Initiates (Shields)",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/initiates.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Initiates (Pikes)",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/initiates.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Wardens",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/wardens.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Dragonslayers",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/dragonslayers.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Hold Thanes",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/hold-thanes.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Inferno Automata",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/inferno-automata.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Hellbringer Drake",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/hellbringer-drake.jpg",
		maxMeleeRange: 1,
		resource1: 15,
	},
	{
		name: "Steelforged",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/steelforged.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Ironclad Drake",
		factions: ["Dweghom"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/ironclad-drake.jpg",
		maxMeleeRange: 1,
		resource1: 23,
	},
	{
		name: "Blooded (Crow)",
		factions: ["Nords"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/blooded-nords.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Blooded (Wolf)",
		factions: ["Nords"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/blooded-nords.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Jarl",
		factions: ["Nords"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/jarl.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Konungyr",
		factions: ["Nords"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/konungyr.jpg",
		maxMeleeRange: 1,
		resource1: 7,
	},
	{
		name: "Shaman",
		factions: ["Nords"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/shaman.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Volva",
		factions: ["Nords"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/volva.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Vargyr Lord",
		factions: ["Nords"],
		type: "Characters",
		size: "54mm",
		avatar: "/Conquest/avatars/vargyr-lord.jpg",
		maxMeleeRange: 1,
		resource1: 8,
	},
	{
		name: "Bodyguard",
		factions: ["Nords"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/nords-default.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Duelist",
		factions: ["Nords"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/nords-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Warrior",
		factions: ["Nords"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/nords-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Tracker",
		factions: ["Nords"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/nords-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Tactician",
		factions: ["Nords"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/nords-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Bannerman",
		factions: ["Nords"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/nords-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Acolyte",
		factions: ["Nords"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/nords-default.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Shield Biter",
		factions: ["Nords"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/shield-biter.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Captain",
		factions: ["Nords"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/captain.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Skald",
		factions: ["Nords"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/skald.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Thegn",
		factions: ["Nords"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/thegn.jpg",
		maxMeleeRange: 1,
		resource1: 6,
	},
	{
		name: "Raiders",
		factions: ["Nords"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/raiders.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Stalkers",
		factions: ["Nords"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/stalkers.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Huskarls",
		factions: ["Nords"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/huskarls.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Trolls",
		factions: ["Nords"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/trolls.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Fenr Beastpack",
		factions: ["Nords"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/fenr-beastpack.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Bow Chosen",
		factions: ["Nords"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/bow-chosen.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Valkyries",
		factions: ["Nords"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/valkyries.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Ugr",
		factions: ["Nords"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/ugr.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Mountain Jotnar",
		factions: ["Nords"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/mountain-jotnar.jpg",
		maxMeleeRange: 1,
		resource1: 18,
	},
	{
		name: "Ice Jotnar",
		factions: ["Nords"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/ice-jotnar.jpg",
		maxMeleeRange: 1,
		resource1: 17,
	},
	{
		name: "Sea Jotnar",
		factions: ["Nords"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/sea-jotnar.jpg",
		maxMeleeRange: 1,
		resource1: 17,
	},
	{
		name: "Ulfhednar",
		factions: ["Nords"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/ulfhednar.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Bearsarks",
		factions: ["Nords"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/bearsarks.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Werewargs",
		factions: ["Nords"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/werewargs.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Maharajah",
		factions: ["Sorcerer Kings"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/maharajah.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Sorcerer",
		factions: ["Sorcerer Kings"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/sorcerer.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Raj",
		factions: ["Sorcerer Kings"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/raj.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Sardar",
		factions: ["Sorcerer Kings"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/sardar.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Rajakur Corps",
		factions: ["Sorcerer Kings"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/sorcerers-default.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Bound Elemental (Efreet Sword Dancer)",
		factions: ["Sorcerer Kings"],
		type: "Retinues",
		size: "54mm",
		avatar: "/Conquest/avatars/sorcerers-default.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Bound Elemental (Steelheart Djinn)",
		factions: ["Sorcerer Kings"],
		type: "Retinues",
		size: "54mm",
		avatar: "/Conquest/avatars/sorcerers-default.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Rajakur",
		factions: ["Sorcerer Kings"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/rajakur.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Ghols",
		factions: ["Sorcerer Kings"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/ghols.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Efreet Sword Dancers",
		factions: ["Sorcerer Kings"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/efreet-sword-dancers.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Efreet Flamecasters",
		factions: ["Sorcerer Kings"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/efreet-flamecasters.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Steelheart Djinn",
		factions: ["Sorcerer Kings"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/steelheart-djinn.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Windborne Djinn",
		factions: ["Sorcerer Kings"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/windborne-djinn.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Rakshasa Bakasura",
		factions: ["Sorcerer Kings"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/rakshasa-bakasura.jpg",
		maxMeleeRange: 1,
		resource1: 20,
	},
	{
		name: "Rakshasa Ravanar",
		factions: ["Sorcerer Kings"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/rakshasa-ravanar.jpg",
		maxMeleeRange: 1,
		resource1: 20,
	},
	{
		name: "Polemarch",
		factions: ["City States"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/polemarch.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Aristarch",
		factions: ["City States"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/aristarch.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Mechanist",
		factions: ["City States"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/mechanist.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Eidolon",
		factions: ["City States"],
		type: "Characters",
		size: "54mm",
		avatar: "/Conquest/avatars/eidolon.jpg",
		maxMeleeRange: 1,
		resource1: 8,
	},
	{
		name: "Ipparchos",
		factions: ["City States"],
		type: "Characters",
		size: "54mm",
		avatar: "/Conquest/avatars/ipparchos.jpg",
		maxMeleeRange: 1,
		resource1: 7,
	},
	{
		name: "Bodyguard",
		factions: ["City States"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/states-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Veteran",
		factions: ["City States"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/states-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Warrior",
		factions: ["City States"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/states-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Lochagos",
		factions: ["City States"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/lochagos.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Dorilates",
		factions: ["City States"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/dorilates.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Thorakites",
		factions: ["City States"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/thorakites.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Hoplites",
		factions: ["City States"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/hoplites.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Phalangites",
		factions: ["City States"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/phalangites.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Agema",
		factions: ["City States"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/agema.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Companion Cavalry",
		factions: ["City States"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/companion-cavalry.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Minotaur Haspists",
		factions: ["City States"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/minotaur-haspists.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Minotaur Thyreans",
		factions: ["City States"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/minotaur-thyreans.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Promethean Giant",
		factions: ["City States"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/promethean.jpg",
		maxMeleeRange: 1,
		resource1: 16,
	},
	{
		name: "Hephaestian Giant",
		factions: ["City States"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/hephaestian.jpg",
		maxMeleeRange: 1,
		resource1: 16,
	},
	{
		name: "Satyroi",
		factions: ["City States"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/satyroi.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Selinoi",
		factions: ["City States"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/selinoi.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Inquisitors",
		factions: ["City States"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/inquisitors.jpg",
		maxMeleeRange: 1,
		resource1: 6,
	},
	{
		name: "Skorpios",
		factions: ["City States"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/skorpios.jpg",
		maxMeleeRange: 1,
		resource1: 10,
	},
	{
		name: "Flogobollon",
		factions: ["City States"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/flogobollon.jpg",
		maxMeleeRange: 1,
		resource1: 10,
	},
	{
		name: "Talos",
		factions: ["City States"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/talos.jpg",
		maxMeleeRange: 1,
		resource1: 16,
	},
	{
		name: "Imperial Officer",
		factions: ["Hundred Kingdoms"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/imperial-officer.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Noble Lord",
		factions: ["Hundred Kingdoms"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/noble-lord.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Mounted Noble Lord",
		factions: ["Hundred Kingdoms"],
		type: "Characters",
		size: "54mm",
		avatar: "/Conquest/avatars/mounted-noble-lord.jpg",
		maxMeleeRange: 1,
		resource1: 7,
	},
	{
		name: "Chapter Mage",
		factions: ["Hundred Kingdoms"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/chapter-mage.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Theist Priest",
		factions: ["Hundred Kingdoms"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/theist-priest.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Priory Commander",
		factions: ["Hundred Kingdoms"],
		type: "Characters",
		size: "54mm",
		avatar: "/Conquest/avatars/priory-commander.jpg",
		maxMeleeRange: 1,
		resource1: 7,
	},
	{
		name: "Order of the Ashen Dawn",
		factions: ["Hundred Kingdoms"],
		type: "Characters",
		size: "54mm",
		avatar: "/Conquest/avatars/order-of-the-ashen-dawn.jpg",
		maxMeleeRange: 1,
		resource1: 6,
	},
	{
		name: "Bodyguard",
		factions: ["Hundred Kingdoms"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/100k-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Duelist",
		factions: ["Hundred Kingdoms"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/100k-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Warrior",
		factions: ["Hundred Kingdoms"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/100k-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Advisor",
		factions: ["Hundred Kingdoms"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/100k-default.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Bannerman",
		factions: ["Hundred Kingdoms"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/100k-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Acolyte",
		factions: ["Hundred Kingdoms"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/100k-default.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Armsmaster",
		factions: ["Hundred Kingdoms"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/armsmaster.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Drillmaster",
		factions: ["Hundred Kingdoms"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/drillmaster.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Errant of the Order of the Shield",
		factions: ["Hundred Kingdoms"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/errant-of-the-order-of-the-shield.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Neophyte",
		factions: ["Hundred Kingdoms"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/neophyte.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Null Mage",
		factions: ["Hundred Kingdoms"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/null-mage.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Servite",
		factions: ["Hundred Kingdoms"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/servite.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Seasoned Veteran",
		factions: ["Hundred Kingdoms"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/seasoned-veteran.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Men-At-Arms",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/men-at-arms.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Household Knights",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/household-knights.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Household Guard",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/household-guard.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Gilded Legion",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/gilded-legion.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Steel Legion",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/steel-legion.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Mercenary Crossbowmen",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/mercenary-crossbowmen.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Longbowmen",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/longbowmen.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Militia",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/militia.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Militia Bowmen",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/militia-bowmen.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Hunter Cadre",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/hunter-cadre.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Imperial Ranger Corps",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/imperial-ranger-corps.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Mounted Squires",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/mounted-squires.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Order of the Crimson Tower",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/order-of-the-crimson-tower.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Order of the Sealed Temple",
		factions: ["Hundred Kingdoms"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/order-of-the-sealed-temple.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Xhiliarch",
		factions: ["Old Dominion"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/xhiliarch.jpg",
		maxMeleeRange: 1,
		resource1: 6,
	},
	{
		name: "Strategos",
		factions: ["Old Dominion"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/strategos.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Mounted Strategos",
		factions: ["Old Dominion"],
		type: "Characters",
		size: "54mm",
		avatar: "/Conquest/avatars/mounted-strategos.jpg",
		maxMeleeRange: 1,
		resource1: 7,
	},
	{
		name: "Hierodeacon",
		factions: ["Old Dominion"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/hierodeacon.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Archimandrite",
		factions: ["Old Dominion"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/archimandrite.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Bodyguard",
		factions: ["Old Dominion"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dominion-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Duelist",
		factions: ["Old Dominion"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dominion-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Warrior",
		factions: ["Old Dominion"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dominion-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Advisor",
		factions: ["Old Dominion"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dominion-default.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Labarophoros",
		factions: ["Old Dominion"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dominion-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Acolyte",
		factions: ["Old Dominion"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/dominion-default.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Optio",
		factions: ["Old Dominion"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/optio.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Dark Cenotaph",
		factions: ["Old Dominion"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/dark-cenotaph.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Profane Reliquary",
		factions: ["Old Dominion"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/profane-reliquary.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Hetairos",
		factions: ["Old Dominion"],
		type: "Officers",
		size: "54mm",
		avatar: "/Conquest/avatars/hetairos.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Legionnaires",
		factions: ["Old Dominion"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/legionnaires.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Praetorian Guard",
		factions: ["Old Dominion"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/praetorian-guard.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Athanatoi",
		factions: ["Old Dominion"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/athanatoi.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Varangian Guard",
		factions: ["Old Dominion"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/varangian-guard.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Moroi",
		factions: ["Old Dominion"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/moroi.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Kheres",
		factions: ["Old Dominion"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/kheres.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Kataphraktoi",
		factions: ["Old Dominion"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/kataphraktoi.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Bone Golems",
		factions: ["Old Dominion"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/bone-golems.jpg",
		maxMeleeRange: 1,
		resource1: 6,
	},
	{
		name: "Caryatids",
		factions: ["Old Dominion"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/karyatids.jpg",
		maxMeleeRange: 1,
		resource1: 6,
	},
	{
		name: "Canephors",
		factions: ["Old Dominion"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/kanephors.jpg",
		maxMeleeRange: 1,
		resource1: 6,
	},
	{
		name: "Buccephaloi",
		factions: ["Old Dominion"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/buccephaloi.jpg",
		maxMeleeRange: 1,
		resource1: 6,
	},
	{
		name: "Biomancer",
		factions: ["Spires"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/biomancer.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Pheromancer",
		factions: ["Spires"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/pheromancer.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "High Clone Executor",
		factions: ["Spires"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/high-clone-executor.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Lineage Highborne",
		factions: ["Spires"],
		type: "Characters",
		size: "54mm",
		avatar: "/Conquest/avatars/lineage-highborne.jpg",
		maxMeleeRange: 1,
		resource1: 8,
	},
	{
		name: "Lineage Prideborne",
		factions: ["Spires"],
		type: "Characters",
		size: "54mm",
		avatar: "/Conquest/avatars/lineage-prideborne.jpg",
		maxMeleeRange: 1,
		resource1: 8,
	},
	{
		name: "Mimetic Assassin",
		factions: ["Spires"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/mimetic-assassin.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Bodyguard",
		factions: ["Spires"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/spires-default.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Duelist",
		factions: ["Spires"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/spires-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Warrior",
		factions: ["Spires"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/spires-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Advisor",
		factions: ["Spires"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/spires-default.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Assistant",
		factions: ["Spires"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/spires-default.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Assault Preceptor",
		factions: ["Spires"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/assault-preceptor.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Ward  Preceptor",
		factions: ["Spires"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/ward-preceptor.jpg",
		maxMeleeRange: 1,
		resource1: 6,
	},
	{
		name: "Catabolic Node",
		factions: ["Spires"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/catabolic-node.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Force Grown Drones",
		factions: ["Spires"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/force-grown-drones.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Vanguard Clones",
		factions: ["Spires"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/vanguard-clones.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Vanguard Clone Infiltrators",
		factions: ["Spires"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/vanguard-clone-infiltrators.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Brute Drones",
		factions: ["Spires"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/brute-drones.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Abomination",
		factions: ["Spires"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/abomination.jpg",
		maxMeleeRange: 1,
		resource1: 15,
	},
	{
		name: "Stryx",
		factions: ["Spires"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/stryx.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Avatara",
		factions: ["Spires"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/avatara.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Centaur Avatara",
		factions: ["Spires"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/centaur-avatara.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Leonine Avatara",
		factions: ["Spires"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/leonine-avatara.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Incarnate Sentinels",
		factions: ["Spires"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/incarnate-sentinels.jpg",
		maxMeleeRange: 1,
		resource1: 6,
	},
	{
		name: "Onslaught Drones",
		factions: ["Spires"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/onslaught-drones.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Bound Clones",
		factions: ["Spires"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/bound-clones.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Marksman Clones",
		factions: ["Spires"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/marksman-clones.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Siegebreaker Behemoth",
		factions: ["Spires"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/siegebreaker-behemoth.jpg",
		maxMeleeRange: 1,
		resource1: 18,
	},
	{
		name: "Matriarch Queen",
		factions: ["W'adrhŭn"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/matriarch-queen.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Apex Queen",
		factions: ["W'adrhŭn"],
		type: "Characters",
		size: "100mm",
		avatar: "/Conquest/avatars/apex-queen.jpg",
		maxMeleeRange: 1,
		resource1: 23,
	},
	{
		name: "Predator",
		factions: ["W'adrhŭn"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/predator.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Mounted Predator",
		factions: ["W'adrhŭn"],
		type: "Characters",
		size: "54mm",
		avatar: "/Conquest/avatars/wadrhun-default.jpg",
		maxMeleeRange: 1,
		resource1: 7,
	},
	{
		name: "Scion of Conquest",
		factions: ["W'adrhŭn"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/scion-of-conquest.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Chieftain",
		factions: ["W'adrhŭn"],
		type: "Characters",
		size: "27mm",
		avatar: "/Conquest/avatars/chieftain.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
	{
		name: "Thunder Chieftain",
		factions: ["W'adrhŭn"],
		type: "Characters",
		size: "54mm",
		avatar: "/Conquest/avatars/thunder-chieftain.jpg",
		maxMeleeRange: 1,
		resource1: 7,
	},
	{
		name: "Bodyguard",
		factions: ["W'adrhŭn"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/wadrhun-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Duelist",
		factions: ["W'adrhŭn"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/wadrhun-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Warrior",
		factions: ["W'adrhŭn"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/wadrhun-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Tactician",
		factions: ["W'adrhŭn"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/wadrhun-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Totem Bearer",
		factions: ["W'adrhŭn"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/wadrhun-default.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Acolyte",
		factions: ["W'adrhŭn"],
		type: "Retinues",
		size: "27mm",
		avatar: "/Conquest/avatars/wadrhun-default.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Aberration",
		factions: ["W'adrhŭn"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/aberration.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Champion",
		factions: ["W'adrhŭn"],
		type: "Officers",
		size: "27mm",
		avatar: "/Conquest/avatars/wadrhun-default.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Braves",
		factions: ["W'adrhŭn"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/braves.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Blooded",
		factions: ["W'adrhŭn"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/blooded-wadrhun.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Hunters",
		factions: ["W'adrhŭn"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/hunters.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Slingers",
		factions: ["W'adrhŭn"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/slingers.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Warbred",
		factions: ["W'adrhŭn"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/warbred.jpg",
		maxMeleeRange: 1,
		resource1: 7,
	},
	{
		name: "Hunting Pack",
		factions: ["W'adrhŭn"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/hunting-pack.jpg",
		maxMeleeRange: 1,
		resource1: 3,
	},
	{
		name: "Raptor Riders",
		factions: ["W'adrhŭn"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/raptor-riders.jpg",
		maxMeleeRange: 1,
		resource1: 4,
	},
	{
		name: "Apex Predator",
		factions: ["W'adrhŭn"],
		type: "Regiments",
		size: "100mm",
		avatar: "/Conquest/avatars/apex-predator.jpg",
		maxMeleeRange: 1,
		resource1: 18,
	},
	{
		name: "Veterans",
		factions: ["W'adrhŭn"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/veterans.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Chosen of Conquest",
		factions: ["W'adrhŭn"],
		type: "Regiments",
		size: "27mm",
		avatar: "/Conquest/avatars/chosen-of-conquest.jpg",
		maxMeleeRange: 1,
		resource1: 2,
	},
	{
		name: "Thunder Riders",
		factions: ["W'adrhŭn"],
		type: "Regiments",
		size: "54mm",
		avatar: "/Conquest/avatars/thunder-riders.jpg",
		maxMeleeRange: 1,
		resource1: 5,
	},
];

import React, { Component, Fragment } from 'react';

import StorageIcon from '@mui/icons-material/Storage';

import ActionButton from '../../../game/ActionButton';
import ListsDialog from './ListsDialog';

export default class extends Component {
  render() {
    return (
      <Fragment>
        <ActionButton
          icon={StorageIcon}
          primary="Lists"
          onClick={() => this.props.game.update({ phase: 'lists' })}
        />
        <ListsDialog
          log={this.props.log}
          game={this.props.game}
          open={this.props.game.attrs.phase === 'lists'}
          onClose={() => this.props.game.update({ phase: null })}
        />
      </Fragment>
    );
  }
}

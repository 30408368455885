import React, { Component } from 'react';
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";

import { auth } from './initFirebase';

export const withUser = (WrappedComponent, options = {}) => {
  const HOC = class extends Component {
    state = {}
    componentDidMount() {
      onAuthStateChanged(auth, this.authStateChanged);
    }
    authStateChanged = (user) => {
      this.setState({
        authChecked: true,
        user
      });
    }
    render() {
      const { authChecked, user } = this.state;
      if (!authChecked) {
        return '';
      }
      if (!user) {
        signInAnonymously(auth);
        return '';
      }
      return <WrappedComponent user={user} {...this.props} />;
    }
  };

  return HOC;
};

export default withUser;

import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga4';

import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

export default class extends Component {
  state = {
    targetNumber: null,
  }
  rollWithTarget(num, targetNum) {
    const roll = this.props.rollDice(num, 6);
    let text = `${num} ${num === 1 ? 'die' : 'dice'}`;
    if (targetNum > 0 && targetNum < 7) {
      text += ` TN=${targetNum}`
    }
    text += `: ${roll.join(',')}`;
    if (targetNum > 0 && targetNum < 7) {
      const successes = roll.filter(roll => roll >= targetNum).length;
      text += ` = ${successes} ${successes === 1 ? 'Success' : 'Successes'}`;
    }
    this.props.addLog(text);
    this.close()
  }
  close() {
    this.props.onClose();
  }
  render() {
    return (
      <Fragment>
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <FormLabel component="legend">Target Number</FormLabel>
        </Grid>
        <Grid item xs={2}>
          <Button variant={!this.state.targetNumber && 'contained'} onClick={() =>this.setState({ targetNumber: null })}>None</Button>
        </Grid>
        {[2,3,4,5,6].map(num => (
          <Grid key={num} item xs={2}>
            <Button variant={this.state.targetNumber === num && 'contained'} onClick={() =>this.setState({ targetNumber: num })}>{num}</Button>
          </Grid>
        ))}
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <FormLabel component="legend">Roll</FormLabel>
        </Grid>
        {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18].map(num => (
          <Grid key={num} item xs={3}>
            <Button onClick={() => this.rollWithTarget(num, this.state.targetNumber)}>{num} {num === 1 ? 'Die' : 'Dice'}</Button>
          </Grid>
        ))}
      </Fragment>
    )
  }
}
import { fabric } from 'fabric-with-gestures';

import FabricComponent from './FabricComponent';
import { LAYER_AURAS } from './Layers';

export default class Aura extends FabricComponent {
  createFabricObject() {
    return new fabric.Circle({
      radius: 0,
      strokeWidth: 1,
      stroke: '#000000',
      fill: '#000000',
      originX: 'center',
      originY: 'center',
      evented: false,
      selectable: false,
      opacity: 0.1,
      layer: LAYER_AURAS,
    });
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    const { isSelected } = this.props;
    const { auraColor, auraRadius, width, x, y } = attrs;
    if (!auraRadius) return;

    let fill = auraColor;
    if ((!fill || fill === '' || fill === 'transparent') && isSelected) {
      fill = 'rgba(255, 255, 255, 0.3)';
    }

    const radius = Math.max(0, (auraRadius + width / 2) * pixelsPerInch - 0.5);
    return {
      left: x * pixelsPerInch,
      top: y * pixelsPerInch,
      radius,
      fill,
      opacity: isSelected ? 0.3 : 0.15,
      stroke: isSelected ?  '#ffffff' : '#000000',
    };
  }
}

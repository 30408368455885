export default [
  {
    title: 'Tokens',
    values: [
      { text: 'Action', "icon": "/Breachstorm/effects/action.png" },
      { text: 'Concentrate Fire', "icon": "/Breachstorm/effects/concentrate_fire.png" },
      { text: 'Concussion', "icon": "/Breachstorm/effects/concussion.png" },
      { text: 'Fury', "icon": "/Breachstorm/effects/fury.png" },
      { text: 'Marker', "icon": "/Breachstorm/effects/marker.png" },
      { text: 'Order', "icon": "/Breachstorm/effects/order.png" },
      { text: 'Rend', "icon": "/Breachstorm/effects/rend.png" },
      { text: 'Suppression', "icon": "/Breachstorm/effects/suppression.png" },
    ]
  }
]
import React, { Component } from 'react';
import Circle from './shapes/Circle';
import Image from './shapes/Image';
import Line from './shapes/Line';
import Polygon from './shapes/Polygon';
import Rect from './shapes/Rect';
import Text from './shapes/Text';

const SHAPES = { Circle, Image, Line, Polygon, Rect, Text };

export default class Shape extends Component {
  render() {
    const { canvas, edit, pixelsPerInch, record } = this.props;
    const Type = SHAPES[record.attrs.type];
    return <Type canvas={canvas} pixelsPerInch={pixelsPerInch} record={record} edit={edit} />
  }
}

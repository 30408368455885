import { fabric } from 'fabric-with-gestures'
import BaseShape from './BaseShape';

export default class Polygon extends BaseShape {
  createFabricObject() {
    const { pixelsPerInch } = this.props;
    const points = this.props.record.attrs.points.map(({x, y}) => {
      return {
        x: x * pixelsPerInch,
        y: y * pixelsPerInch
      };
    });
    return new fabric.Polygon(points, {
      originX: 'center',
      originY: 'center',
      strokeWidth: 1,
      hasControls: false,
      layer: this.layer(),
    });
  }
  shapeAttrs(attrs, pixelsPerInch) {
    return {
      left: attrs.x * pixelsPerInch,
      top: attrs.y * pixelsPerInch,
      angle: attrs.facing,
      stroke: attrs.stroke,
      fill: attrs.fill
    };
  }
}

import React, { Component } from 'react';
import { fabric } from 'fabric-with-gestures';

import TokenRenderer from './map/TokenRenderer';
import Grid from '@mui/material/Grid';

export default class TokenTest extends Component {
  token(attrs) {
    const size = 200;
    const setCanvas = (canvasEl) => {
      const fabricCanvas = new fabric.Canvas(canvasEl);
      new TokenRenderer(attrs).toImage().then((image) => {
        fabricCanvas.add(image);
      });
    }
    return <canvas ref={setCanvas} width={size} height={size} />;
  }
  render() {
    return <Grid container>
      <Grid item xs={4}>
        {this.token({
          imageUrl: "/Warmahordes/avatars/TheHarbingerofMenoth.jpg",
          size: 200,
          color: '#e91e63',
          resource1: 28,
          resource2: 1,
          resource3: 3,
          effects: [
            { color: '#e91e63', icon: "/emoji/anchor.png", },
            { color: '#e91e63', icon: "/emoji/anchor.png" },
            { color: '#e91e63', icon: "/emoji/anchor.png" },
            { color: '#e91e63', icon: "/emoji/anchor.png" },
            { color: '#e91e63', icon: "/emoji/anchor.png" },
            { color: '#e91e63', icon: "/emoji/anchor.png" },
            { color: '#e91e63', icon: "/emoji/anchor.png" },
            { color: '#e91e63', icon: "/emoji/anchor.png" },
          ]
        })}
      </Grid>
      <Grid item xs={4}>
        {this.token({
          label: 'Duplicate Effects',
          size: 200,
          color: '#e91e63',
          effects: [
            { color: '#9c27b0', text: 'Shadow Mist', icon: '/emoji/cloud.png' },
            { color: '#9c27b0', text: 'Shadow Mist', icon: '/emoji/cloud.png' },
            { color: '#9c27b0', text: 'Shadow Mist', icon: '/emoji/cloud.png' },
          ],
        })}
      </Grid>
      <Grid item xs={4}>
        {this.token({
          imageUrl: "/Warmahordes/avatars/TheHarbingerofMenoth.jpg",
          size: 200,
          color: '#e91e63',
          effects: [
            { color: '#e91e63', text: 'Stun', },
            { color: '#e91e63', text: 'Stun', },
            { color: '#e91e63', text: 'Vorpal Blade', icon: '/emoji/anchor.png' },
            { color: '#9c27b0', text: 'Long Effect Name' },
          ]
        })}
      </Grid>
      <Grid item xs={4}>
        {this.token({
          label: 'This is my marker',
          size: 200,
          color: '#e91e63',
          resource1: 15,
          effects: [
            { color: '#e91e63', icon: "/emoji/anchor.png", },
            { color: '#e91e63', icon: "/emoji/anchor.png" },
          ]
        })}
      </Grid>
      <Grid item xs={4}>
        {this.token({
          label: 'Highlight',
          size: 200,
          color: '#e91e63',
          highlight: true
        })}
      </Grid>
      <Grid item xs={4}>
        {this.token({
          imageUrl: "/Warmahordes/avatars/TheHarbingerofMenoth.jpg",
          size: 200,
          color: '#e91e63',
          tint: '#e91e63',
        })}
      </Grid>
    </Grid>;
  }
}

export default {
  "id": "ConquestFB",
  "banner": "/Conquest/FB/banner.jpg",
  "title": "Conquest: First Blood",
  "description": "Conquest: First Blood is a fast-paced Wargame of thrilling Skirmish battles, set in the world of Conquest.",
  "rules": "https://www.para-bellum.com/conquest-first-blood/",
  "gameSettings": {
    system: 'ConquestFB',
    width: 48,
    height: 48,
    resource1Label: 'Wounds',
    resource2Label: '',
    resource3Label: '',
    name: 'Conquest: First Blood',
    backgroundImageUrl: '/backgrounds/forest_jaden.jpg',
    showArcs: false,
    player1Color: '#D8967E',
    player1Label: 'Player A',
    player2Color: '#90A0BA',
    player2Label: 'Player B',
    clockEnabled: false,
    clockPaused: true,
    clockActivePlayer: 1,
    clockPlayer1TimeRemaining: 60 * 60 * 1000,
    clockPlayer2TimeRemaining: 60 * 60 * 1000,
  },
};
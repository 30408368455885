import { fabric } from 'fabric-with-gestures';

export default function({ pixelsPerInch }) {
  const objects = [];
  [
    [{ x: 18, y: 0 }, { x: 18, y: 6 }],
    [{ x: 18, y: 6 }, { x: 24, y: 6 }],
    [{ x: 0, y: 18 }, { x: 6, y: 18 }],
    [{ x: 6, y: 18 }, { x: 6, y: 24 }],
  ].forEach(([start, end]) => {
    const line = new fabric.Line([start.x * pixelsPerInch, start.y * pixelsPerInch, end.x * pixelsPerInch, end.y * pixelsPerInch], {
      strokeWidth: 1,
      stroke: '#ffffff',
      opacity: 1,
      deploymentLine: true,
      originX: 'center',
      originY: 'center',
    });
    objects.push(line);
  });
  return objects;
}
import React, { Component, Fragment } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Dialog from '../../../WTDialog';

export default class DeployDialog extends Component {
  state = {
    player1Lists: null,
    player2Lists: null,
  }
  confirmReveal = (player) => {
    const { game } = this.props;
    game.update({ [`player${player}Reveal`]: this.state[`player${player}Reveal`] });
  }
  enterReveal = () => {
    const { game } = this.props;
    return (
      <Grid container spacing={2}>
        {[1, 2].map(player =>
          <Grid key={`player${player}`} item xs={12} sm={6}>
            <Typography variant="h6">{game.attrs[`player${player}Label`]}</Typography>
            {game.attrs[`player${player}Reveal`] ? (
              <Typography variant="body1" paragraph>{game.attrs[`player${player}Label`]} secret entered</Typography>
            ) : (
              <Fragment>
                <TextField
                  margin="normal"
                  fullWidth
                  multiline
                  rows={5}
                  label="Enter your secret here"
                  value={this.state[`player${player}Reveal`] || ''}
                  onChange={(e) => { this.setState({ [`player${player}Reveal`]: e.target.value }) }}
                />
                <Button disabled={!this.state[`player${player}Reveal`]} variant="contained" color="primary" onClick={() => { this.confirmReveal(player) }}>Confirm</Button>
              </Fragment>
            )}
          </Grid>
        )}
      </Grid>
    )
  }
  reveal = () => {
    const { game } = this.props;
    return (
      <Grid container spacing={2}>
        {[1, 2].map(player =>
          <Grid key={`player${player}`} item xs={12} sm={6}>
            <Typography variant="h6">{game.attrs[`player${player}Label`]}</Typography>
            <Typography variant="body1">{game.attrs[`player${player}Label`]} chose "{game.attrs[`player${player}Reveal`]}"</Typography>
          </Grid>
        )}
      </Grid>
    )
  }
  dialogContent = () => {
    const { game } = this.props;
    if (game.attrs.player1Reveal && game.attrs.player2Reveal) {
      return this.reveal();
    }
    return this.enterReveal();
  }
  onReset = () => {
    this.setState({
      player1Reveal: null,
      player2Reveal: null,
    });
    this.props.game.update({
      player1Reveal: null,
      player2Reveal: null,
    });
  }
  render() {
    if (!this.props.open) return null;
    const { game } = this.props;

    return (
      <Dialog open maxWidth="md" fullWidth onClose={this.props.onClose}>
        <DialogTitle>Reveal</DialogTitle>
        <DialogContent>
          {this.dialogContent()}
        </DialogContent>
        <DialogActions>
          {(game.attrs.player1Reveal || game.attrs.player2Reveal) && (
            <Button onClick={this.onReset}>Reset</Button>
          )}
          {game.attrs.player1Reveal && game.attrs.player2Reveal ? (
            <Button variant="contained" color="primary" onClick={this.props.onClose}>Done</Button>
          ) : (
            <Button onClick={this.props.onClose}>Cancel</Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

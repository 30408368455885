
const FIVE_MAN_POSITIONS = [
  { x: 0, y: 0 },
  { x: 1, y: 0 },
  { x: 0.5, y: Math.sqrt(3) / 2 },
  { x: -1, y: 0 },
  { x: -0.5, y: Math.sqrt(3) / 2 },
];

const SIX_MAN_POSITIONS = [
  { x: 0, y: 0 },
  { x: 1, y: 0 },
  { x: 0, y: 1 },
  { x: 1, y: 1 },
  { x: -1, y: 0 },
  { x: -1, y: 1 },
  { x: 0, y: 2 },
  { x: 1, y: 2 },
  { x: -1, y: 2 },
];

const TEN_MAN_POSITIONS = [
  { x: 0, y: 0 },
  { x: 1, y: 0 },
  { x: -1, y: 0 },
  { x: 2, y: 0 },
  { x: -2, y: 0 },
  { x: 0, y: 1 },
  { x: 1, y: 1 },
  { x: -1, y: 1 },
  { x: 2, y: 1 },
  { x: -2, y: 1 },
  { x: 0, y: 2 },
  { x: 1, y: 2 },
  { x: -1, y: 2 },
  { x: 2, y: 2 },
  { x: -2, y: 2 },
];

export default function setFormation(group, offsetDirection) {
  if (Math.max(...group.map(model => model.width)) !== Math.min(...group.map(model => model.width))) {
    // inconsistent widths
    let x = 0;
    group.forEach(model => {
      model.y = 0;
      model.x = x + model.width / 2;
      x += model.width;
    })
    return;
  }
  const totalModels = group.length;
  let positions;
  if (totalModels === 3 || totalModels === 5) {
    positions = FIVE_MAN_POSITIONS;
  } else if (totalModels <= 9) {
    positions = SIX_MAN_POSITIONS;
  } else {
    positions = TEN_MAN_POSITIONS;
  }
  group.forEach((model, i) => {
    model.x = positions[i].x * model.width;
    model.y = positions[i].y * model.width * offsetDirection;
  });
}


import React, { Component } from 'react';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';

export default class NumberInput extends Component {
  increment = () => {
    this.onChange(this.props.value, 1);
  }
  decrement = () => {
    this.onChange(this.props.value, -1);
  }
  onChange = (value, amount) => {
    let result = (parseInt(value) || 0) + amount;
    if (this.props.min !== undefined) {
      result = Math.max(result, this.props.min);
    }
    if (this.props.max) {
      result = Math.min(result, this.props.max);
    }
    this.props.onChange({ target: { name: this.props.name, value: result }});
  }
  render() {
    return <FormControl fullWidth={this.props.fullWidth} margin={this.props.margin}>
      <InputLabel variant="standard">{this.props.label}</InputLabel>
      <Input
        value={this.props.value}
        name={this.props.name}
        type="number"
        onChange={(e) => this.onChange(e.target.value, 0)}
        fullWidth={false}
        inputProps={{pattern: "[0-9]*", style: { textAlign: 'right' }}}
        inputRef={node => {
          if (!node) { return }
          node.addEventListener('click', function() { this.select() });
        }}
        startAdornment={<InputAdornment position="start"><Button onClick={this.decrement} variant="outlined" size="small" style={{ height: 20, minHeight: 20, width: 20, minWidth: 20, padding: 3, lineHeight: 0.5 }}>-</Button></InputAdornment>}
        endAdornment={<InputAdornment position="end"><Button onClick={this.increment} variant="outlined" size="small" style={{ height: 20, minHeight: 20, width: 20, minWidth: 20, padding: 3, lineHeight: 0.5 }}>+</Button></InputAdornment>}
      />
      {this.props.helperText && <FormHelperText>{this.props.helperText}</FormHelperText>}
    </FormControl>;
  }
}

import React, { Component } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import SignInButton from './SignInButton';
import Logo from '../Logo';
import featuredModules from '../modules/featured';
import { loadSettings } from '../modules';

class SignUp extends Component {
  state = {}
  async componentDidMount() {
    const modules = await Promise.all(featuredModules.map(this.resolveFeatured.bind(this)));
    this.setState({ modules });
  }
  async resolveFeatured(featuredModule) {
    const module = await loadSettings(featuredModule.id);
    return {
      featuredImage: featuredModule.image,
      ...module,
    };
  }
  render() {
    const windowWidth = window.innerWidth;
    const containerWidth = 960;
    const videoWidth = Math.min(windowWidth, 960) - 32;

    const { modules } = this.state;
    if (!modules) {
      return null;
    }

    return (
      <div style={{ maxWidth: containerWidth, margin: '0 auto', padding: 16 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} align="center" style={{ marginBottom: 32 }}>
            <Typography variant="h2"><Logo/></Typography>
            <Typography paragraph variant="body2" color="textSecondary"><span role="img" aria-label="Crossed Swords">⚔️</span> War games in your browser <span role="img" aria-label="Crossed Swords">⚔️</span></Typography>
            <SignInButton style={{ position: 'absolute', top: 15, right: 10 }} />
          </Grid>
        </Grid>
        {modules.map(module => (
          <a key={module.id} href={`/modules/${module.id}`}>
            <Grid container spacing={2} style={{ marginBottom: 24 }}>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                <img alt="Warmachine Logo" style={{ objectFit: 'cover', height: '100%', width: '100%' }} src={module.featuredImage} />
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginBottom: 16 }}>
                <Typography variant="h4" gutterBottom>{module.title}</Typography>
                <Typography variant="body2" paragraph color="textSecondary">{module.description}</Typography>
                <Button style={{ margin: '0 auto 16px' }} size="large" variant="contained" color="secondary">
                  Play
                </Button>
              </Grid>
            </Grid>
          </a>
        ))}
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginBottom: 32 }}>
            <Typography variant="h4" gutterBottom>About War Table</Typography>
            <Typography variant="body2" paragraph color="textSecondary">War Table brings table top war games to your browser. It’s a virtual table optimized for the precise measurement and movement that wargames require. Hit me up at <a href="mailto:lars@wartable.online" style={{ textDecoration: 'underline' }}>lars@wartable.online</a> with any suggestions, stories, or requests for better dice luck.</Typography>
            <iframe title="Welcome to wartable.online" style={{ width: videoWidth, height: videoWidth / 2 }} src="https://www.youtube.com/embed/FTee13G6Ycw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <a href="https://www.patreon.com/bePatron?u=13434209" data-patreon-widget-type="become-patron-button"><img alt="Become a Patron" src="/become_a_patron_button.png" style={{ height: 32 }} /></a>
            <script async src="https://c6.patreon.com/becomePatronButton.bundle.js"></script>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default SignUp;

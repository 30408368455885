import { fabric } from 'fabric-with-gestures';
import FabricComponent from './FabricComponent';
import { LAYER_WIDGETS } from './Layers';

export default class Deck extends FabricComponent {
  createFabricObject() {
    this.loadImage();
    return new fabric.Object({ layer: LAYER_WIDGETS });
  }
  loadImage() {
    const topCard = this.props.record.attrs.cards[0];
    if (!topCard) return;
    const url = this.props.record.attrs.faceUp ? topCard.frontImageUrl : topCard.backImageUrl;
    fabric.Image.fromURL(url, (img) => {
      this.props.canvas.remove(this.fabricObject);
      this.fabricObject = img;
      this.imageHeight = img.height;
      this.imageWidth = img.width;
      this.fabricObject.setControlsVisibility({
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        tl: false,
        tr: false,
        mtr: true
      })
      img.set(this.fabricObjectAttrs(this.props.record.attrs, this.props.pixelsPerInch));
      img.layer = LAYER_WIDGETS;
      this.props.canvas.add(img);
    });
  }
  componentDidUpdate(prevProps) {
    const { record } = this.props;
    const prevRecord = prevProps.record;
    if (record.attrs.faceUp !== prevRecord.attrs.faceUp) {
      this.loadImage();
    }
    this.updateFabricObject();
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    if (!this.imageHeight) return {};
    return {
      originX: 'center',
      originY: 'center',
      left: attrs.x * pixelsPerInch,
      top: attrs.y * pixelsPerInch,
      record: this.props.record,
      angle: attrs.facing,
      scaleX: attrs.width * pixelsPerInch / this.imageWidth,
      scaleY: attrs.height * pixelsPerInch / this.imageHeight,
    };
  }
}

import formatMillis from './formatMillis';
import recordTimeUsed from './recordTimeUsed';

export default function toggleActivePlayer(game, log, attrs) {
  const clockActivePlayer = game.attrs.clockActivePlayer === 1 ? 2 : 1;
  const { clockPlayer1TimeRemaining, clockPlayer2TimeRemaining } = recordTimeUsed(game, { ...attrs, clockActivePlayer });
  const { player1Label, player2Label } = game.attrs;
  if (clockActivePlayer === 1) {
    log(`Clock switched from ${player2Label} (${formatMillis(clockPlayer2TimeRemaining)}) to ${player1Label} (${formatMillis(clockPlayer1TimeRemaining)})`);
  } else {
    log(`Clock switched from ${player1Label} (${formatMillis(clockPlayer1TimeRemaining)}) to ${player2Label} (${formatMillis(clockPlayer2TimeRemaining)})`);
  }
}

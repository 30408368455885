import { fabric } from 'fabric-with-gestures'
import BaseWidget from '../../../map/widgets/BaseWidget';

export default class SprayTemplate extends BaseWidget {
  objects() {
    const lines = [];
    // TODO: these numbers aren't quite right
    let lastPoint = [.625, 10];
    [[0.875, 10], [1.5, 0.1], [0.75, 0], [0, 0.1], [.625, 10]].forEach((point) => {
      const line = new fabric.Line(
        [
          this.inchesToPixels(lastPoint[0]),
          this.inchesToPixels(lastPoint[1]),
          this.inchesToPixels(point[0]),
          this.inchesToPixels(point[1])
        ], {
          stroke: '#fff'
        }
      );
      lines.push(line);
      lastPoint = point;
    });
    // center line
    lines.push(new fabric.Line([this.inchesToPixels(0.75), this.inchesToPixels(0), this.inchesToPixels(0.75), this.inchesToPixels(10)], { stroke: '#fff' }));

    // 8 inch mark
    lines.push(new fabric.Line([this.inchesToPixels(0.75), this.inchesToPixels(2), this.inchesToPixels(0.15), this.inchesToPixels(2.04)], { stroke: '#fff' }));
    lines.push(new fabric.Line([this.inchesToPixels(0.75), this.inchesToPixels(2), this.inchesToPixels(1.35), this.inchesToPixels(2.04)], { stroke: '#fff' }));

    //6 inch mark
    lines.push(new fabric.Line([this.inchesToPixels(0.75), this.inchesToPixels(4), this.inchesToPixels(0.25), this.inchesToPixels(4.06)], { stroke: '#fff' }));
    lines.push(new fabric.Line([this.inchesToPixels(0.75), this.inchesToPixels(4), this.inchesToPixels(1.25), this.inchesToPixels(4.06)], { stroke: '#fff' }));

    if (this.props.record.attrs.rotateRadius) {
      // spray template should rotate around model
      lines.push(new fabric.Line([this.inchesToPixels(0.75), 10, this.inchesToPixels(0.75), this.inchesToPixels(10 + this.props.record.attrs.rotateRadius)], { stroke: 'rgba(0,0,0,0)' }));
    }

    return lines;
  }
  fabricObjectAttrs(attrs, pixelsPerInch) {
    const objectAttrs = super.fabricObjectAttrs(attrs, pixelsPerInch);
    return {
      ...objectAttrs,
      centeredRotation: false,
      originX: 'center',
      originY: 'bottom',
    };
  }
  canRotate() {
    return true;
  }
}

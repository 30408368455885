import currentTime from './currentTime';
import timeElapsed from './timeElapsed';

export default function recordTimeUsed(game, attrs) {
  let { clockActivePlayer, clockPaused, clockPlayer1TimeRemaining, clockPlayer2TimeRemaining } = game.attrs;
  if (!clockPaused) {
    if (clockActivePlayer === 1) {
      clockPlayer1TimeRemaining = Math.max(0, (clockPlayer1TimeRemaining || 0) - timeElapsed(clockActivePlayer, game));
    } else {
      clockPlayer2TimeRemaining = Math.max(0, (clockPlayer2TimeRemaining || 0) - timeElapsed(clockActivePlayer, game));
    }
  }
  const newAttrs = {
    ...attrs,
    clockSwitchedAt: currentTime(game),
    clockPlayer1TimeRemaining,
    clockPlayer2TimeRemaining,
  }
  game.update(newAttrs);
  return newAttrs;
}

import React, { Component } from 'react';
import TextField from '@mui/material/TextField';

export default class GameState extends Component {
  handleChange = (event) => {
    this.props.game.update({ notes: event.target.value });
  }
  render() {
    const { game } = this.props;
    return <TextField
      name="notes"
      label="Game Notes"
      value={game.attrs.notes}
      multiline
      onChange={this.handleChange}
      fullWidth
      margin="normal"
      rows="5"
      rowsMax="15"
    />;
  }
}

import React, { Component } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import AddImage from '../AddImage';
import ColorPicker from './ColorPicker';
import Dialog from '../WTDialog';
import { widthFor } from '../map/Token';

export default class EditDialog extends Component {
  handleChange(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'width') {
      value = parseFloat(value);
    }
    if (name.indexOf('resource') >= 0) {
      value = parseInt(value);
    }
    this.props.record.update({[name]: value});
  }
  sizes() {
    return ['20mm', '25mm', '27mm', '30mm', '35mm', '40mm', '50mm', '54mm', '65mm', '80mm', '100mm', '120mm', '0.5in', '0.75in', '1in', '1.5in', '2in'];
  }
  avatarSelected = (imageUrl) => {
    this.props.record.update({ imageUrl });
  }
  render() {
    const { open, record } = this.props;
    if (!record) {
      return null;
    }
    const { attrs } = record;
    return <Dialog open={open} maxWidth="md" onClose={this.props.onClose}>
      <DialogTitle>Edit Model</DialogTitle>
      <DialogContent>
        <TextField
          name="label"
          label="Label"
          value={attrs.label}
          onChange={this.handleChange.bind(this)}
          fullWidth
          margin="normal"
          variant="standard"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="token-width" variant="standard">Size</InputLabel>
          <Select
            value={attrs.width}
            onChange={this.handleChange.bind(this)}
            inputProps={{
              name: 'width',
              id: 'token-width',
            }}
            variant="standard"
          >
            {this.sizes().map(size => {
              return (
                <MenuItem key={size} value={widthFor(size)}>{size}</MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {false &&
          <FormControl fullWidth margin="normal">
            <AddImage imageUrl={record.attrs.imageUrl} onImageUploaded={this.avatarSelected}/>
          </FormControl>
        }
        <FormControl fullWidth margin="normal">
          <ColorPicker
            onChange={this.handleChange.bind(this)}
            name="color"
            label="Base"
            value={attrs.color}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <ColorPicker
            onChange={this.handleChange.bind(this)}
            name="tint"
            label="Tint"
            value={attrs.tint}
            includeTransparent
          />
        </FormControl>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              name="resource1"
              label={this.props.resource1Label}
              style={{color: '#f44336'}}
              value={attrs.resource1}
              onChange={this.handleChange.bind(this)}
              fullWidth
              margin="normal"
              type="number"
              inputProps={{pattern: "[0-9]*"}}
              variant="standard"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="resource2"
              label={this.props.resource2Label}
              style={{color: '#2196f3'}}
              value={attrs.resource2}
              onChange={this.handleChange.bind(this)}
              fullWidth
              margin="normal"
              type="number"
              inputProps={{pattern: "[0-9]*"}}
              variant="standard"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="resource3"
              label={this.props.resource3Label}
              style={{color: '#4caf50'}}
              value={attrs.resource3}
              onChange={this.handleChange.bind(this)}
              fullWidth
              margin="normal"
              type="number"
              inputProps={{pattern: "[0-9]*"}}
              variant="standard"
            />
          </Grid>
        </Grid>
        <FormControlLabel
          control={
            <Checkbox
              checked={attrs.immobile}
              onChange={(e) => { this.props.record.update({ immobile: e.target.checked })}}
              name="immobile"
              color="primary"
            />
          }
          label="Pinned?"
        />
        <TextField
          name="notes"
          label="Notes"
          value={attrs.notes}
          multiline
          onChange={this.handleChange.bind(this)}
          fullWidth
          margin="normal"
          rows="3"
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={this.props.onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  }
}

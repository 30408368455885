import { fabric } from 'fabric-with-gestures';
import FabricComponent from './FabricComponent';
import TokenRenderer from './TokenRenderer';
import { LAYER_AURAS, LAYER_IMMOBILE_MODELS, LAYER_MODELS } from './Layers';

import { distanceBetween, roundDistance } from '../Distance';

export default class Token extends FabricComponent {
  state = { renderNum: 0 }
  componentDidMount() {
    const radius = this.props.width * this.props.pixelsPerInch / 2;
    const borderWidth = radius / 6;

    this.backgroundImage = new fabric.Image("", { layer: LAYER_MODELS });

    this.backArc = new fabric.Circle({
      radius: radius - borderWidth / 2,
      strokeWidth: borderWidth,
      fill: '',
      originX: 'center',
      originY: 'center',
      startAngle: 0,
      endAngle: Math.PI,
    });
    if (this.props.showArcs) {
      this.backArc.set({
        stroke: '#000000',
        opacity: 0.7,
      });
    }
    this.arcMarkerLeft = new fabric.Rect({
      fill: '#ffffff',
      originX: 'center',
      originY: 'center',
    });
    this.arcMarkerRight = new fabric.Rect({
      fill: '#ffffff',
      originX: 'center',
      originY: 'center',
    });
    this.arcMarkerFront = new fabric.Rect({
      fill: '#ffffff',
      originX: 'center',
      originY: 'center',
    });

    this.token = new fabric.Group([ this.backArc, this.arcMarkerLeft, this.arcMarkerRight, this.arcMarkerFront ], {
      centeredRotation: true,
      originX: 'center',
      originY: 'center',
      borderColor: '#fff',
      opacity: this.props.opacity,
      record: this.props.record,
      evented: this.props.selectable,
      selectable: this.props.selectable,
      lockRotation: !this.props.showArcs,
      lockScalingX: true,
      lockScalingY: true,
      layer: LAYER_MODELS,
    });

    this.token.setControlsVisibility({
      bl: false,
      br: false,
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      tl: false,
      tr: false,
      mtr: this.props.showArcs
    });

    this.distance = new fabric.Text(this.distanceText(), {
      fontSize: 0.5 * this.props.pixelsPerInch,
      textAlign: 'center',
      fill: 'white',
      textBackgroundColor: '#444',
      originX: 'center',
      originY: 'center',
      evented: false,
      selectable: false,
      fontFamily: 'Roboto',
      lockScalingX: true,
      lockScalingY: true,
      opacity: this.props.opacity,
      layer: LAYER_MODELS,
    });

    this.meleeAura = new fabric.Circle({
      radius: 0,
      strokeWidth: 0,
      stroke: '#2196f3',
      fill: '',
      originX: 'center',
      originY: 'center',
      evented: false,
      selectable: false,
      opacity: 0.1,
      layer: LAYER_AURAS,
    });
    this.meleeFacing = new fabric.Rect({
      fill: '#f44336',
      originX: 'center',
      originY: 'center',
      opacity: 0,
      evented: false,
      selectable: false,
      layer: LAYER_MODELS,
    });
    if (this.props.showArcs) {
      this.meleeAura.set({
        startAngle: Math.PI,
        endAngle: 2 * Math.PI,
      });
    }

    this.props.canvas.add(this.meleeAura);
    this.props.canvas.add(this.meleeFacing);
    this.props.canvas.add(this.backgroundImage);
    this.props.canvas.add(this.token);
    this.props.canvas.add(this.distance);

    this.rerenderToken();
    this.applyProps();

    this.token.on('selected', this.bringToFront);
  }
  loadImage(url) {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(url, (img, err) => {
        if (err) {
          reject(err)
        } else {
          resolve(img);
        }
      }, { crossOrigin: 'anonymous' });
    });
  }
  rerenderToken() {
    const { activated, effects, imageUrl, color, conditions, isActivating, label, resource1, resource2, resource3, tint, width } = this.props;
    let { renderNum } = this.state;
    renderNum += 1;
    this.setState({ renderNum });
    let baseColor = color;
    let effectsCopy = (effects || []).slice(0);
    if (activated) {
      effectsCopy.push({
        text: '✓',
        color: '#4caf50',
      });
    }
    let size = 160 * width;
    new TokenRenderer({
      color: baseColor,
      highlight: isActivating,
      conditions,
      effects: effectsCopy,
      imageUrl,
      label,
      resource1,
      resource2,
      resource3,
      size,
      tint
    }).toImage().then((image) => {
      if (this.state.renderNum === renderNum) {
        this.updateImage(image);
      }
    });
  }
  updateImage = (image) => {
    const { width } = this.props;
    this.props.canvas.remove(this.backgroundImage);
    this.backgroundImage = image;
    this.backgroundImage.scaleToWidth(width * this.props.pixelsPerInch);
    this.backgroundImage.set({
      originX: 'center',
      originY: 'center',
      left: this.props.x * this.props.pixelsPerInch,
      top: this.props.y * this.props.pixelsPerInch,
      opacity: this.props.opacity,
      evented: false,
      selectable: false,
      layer: LAYER_MODELS,
    });
    if (this.props.immobile) {
      const objects = this.props.canvas.getObjects();
      const index = objects.findIndex(o => o.record && o.record.type === 'token') || 1;
      this.props.canvas.insertAt(image, index);
    } else {
      this.props.canvas.add(image);
      this.bringToFront();
    }
  }
  componentWillUnmount() {
    this.token.off('selected', this.bringToFront);
    this.props.canvas.remove(this.meleeAura);
    this.props.canvas.remove(this.meleeFacing);
    this.props.canvas.remove(this.backgroundImage);
    this.props.canvas.remove(this.token);
    this.props.canvas.remove(this.distance);
    this.meleeAura = null;
    this.meleeFacing = null;
    this.backgroundImage = null;
    this.token = null;
    this.distance = null;
  }
  bringToFront = () => {
    if (!this.token || this.props.immobile) return;
    this.backgroundImage.bringToFront();
    this.token.bringToFront();
    this.distance.bringToFront();
  }
  distanceToSelected() {
    let { record, selections, width, x, y } = this.props;
    if (selections.find(selection => selection.path === record.path)) return;

    let distances = selections.filter(selection => selection.type === 'token').map(selection => {
      let distanceToSelected = distanceBetween({ x, y }, selection.attrs);
      distanceToSelected = distanceToSelected - width / 2 - selection.attrs.width / 2;
      if (distanceToSelected < 0) {
        return -0.1;
      }
      return distanceToSelected;
    });
    distances = distances.filter(d => d !== undefined);
    if (!distances.length) return;
    return Math.min(...distances);
  }
  distanceText(distanceToSelected) {
    if (!this.props.showDistance) return '';
    if (distanceToSelected && distanceToSelected < 0) {
      return '❌';
    }
    if (distanceToSelected && distanceToSelected < 0.05) {
      return `0”`;
    }
    if (distanceToSelected) {
      return `${roundDistance(distanceToSelected)}”`;
    }
    return '';
  }
  meleeAuraOpacity(distanceToSelected) {
    const { record, selections } = this.props;
    let meleeAuraOpacity = 0;
    if (distanceToSelected <= this.props.meleeRange) {
      meleeAuraOpacity = 0.6;
    } else if (distanceToSelected <= this.props.meleeRange + 2) {
      meleeAuraOpacity = 0.3;
    }
    if (selections && selections.find(selection => selection.path === record.path)) {
      meleeAuraOpacity = 0.6;
    } else if (record.attrs.player && !selections.find(selection => selection.attrs.player !== record.attrs.player)) {
      meleeAuraOpacity = 0;
    }
    return meleeAuraOpacity;
  }
  applyProps() {
    const radius = this.props.width * this.props.pixelsPerInch / 2;
    const borderWidth = radius / 6;
    const distanceToSelected = this.distanceToSelected();

    this.backArc.set({
      radius: radius - borderWidth / 2,
      strokeWidth: borderWidth,
    });
    let arcMarkerOpacity = this.props.showArcs ? 0.4 : 0;
    this.arcMarkerLeft.set({
      top: 0,
      left: -1 * radius + borderWidth / 2,
      width: borderWidth,
      height: borderWidth / 3,
      opacity: arcMarkerOpacity,
    });
    this.arcMarkerRight.set({
      top: 0,
      left: radius - borderWidth / 2,
      width: borderWidth,
      height: borderWidth / 3,
      opacity: arcMarkerOpacity,
    });
    this.arcMarkerFront.set({
      top: -1 * radius + borderWidth / 2,
      left: 0,
      width: borderWidth / 3,
      height: borderWidth,
      opacity: arcMarkerOpacity,
    });
    const meleeAuraOpacity = this.meleeAuraOpacity(roundDistance(distanceToSelected));
    this.meleeAura.set({
      left: this.props.x * this.props.pixelsPerInch,
      top: this.props.y * this.props.pixelsPerInch,
      opacity: meleeAuraOpacity,
      angle: this.props.facing,
    });
    const meleeRadius = (this.props.meleeRange + this.props.width / 2) * this.props.pixelsPerInch;
    this.meleeAura.setRadius(meleeRadius);
    this.meleeAura.set('fill', new fabric.Gradient({
      type: 'radial',
      coords: {
        x1: meleeRadius,
        y1: meleeRadius,
        x2: meleeRadius,
        y2: meleeRadius,
        r1: meleeRadius / 2,
        r2: meleeRadius,
      },
      colorStops: [
        { offset: 0, color: 'rgba(244,67,54,0.1)', },
        { offset: 0.2, color: 'rgba(244,67,54,0.1)', },
        { offset: 0.9, color: 'rgba(244,67,54,0.3)', },
        { offset: 1, color: 'rgba(244,67,54,0.7)', },
      ],
    }));
    if (this.props.showArcs) {
      this.meleeFacing.set({
        left: this.props.x * this.props.pixelsPerInch,
        top: this.props.y * this.props.pixelsPerInch,
        width: 1,
        height: 2 * meleeRadius - 1,
        opacity: meleeAuraOpacity,
        angle: this.props.facing,
        layer: this.props.immobile ? LAYER_IMMOBILE_MODELS : LAYER_MODELS,
      });
      this.meleeFacing.set('fill', new fabric.Gradient({
        type: 'linear',
        coords: {
          x1: -this.meleeFacing.width / 2,
          y1: 0,
          x2: this.meleeFacing.width / 2,
          y2: this.meleeFacing.height,
        },
        colorStops: [
          { offset: 0, color: 'rgba(244,67,54,0.7)', },
          { offset: 0.5, color: 'rgba(244,67,54,0.7)', },
          { offset: 0.51, color: 'rgba(0,0,0,0)', },
          { offset: 1, color: 'rgba(0,0,0,0)', },
        ],
      }));
    }
    this.backgroundImage.set({
      left: this.props.x * this.props.pixelsPerInch,
      top: this.props.y * this.props.pixelsPerInch,
      layer: this.props.immobile ? LAYER_IMMOBILE_MODELS : LAYER_MODELS,
    });
    this.token.set({
      width: radius * 2,
      height: radius * 2,
      record: this.props.record,
      opacity: this.props.opacity,
      lockMovementX: this.props.immobile,
      lockMovementY: this.props.immobile,
      layer: this.props.immobile ? LAYER_IMMOBILE_MODELS : LAYER_MODELS,
    });
    if (!this.props.isDragging && !this.token.group) {
      this.token.set({
        angle: this.props.facing,
        left: this.props.x * this.props.pixelsPerInch,
        top: this.props.y * this.props.pixelsPerInch,
      });
    }
    this.token.setCoords();

    const distanceText = this.distanceText(distanceToSelected);
    this.distance.set({
      text: distanceText,
      left: this.props.x * this.props.pixelsPerInch,
      top: this.props.y * this.props.pixelsPerInch,
      opacity: this.props.opacity,
    });
  }
  componentDidUpdate(prevProps) {
    this.applyProps();
    if (prevProps.opacity !== this.props.opacity || prevProps.color !== this.props.color || prevProps.resource1 !== this.props.resource1 || prevProps.resource2 !== this.props.resource2 || prevProps.resource3 !== this.props.resource3 || prevProps.label !== this.props.label || prevProps.width !== this.props.width || prevProps.activated !== this.props.activated || prevProps.isActivating !== this.props.isActivating || prevProps.conditions !== this.props.conditions || prevProps.pixelsPerInch !== this.props.pixelsPerInch || prevProps.imageUrl !== this.props.imageUrl || prevProps.tint !== this.props.tint || prevProps.width !== this.props.width || (this.props.effects || []).length !== (prevProps.effects || []).length || prevProps.immobile !== this.props.immobile) {
      this.rerenderToken();
    }
    this.props.canvas.requestRenderAll();
  }
}

export function widthFor(baseSize) {
  if (baseSize === '0.5in') {
    return 0.5;
  } else if (baseSize === '0.75in') {
    return 0.75;
  } else if (baseSize === '1in') {
    return 1;
  } else if (baseSize === '1.5in') {
    return 1.5;
  } else if (baseSize === '2in') {
    return 2;
  } else if (baseSize === '20mm') {
    return 0.787402;
  } else if (baseSize === '25mm') {
    return 0.984252;
  } else if (baseSize === '27mm') {
    return 1.06299;
  } else if (baseSize === '30mm') {
    return 1.1811;
  } else if (baseSize === '35mm') {
    return 1.37795;
  } else if (baseSize === '40mm') {
    return 1.5748;
  } else if (baseSize === '50mm') {
    return 1.9685;
  } else if (baseSize === '54mm') {
    return 2.12598;
  } else if (baseSize === '65mm') {
    return 2.55906;
  } else if (baseSize === '80mm') {
    return 3.14961;
  } else if (baseSize === '100mm') {
    return 3.93701;
  } else if (baseSize === '120mm') {
    return 4.72441;
  }
  return 1;
}

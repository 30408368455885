import React, { Component } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { listGames } from '../initFirebase';
import { withUser } from '../withUser';
import GameItem from './GameItem';
import Dialog from '../WTDialog';

class ResumeGameDialog extends Component {
  state = {
    games: null
  }
  componentDidMount() {
    const { system } = this.props;
    listGames(this.props.user.uid).then((games) => {
      if (this.props.system) games = games.filter(game => game.attrs.system === system);
      games = games.reverse();
      this.setState({ games });
    });
  }
  onRemoveGame = (game) => {
    let games = this.state.games.slice(0);
    games.splice(games.indexOf(game), 1);
    this.setState({ games });
    game.remove();
  }
  render() {
    const { onClose, open, user } = this.props;
    if (!open) return null;
    const { games } = this.state;
    return <Dialog open={open} maxWidth="md" onClose={onClose}>
      <DialogTitle>Continue Game</DialogTitle>
      {games && games.length > 0 &&
        <List>
          {games.map((game, i) => {
            return <GameItem key={i} game={game} user={user} onRemove={this.onRemoveGame} />
          })}
        </List>
      }
      {games !== null && games.length === 0 &&
        <DialogContent>
          <Typography paragraph>No games to resume</Typography>
        </DialogContent>
      }
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  }
}


export default withUser(ResumeGameDialog);

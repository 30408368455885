import { widthFor } from '../../../map/Token';

export default function setup(connection) {
  [
    { x: 8, y: 6 },
    { x: 24 - 8, y: 6 },
    { x: 8, y: 24 - 6 },
    { x: 24 - 8, y: 24 - 6 },
  ].forEach(({ x, y }) => {
    connection.add('token', {
      x,
      y,
      label: 'Keii',
      width: widthFor('30mm'),
      deletable: true,
      immobile: true,
    });
  });
  [
    { x: 6, y: 12 },
    { x: 24 - 6, y: 12 },
  ].forEach(({ x, y }) => {
    connection.add('token', {
      x,
      y,
      label: 'Keii/Idols',
      width: widthFor('30mm'),
      deletable: true,
      immobile: true,
    });
  });
  [
    { x: 12, y: 12 },
    { x: 12, y: 12 },
  ].forEach(({ x, y }) => {
    connection.add('token', {
      x,
      y,
      label: 'Idols',
      width: widthFor('30mm'),
      deletable: true,
      immobile: true,
    });
  });
  [
    { x: 6, y: 12 },
    { x: 12, y: 12 },
    { x: 24 - 6, y: 12 },
  ].forEach(({ x, y }) => {
    connection.add('shape', {
      type: 'Circle',
      x,
      y,
      fill: '#9e9e9e',
      stroke: '#000000',
      label: 'Seikyu',
      width: 4,
    });
  });
  connection.add('widget', {
    widgetType: 'Counter',
    value: 0,
    x: -2,
    y: 6 - widthFor('50mm') / 2,
    label: 'VPs',
    fill: '#9e9e9e',
    stroke: '#ffffff',
  });
  connection.add('widget', {
    widgetType: 'Counter',
    value: 0,
    x: -2,
    y: 18 + widthFor('50mm') / 2,
    label: 'VPs',
    fill: '#9e9e9e',
    stroke: '#ffffff',
  });
  connection.add('widget', {
    widgetType: 'Counter',
    value: 0,
    x: 26,
    y: 6 - widthFor('50mm') / 2,
    label: 'SPs',
    fill: '#f44336',
    stroke: '#ffffff',
  });
  connection.add('widget', {
    widgetType: 'Counter',
    value: 0,
    x: 26,
    y: 18 + widthFor('50mm') / 2,
    label: 'SPs',
    fill: '#f44336',
    stroke: '#ffffff',
  });
}

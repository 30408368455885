import React from 'react';

export default (props) => {
  return <span
    style={{
      fontFamily: 'Shojumaru,cursive',
      color: props.color,
    }}
  >
    War Table
  </span>
}

import React, { Component } from 'react';

export const withWidth = () => {
  return (WrappedComponent) => {
    const HOC = class extends Component {
      componentDidMount() { 
      }
      render() {
        const windowWidth = window.innerWidth;
        let width;
        if (windowWidth >= 1536) width = 'xl'
        else if (windowWidth >= 1200) width = 'lg'
        else if (windowWidth >= 900) width = 'md'
        else if (windowWidth >= 600) width = 'xm'
        else width = 'xs'
        return <WrappedComponent width={width} {...this.props} />;
      }
    };
  
    return HOC;
  }
};

export default withWidth;
export function isWidthUp(compare, width) {
  if (compare === 'xl') {
    return ['xl'].includes(width);
  }
  if (compare === 'lg') {
    return ['lg', 'xl'].includes(width);
  }
  if (compare === 'md') {
    return ['md', 'lg', 'xl'].includes(width);
  }
  if (compare === 'sm') {
    return ['sm', 'md', 'lg', 'xl'].includes(width);
  }
  return true;
};
export function isWidthDown(compare, width) {
  if (compare === 'lg') {
    return ['xs', 'sm', 'md', 'lg'].includes(width);
  }
  if (compare === 'md') {
    return ['xs', 'sm', 'md'].includes(width);
  }
  if (compare === 'sm') {
    return ['xs', 'sm'].includes(width);
  }
  if (compare === 'xs') {
    return ['xs'].includes(width);
  }
  return true;
};
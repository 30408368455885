import React, { Fragment } from 'react';

export default [
  {
    label: 'Necrotyr18',
    credit: <Fragment>These maps were contributed by Necrotyr18, a long-time supporter of War Table.</Fragment>,
    maps: [
      {
        "label": "Necrotyr18’s Map 1",
        "imageUrl": "/Warmahordes/maps/Necrotyr-0.jpg",
        "gameId": "-MjNcalshT_hsFq7G-Ny"
      },
      {
        "label": "Necrotyr18’s Map 2",
        "imageUrl": "/Warmahordes/maps/Necrotyr-1.jpg",
        "gameId": "-MjNxNSL_4qjhHn0LwRr"
      },
      {
        "label": "Necrotyr18’s Map 3",
        "imageUrl": "/Warmahordes/maps/Necrotyr-2.jpg",
        "gameId": "-MjNyuAOk7xxcm7un0Xw"
      },
      {
        "label": "Necrotyr18’s Map 4",
        "imageUrl": "/Warmahordes/maps/Necrotyr-3.jpg",
        "gameId": "-MjNzTYypKv70FDhN29d"
      },
      {
        "label": "Necrotyr18’s Map 5",
        "imageUrl": "/Warmahordes/maps/Necrotyr-4.jpg",
        "gameId": "-N24nBeO0SqkjNCoSt1B"
      },
      {
        "label": "Necrotyr18’s Map 6",
        "imageUrl": "/Warmahordes/maps/Necrotyr-5.jpg",
        "gameId": "-N24mA-HtvMlh6ZLK4YG"
      },
      {
        "label": "Necrotyr18’s Map 7",
        "imageUrl": "/Warmahordes/maps/Necrotyr-6.jpg",
        "gameId": "-MjTFEMqRgDF7tb7wdkf"
      },
      {
        "label": "Necrotyr18’s Map 8",
        "imageUrl": "/Warmahordes/maps/Necrotyr-7.jpg",
        "gameId": "-MjTG6hP7gMN2q9jEE3x"
      },
      {
        "label": "Necrotyr18’s Map 9",
        "imageUrl": "/Warmahordes/maps/Necrotyr-8.jpg",
        "gameId": "-MjTJDAN-mhLzRTMdki5"
      },
      {
        "label": "Necrotyr18’s Map 10",
        "imageUrl": "/Warmahordes/maps/Necrotyr-9.jpg",
        "gameId": "-MjTMJrQIoOTcIOZ2GvJ"
      },
      {
        "label": "Necrotyr18’s Map 11",
        "imageUrl": "/Warmahordes/maps/Necrotyr-10.jpg",
        "gameId": "-MjTScTd4Xjx_rrRQfyx"
      },
      {
        "label": "Necrotyr18’s Map 12",
        "imageUrl": "/Warmahordes/maps/Necrotyr-11.jpg",
        "gameId": "-Mq_Cj_aCks2JJBX4dZH"
      },
      {
        "label": "Necrotyr18’s Map 13",
        "imageUrl": "/Warmahordes/maps/Necrotyr-12.jpg",
        "gameId": "-Mq_DeTnbjA1AIbXG5WL"
      },
      {
        "label": "Necrotyr18’s Map 14",
        "imageUrl": "/Warmahordes/maps/Necrotyr-13.jpg",
        "gameId": "-Mq_Es2CQSS81ksqKTv4"
      },
      {
        "label": "Necrotyr18’s Map 15",
        "imageUrl": "/Warmahordes/maps/Necrotyr-14.jpg",
        "gameId": "-Mq_GiCzKPu4N0LRU7Px"
      },
      {
        "label": "Necrotyr18’s Map 16",
        "imageUrl": "/Warmahordes/maps/Necrotyr-15.jpg",
        "gameId": "-Mq_Hn3BSw4JHm5PqM0f"
      },
      {
        "label": "Necrotyr18’s Map 17",
        "imageUrl": "/Warmahordes/maps/Necrotyr-16.jpg",
        "gameId": "-Mq_IvVAwSVOugae5Lp5"
      },
      {
        "label": "Necrotyr18’s Map 18",
        "imageUrl": "/Warmahordes/maps/Necrotyr-17.jpg",
        "gameId": "-Mq_Ji-JX9WhALlVZunE"
      },
      {
        "label": "Necrotyr18’s Map 19",
        "imageUrl": "/Warmahordes/maps/Necrotyr-18.jpg",
        "gameId": "-Mq_K_AW1W7afqzDsyq3"
      },
      {
        "label": "Necrotyr18’s Map 20",
        "imageUrl": "/Warmahordes/maps/Necrotyr-19.jpg",
        "gameId": "-Mq_Lgd1lvnd9yQcYKjF"
      },
      {
        "label": "Necrotyr18’s Map 21",
        "imageUrl": "/Warmahordes/maps/Necrotyr-20.jpg",
        "gameId": "-Mq_MpTzEH_F9pV80R4v"
      },
      {
        "label": "Necrotyr18’s Map 22",
        "imageUrl": "/Warmahordes/maps/Necrotyr-21.jpg",
        "gameId": "-Mq_Pbrtyt4XqlF9NYij"
      },
      {
        "label": "Necrotyr18’s Map 23",
        "imageUrl": "/Warmahordes/maps/Necrotyr-22.jpg",
        "gameId": "-MuFUYID70zPS_y8o2-T"
      },
      {
        "label": "Necrotyr18’s Map 24",
        "imageUrl": "/Warmahordes/maps/Necrotyr-23.jpg",
        "gameId": "-MuFWDvDmYsULl8oRXsI"
      },
      {
        "label": "Necrotyr18’s Map 25",
        "imageUrl": "/Warmahordes/maps/Necrotyr-24.jpg",
        "gameId": "-MuFXA151hvzliHvu8QH"
      },
      {
        "label": "Necrotyr18’s Map 26",
        "imageUrl": "/Warmahordes/maps/Necrotyr-25.jpg",
        "gameId": "-MuFY6fHkGCh46q5sydm"
      },
      {
        "label": "Necrotyr18’s Map 27",
        "imageUrl": "/Warmahordes/maps/Necrotyr-26.jpg",
        "gameId": "-N1lxdBXtSId6cCEf1jG"
      },
      {
        "label": "Necrotyr18’s Map 28",
        "imageUrl": "/Warmahordes/maps/Necrotyr-27.jpg",
        "gameId": "-N1umPMUmPvViXV2Lsjc"
      },
      {
        "label": "Necrotyr18’s Map 29",
        "imageUrl": "/Warmahordes/maps/Necrotyr-28.jpg",
        "gameId": "-N24jU-vhW22o2_4x_Ue"
      },
      {
        "label": "Necrotyr18’s Map 30",
        "imageUrl": "/Warmahordes/maps/Necrotyr-29.jpg",
        "gameId": "-N24kJWSFWyfdLSJF6kg"
      },
      {
        "label": "Necrotyr18’s Map 31",
        "imageUrl": "/Warmahordes/maps/Necrotyr-30.jpg",
        "gameId": "-N24l5gGhq_df63YRzKn"
      },
      {
        "label": "Necrotyr18’s Map 32",
        "imageUrl": "/Warmahordes/maps/Necrotyr-31.jpg",
        "gameId": "-N2FhI73kUJYEsXIrvno"
      },
      {
        "label": "Necrotyr18’s Map 33",
        "imageUrl": "/Warmahordes/maps/Necrotyr-32.jpg",
        "gameId": "-N2Fi7eHUy8aUKhPjwTg"
      },
      {
        "label": "Necrotyr18’s Map 34",
        "imageUrl": "/Warmahordes/maps/Necrotyr-33.jpg",
        "gameId": "-N3RUSRAdhdpW0t5DMPF"
      },
      {
        "label": "Necrotyr18’s Map 35",
        "imageUrl": "/Warmahordes/maps/Necrotyr-34.jpg",
        "gameId": "-N4J54KmPvOGXaApVo34"
      },
      {
        "label": "Necrotyr18’s Map 36",
        "imageUrl": "/Warmahordes/maps/Necrotyr-35.jpg",
        "gameId": "-N4K96js0Xj7zSSF6LWr"
      },
      {
        "label": "Necrotyr18’s Map 37",
        "imageUrl": "/Warmahordes/maps/Necrotyr-36.jpg",
        "gameId": "-N4QkOvpkkfbo9qCDVhm"
      },
      {
        "label": "Necrotyr18’s Map 38",
        "imageUrl": "/Warmahordes/maps/Necrotyr-37.jpg",
        "gameId": "-N4Qm6GAiAylw30JE1rT"
      },
      {
        "label": "Necrotyr18’s Map 39",
        "imageUrl": "/Warmahordes/maps/Necrotyr-38.jpg",
        "gameId": "-N4QnANfAVD8J8-UmE4h"
      },
      {
        "label": "Necrotyr18’s Map 40",
        "imageUrl": "/Warmahordes/maps/Necrotyr-39.jpg",
        "gameId": "-N4Qoi057ITLoII_HdM8"
      },
      {
        "label": "Necrotyr18’s Map 41",
        "imageUrl": "/Warmahordes/maps/Necrotyr-40.jpg",
        "gameId": "-N4YFz2tDS6ZMFwOKeqz"
      },
      {
        "label": "Necrotyr18’s Map 42",
        "imageUrl": "/Warmahordes/maps/Necrotyr-41.jpg",
        "gameId": "-N4ZDUPYw-B4_LYOFSUm"
      },
      {
        "label": "Necrotyr18’s Map 43",
        "imageUrl": "/Warmahordes/maps/Necrotyr-42.jpg",
        "gameId": "-N4ZEtK0XvkwmtCfCvIC"
      },
      {
        "label": "Necrotyr18’s Map 44",
        "imageUrl": "/Warmahordes/maps/Necrotyr-43.jpg",
        "gameId": "-N4ZG8FLTdVsOB5giXm7"
      },
      {
        "label": "Necrotyr18’s Map 45",
        "imageUrl": "/Warmahordes/maps/Necrotyr-44.jpg",
        "gameId": "-N4ZLUg-usxYdljOVdG6"
      }    
    ]
  },
  {
    label: 'Line of Sight',
    credit: <Fragment>These maps were contributed by <a href="https://twitter.com/chocobsessed_ll" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>Chocobssessed</a> and <a href="http://twitter.com/los_jaden" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>Jaden</a> from <a href="https://loswarmachine.com" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>Line of Sight</a>.</Fragment>,
    maps: [
      {
        label: 'A House Divided',
        gameId: '-MDUzjNWI1NcT4ZsENHH',
        imageUrl: '/Warmahordes/maps/AHouseDivided.jpg',
      },
      {
        label: 'Between A Rock And A Burnt Place',
        gameId: '-MKvDS3lvJXB8D8fRkJU',
        imageUrl: '/Warmahordes/maps/BetweenARockAndABurntPlace.jpg'
      },
      {
        label: 'Between Two Cottages',
        gameId: '-MDZhjmvK3VjaG2CWCMC',
        imageUrl: '/Warmahordes/maps/BetweenTwoCottages.jpg'
      },
      {
        label: 'Boarding Action',
        gameId: '-MDWn5tEVDpkKHzYBZN7',
        imageUrl: '/Warmahordes/maps/BoardingAction.jpg'
      },
      {
        label: 'Castle Siege',
        gameId: '-MKvBpk_Lmyxa2tq_34l',
        imageUrl: '/Warmahordes/maps/CastleSiege.jpg',
      },
      {
        label: 'Corridors',
        gameId: '-MKvC_svhWPxtV_ENp4U',
        imageUrl: '/Warmahordes/maps/Corridors.jpg',
      },
      {
        label: 'Desert',
        gameId: '-MDWgjgff8J3Ba1Jskip',
        imageUrl: '/Warmahordes/maps/Desert.jpg'
      },
      {
        label: 'Fall of Llael',
        gameId: '-MDWe2sg_p1nfToy0Fzd',
        imageUrl: '/Warmahordes/maps/FallOfLlael.jpg'
      },
      {
        label: 'Forest’s Edge',
        gameId: '-MDV5hqLREMIMP53FETs',
        imageUrl: '/Warmahordes/maps/ForestsEdge.jpg'
      },
      {
        label: 'Necrofactory',
        gameId: '-MDWkAlctfmppXg-JZ12',
        imageUrl: '/Warmahordes/maps/Necrofactory.jpg'
      },
      {
        label: 'Night on Blight Mountain',
        gameId: '-MDZfG4OxMDozPRFyTbH',
        imageUrl: '/Warmahordes/maps/NightOnBlightMountain.jpg'
      },
      {
        label: 'Outskirts',
        gameId: '-MDWq933N1wup-QL4zf5',
        imageUrl: '/Warmahordes/maps/Outskirts.jpg'
      },
      {
        label: 'Rubble Rumble',
        gameId: '-MKvE4sVJVGaIIbT1OR8',
        imageUrl: '/Warmahordes/maps/RubbleRumble.jpg'
      },
      {
        label: 'Swamp',
        gameId: '-MDWi30UwxiAi8-djLmU',
        imageUrl: '/Warmahordes/maps/Swamp.jpg'
      },
      {
        label: 'The Path Less Traveled',
        gameId: '-MDWoS4800MSwkKC_-ph',
        imageUrl: '/Warmahordes/maps/ThePathLessTraveled.jpg'
      },
      {
        label: 'Three Groves',
        gameId: '-MKvD0S2PkyQXDM8e_ca',
        imageUrl: '/Warmahordes/maps/ThePathLessTraveled.jpg'
      },
      {
        label: 'Trinity',
        gameId: '-MDV6zEJAhH_8cOmyTVR',
        imageUrl: '/Warmahordes/maps/Trinity.jpg'
      },
      {
        label: 'Trench Warfare',
        gameId: '-MDWbmvj64eu5qZedGv5',
        imageUrl: '/Warmahordes/maps/TrenchWarfare.jpg'
      },
      {
        label: 'Volcano',
        gameId: '-MDWcs5WBrHFVCND9YCK',
        imageUrl: '/Warmahordes/maps/Volcano.jpg'
      },
    ]
  }, {
    label: 'VTC 2021',
    credit: <Fragment>The <a href="https://knightsmachine.com/blogs/general/vtc-2021-is-coming" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>2021 Virtual Team Championship</a> is done but you can still <a href="https://docs.google.com/document/d/19JlGdqarkK5Gi6o2ksNaq91jMlkg5levMoN0LbJkw2M/edit?usp=sharing" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>get the map links from this doc</a>.</Fragment>,
    maps: [],
  }, {
    label: 'VTC 2020',
    credit: <Fragment>The  <a href="https://knightsmachine.com/2020/06/12/vtc-announcement/" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>2020 Virtual Team Championship</a> is done but you can still <a href="https://docs.google.com/document/d/19JlGdqarkK5Gi6o2ksNaq91jMlkg5levMoN0LbJkw2M/edit?usp=sharing" target="_blank" rel="noreferrer noopener" style={{ textDecoration: 'underline' }}>get the map links from this doc</a>.</Fragment>,
    maps: [],
  },
];

import { fabric } from 'fabric-with-gestures'
import BaseShape from './BaseShape';

export default class Rect extends BaseShape {
  createFabricObject() {
    return new fabric.Rect({
      strokeWidth: 1,
      fill: '',
      originX: 'center',
      originY: 'center',
      hasControls: false,
      layer: this.layer(),
    });
  }
  shapeAttrs(attrs, pixelsPerInch) {
    return {
      left: attrs.x * pixelsPerInch,
      top: attrs.y * pixelsPerInch,
      width: attrs.width * pixelsPerInch - 1,
      height: attrs.height * pixelsPerInch - 1,
      stroke: attrs.stroke,
      angle: attrs.facing,
      fill: attrs.fill || 'transparent'
    };
  }
}

import recordTimeUsed from './recordTimeUsed';

export default function togglePause(game, log, attrs) {
  const activePlayerLabel = game.attrs[`player${game.attrs.clockActivePlayer}Label`];
  if (game.attrs.clockPaused) {
    log(`${activePlayerLabel} clock resumed`);
  } else {
    log(`${activePlayerLabel} clock paused`);
  }
  recordTimeUsed(game, {
    ...attrs,
    clockPaused: !game.attrs.clockPaused
  });
}

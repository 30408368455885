import React, { Component } from 'react';
import moment from 'moment';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Records from './Records';

export default class Log extends Component {
  render() {
    return (
      <List>
        <Records type='log' reverse connection={this.props.connection} limit={this.props.limit}>
          { (record) =>
            <ListItem disableGutters key={record.path} dense divider>
              <ListItemText primary={record.attrs.text} secondary={record.attrs.secondary}/>
              <ListItemSecondaryAction>
                <Typography variant="body2" color="textSecondary">{moment(record.attrs.createdAt).format('HH:mm')}</Typography>
              </ListItemSecondaryAction>
            </ListItem>
          }
        </Records>
      </List>
    )
  }
}

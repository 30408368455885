export const LAYER_BACKGROUND = 100;
export const LAYER_SCENARIO = 200;
export const LAYER_TERRAIN = 300;
export const LAYER_GRID = 400;
export const LAYER_AURAS = 500;
export const LAYER_IMMOBILE_MODELS = 600;
export const LAYER_PATHS = 700;
export const LAYER_WIDGETS = 800;
export const LAYER_MODELS = 900;
export const LAYER_UI = 1000;
export const LAYER_PING = 1100;

import React, { Component } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Redirect } from 'react-router-dom';

import Dialog from '../WTDialog';

export default class JoinGameDialog extends Component {
  state = {
    inviteLink: null
  }
  join = () => {
    const { inviteLink } = this.state;
    const relativeUrl = inviteLink.substr(inviteLink.indexOf('/', 10));
    if (relativeUrl.indexOf('/invite') === 0) {
      this.setState({ path: relativeUrl });
    }
  }
  render() {
    const { onClose } = this.props;
    const { inviteLink, path } = this.state;
    if (path) {
      return <Redirect push to={path} />;
    }
    return (
      <Dialog open onClose={onClose}>
        <DialogTitle>Join Game</DialogTitle>
        <DialogContent>
          <Typography paragraph>Paste your invite link in the field below</Typography>
          <TextField
            label="Invite Link"
            helperText="Invite link will be like https://wartable.online/invite/asdfasdfafa"
            margin="normal"
            fullWidth
            value={inviteLink}
            onChange={(e) => { this.setState({ inviteLink: e.target.value }) }}
          />
          <Button variant="contained" color="primary" disabled={!inviteLink} onClick={this.join}>Join</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    );
  }
}
